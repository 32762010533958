import TextareaAutosize from "@mui/base/TextareaAutosize";
import { Button, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useRef, useState } from "react";
import userAPi from "src/api/MemberApi";
import UploadImg from "src/components/phase1/Field/uploadAvatar";
import { LoadingContext } from "src/providers/LoadingProvider";
import { IsCeleb, getAgeUser, niceCompany, niceSex, valueNiceAuth } from "src/util/Utils";
import { convertTimeZone, convertTextToKorean, handlSignInTime } from "src/util/convert";
import { showError, showSuccess } from "src/util/error";
import FilterInputWrapper from "../NewLayout/FilterInputWrapperV2";
import "./style.css";
import { getAppName, getAppVersion } from "src/util/data";

const UserInfo = ({ data, fetchAll }) => {
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [urlImg, setUrlImg] = useState(null);
	const inputRef = useRef(null);
	const [content, setContent] = useState(data?.admin_note);
	const [isConfirmOver19, setIsConfirmOver19] = useState(false);

	const [appInfo, setAppInfo] = useState({
		app_version: "",
		app_name: "",
	});

	useEffect(() => {
		setUrlImg(data?.avatar[0]);

		const isConfirmOver19 = data?.is_over_19 && data?.nice_infos[0]?.id ? true : false;
		setIsConfirmOver19(isConfirmOver19);
		setAppInfo({
			app_name: getAppName(data),
			app_version: getAppVersion(data),
		});
	}, [data]);

	const renderUserHashTag = (arr) => {
		return arr?.map((item) => `#${item?.hashtag?.title}`).join();
	};
	// const totalPayment = (arr) => {
	// 	return arr?.reduce((total, item) => (total += item?.pack?.price), 0);
	// };

	const handleAvatar = async (body) => {
		try {
			const res = await userAPi.adminUpdate(body);
			if (res.code === 200) {
				showSuccess();
				fetchAll();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleSubmit = async () => {
		showLoading();
		try {
			const res = await userAPi.update({ admin_note: content }, data.id);
			if (res.code === 200) {
				fetchAll();
			}
			hideLoading();
		} catch (error) {
			hideLoading();
			showError(error);
		}
	};

	return (
		<>
			<Stack spacing={2}>
				{/* avatar */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>사진정보</p>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							padding: "20px",
						}}
					>
						<div
							className="user-avatar-container"
							style={{
								flex: 3,
							}}
						>
							<UploadImg
								thumbnail={urlImg}
								setUrlImg={setUrlImg}
								uploadRef={inputRef}
								URL={(img) =>
									handleAvatar({
										type: "ADD_AVATAR",
										photos: [img],
										user_id: data?.id,
									})
								}
							/>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-around",
									marginLeft: "10px",
								}}
							>
								<Button
									variant="contained"
									sx={{
										background: "#FAFAFA ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
										color: "black",
									}}
									onClick={() =>
										data?.avatar.length > 0 &&
										handleAvatar({
											type: "REMOVE_AVATAR",
											photos: [data?.avatar[0]],
											user_id: data?.id,
										})
									}
								>
									삭제
								</Button>
								<Button
									variant="contained"
									sx={{
										background: "#3B3B3B ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
									}}
									onClick={() => inputRef && inputRef.current && inputRef.current.click()}
								>
									사진 등록
								</Button>
							</div>
						</div>
						{IsCeleb(data) && (
							<div style={{ flex: 1 }}>
								<FilterInputWrapper label={"총 적립금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
								<FilterInputWrapper label={"총 환급금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
								<FilterInputWrapper label={"현재 보유금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
							</div>
						)}
					</div>
				</div>

				{/*basic info */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>기본정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* id */}
							<FilterInputWrapper label={"회원 ID"}>
								<input type="text" value={data?.identified_id} readOnly className="custom-input" />
							</FilterInputWrapper>
							{/* gender */}
							<FilterInputWrapper label={"성별"}>
								<input
									type="text"
									value={convertTextToKorean(data?.sex)}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* hasgtag */}
							<FilterInputWrapper label={"관심사"}>
								<input
									type="text"
									value={renderUserHashTag(data?.user_hashtags)}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* payment */}
							<FilterInputWrapper label={"결제"}>
								<input
									type="text"
									value={`${(Number(data?.total_won_iap) || 0).toLocaleString()}/${Number(
										data?.number_of_iap
									).toLocaleString()}회`}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* nickname */}
							<FilterInputWrapper label={"닉네임"}>
								<input type="text" value={data?.nickname} readOnly className="custom-input" />
							</FilterInputWrapper>
							{/* email */}
							<FilterInputWrapper label={"이메일"}>
								<input type="text" value={data?.email} readOnly className="custom-input" />
							</FilterInputWrapper>
							{/* partner */}
							<FilterInputWrapper label={"파트너"}>
								<input
									type="text"
									value={data?.partner_user?.pid}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* first payment time */}
							<FilterInputWrapper label={"최초 결제일"}>
								<input
									type="text"
									value={convertTimeZone(+data?.first_iap_unix_timestamp)}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* age */}
							<FilterInputWrapper label={"나이"}>
								<input
									type="text"
									value={getAgeUser(data?.birthday)}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* status */}
							<FilterInputWrapper label={"상태"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={convertTextToKorean(data?.status_account)}
								/>
							</FilterInputWrapper>
							{/* level */}
							<FilterInputWrapper label={"등급"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={convertTextToKorean(data?.cs_level)}
								/>
							</FilterInputWrapper>
							{/* last payment time */}
							<FilterInputWrapper label={"최근 결제"}>
								<input
									type="text"
									value={convertTimeZone(+data?.last_iap_unix_timestamp)}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>

				{/*Registration / login information*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>가입 · 로그인정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* sigun-up day */}
							<FilterInputWrapper label={"가입 일자"}>
								<input
									type="text"
									value={convertTimeZone(+data?.created_at_unix_timestamp)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* sigun-up device  */}
							<FilterInputWrapper label={"가입 디바이스"}>
								<input type="text" value={data?.sign_up_device} readOnly className="custom-input" />
							</FilterInputWrapper>
							{/* sigun-up platfomr  */}
							<FilterInputWrapper label={"가입플랫폼"}>
								<input
									type="text"
									value={data?.sign_up_platform}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* sigun-in day */}
							<FilterInputWrapper label={"로그인 일자"}>
								<input
									type="text"
									value={handlSignInTime(
										data?.sign_in_time_unix_timestamp,
										data?.last_open_app_unix_timestamp
									)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* sigun-in device  */}
							<FilterInputWrapper label={"로그인 디바이스"}>
								<input type="text" value={data?.sign_in_device} readOnly className="custom-input" />
							</FilterInputWrapper>
							{/* sigun-in platfomr  */}
							<FilterInputWrapper label={"로그인 플랫폼"}>
								<input
									type="text"
									value={data?.sign_in_platform}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* OS app download  */}
							<FilterInputWrapper label={"설치한 앱 명"}>
								<input
									type="text"
									value={appInfo.app_name}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* app sersion */}
							<FilterInputWrapper label={"설치한 앱 버전"}>
								<input
									type="text"
									value={appInfo.app_version}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* app platform */}
							<FilterInputWrapper label={"설치한 플랫폼"}>
								<input
									type="text"
									value={data?.app_platform}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>
				{/*push  setting*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>푸시 설정</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* video call  */}
							<FilterInputWrapper label={"영상통화"}>
								<input
									type="text"
									value={data?.user_setting?.allow_video_call === true ? "ON" : "OFF"}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* chat */}
							<FilterInputWrapper label={"문자채팅"}>
								<input
									type="text"
									value={data?.user_setting?.allow_message === true ? "ON" : "OFF"}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>

				{/* video call setting*/}
				{/* <div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>영상통화 설정정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}> */}
				{/* star/1min  */}
				{/* <FilterInputWrapper label={"1분당 스타"}>
								<input
									type="text"
									value={data?.user_setting?.pay_per_call}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}> */}
				{/* time limit */}
				{/* <FilterInputWrapper label={"최소시간"}>
								<input
									type="text"
									value={data?.user_setting?.min_call_minutes}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div> */}

				{/*agency*/}
				{/* <div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>에이전시&환급정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}> */}
				{/* agency */}
				{/* <FilterInputWrapper label={"에이전시"}>
								<input
									type="text"
									value={data?.agency?.nickname}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper> */}
				{/* bank */}
				{/* <FilterInputWrapper label={"은행명"}>
								<input
									type="text"
									value={data?.agency?.withdrawal_bank}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper> */}
				{/* cmnd */}
				{/* <FilterInputWrapper label={"주민번호"}>
								<input
									type="text"
									value={data?.agency?.resident_registration_number}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}> */}
				{/* name account */}
				{/* <FilterInputWrapper label={"예금주"}>
								<input
									type="text"
									value={data?.agency?.account_holder_name}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}> */}
				{/* account number */}
				{/* <FilterInputWrapper label={"계좌번호"}>
								<input
									type="text"
									value={data?.agency?.account_number}
									readOnly
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div> */}

				{/* verify*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>본인 인증 정보</p>
					</div>
					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* verify */}
							<FilterInputWrapper label={"본인 인증"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={isConfirmOver19 ? "인증" : "미인증"}
								/>
							</FilterInputWrapper>
							{/* name */}
							<FilterInputWrapper label={"이름"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={decodeURIComponent(data?.nice_infos[0]?.nice_utf8_name || "")}
								/>
							</FilterInputWrapper>
							{/* phone verify */}
							<FilterInputWrapper label={"전화 번호"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={data?.nice_infos[0]?.nice_mobile_no}
								/>
							</FilterInputWrapper>
						</Grid>

						<Grid item xs={4}>
							{/* method verify */}
							<FilterInputWrapper label={"본인 인증 방식"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={valueNiceAuth(data?.nice_infos[0]?.nice_auth_type)}
								/>
							</FilterInputWrapper>
							{/* gender*/}
							<FilterInputWrapper label={"성별"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={niceSex(data?.nice_infos[0]?.nice_gender)}
								/>
							</FilterInputWrapper>
							{/* network name */}
							<FilterInputWrapper label={"통신사"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={niceCompany(data?.nice_infos[0]?.nice_mobileco)}
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* day verify */}
							<FilterInputWrapper label={"인증 날짜"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={convertTimeZone(
										Number(data?.nice_infos[0]?.nice_verify_at_unix_timestamp)
									)}
								/>
							</FilterInputWrapper>
							{/* D.O.Bs */}
							<FilterInputWrapper label={"생년월일"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									value={convertTimeZone(Number(data?.nice_infos[0]?.nice_birthdate))}
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>{" "}
				</div>

				{/* admin not*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>관리자 메모</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 1 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sx={{ display: "flex" }}>
							<TextareaAutosize
								style={{ width: "calc(100% - 146px)", height: 200, border: "1px solid black" }}
								value={content}
								onChange={(e) => setContent(e.target.value)}
							/>
							<div style={{ display: "flex", alignItems: "flex-end" }}>
								<Button
									variant="contained"
									sx={{
										background: "#3B3B3B ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
										width: "136px",
										height: "54px",
										marginLeft: "10px",
									}}
									onClick={handleSubmit}
								>
									저장
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
