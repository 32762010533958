import { getToken } from "src/util/Utils";
import axiosClient from "./axiosClient";
const HOSTNAME = process.env.REACT_APP_API;
const fields = 'fields=["$all"]';

const table = "employee";

const memberApi = {
	filter: (text, page, limit, column) => {
		let url = "";
		let filedFullname = "";

		if (column === "following_count" || column === "follower_count") {
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":"${text}"}&order=[["created_at","desc"]]`;
		} else if (column === "hashtag") {
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":{"$contains": ["${text}"]}}&order=[["created_at","desc"]]`;
		} else if (column === "nickname") {
			filedFullname = `fields=["$all",{"user":["nickname",{"$filter": {"nickname": {"$iLike": "${text}"} } } ]}]`;
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		} else {
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":{"$iLike":"%25${text.trim()}%25"}}&order=[["created_at","desc"]]`;
		}

		console.log("=====> url", url);
		return axiosClient.get(url);
	},
	findAll: (page, limit) => {
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?${fields}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
		return axiosClient.get(url);
	},

	findOne: (id) => {
		const url = `${HOSTNAME}/${table}/${id}?fields=["$all"]`;
		return axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		});
	},

	add: (body) => {
		const url = `${HOSTNAME}/${table}`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		});
	},

	register: (body) => {
		const url = `${HOSTNAME}/user`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		});
	},

	loginAdmin: (body) => {
		const url = `${HOSTNAME}/auth/employee_login`;
		return axiosClient.post(url, body);
	},

	update: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		});
	},

	delete: (id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.delete(url, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		});
	},

	uploadFile: (body) => {
		const url = `${HOSTNAME}/image/upload/`;
		return axiosClient.post(url, body, {
			headers: {
				"Content-Type": "image/png",
				Authorization: `Bearer ${getToken()}`,
			},
		});
	},
};

export default memberApi;
