// import ReactLoading from "react-loading";
import "./style.css";

const Loading = () => {
	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				background: "gray",
				opacity: "0.5",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "fixed",
				zIndex: "1800",
			}}
		>
			<span className="loader"></span>
		</div>
	);
};

export default Loading;
