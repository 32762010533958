import React from "react";
import "../styles.scss";
import "../style.css";
import ko from "date-fns/locale/ko";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function DateInput({
	placeholder = "YYYY/MM/DD",
	date,
	setDate,
	dateFormat = "yyyy/MM/dd",
	isShowTime = false,
}) {
	return (
		<div className="date-picker-container">
			<DatePicker
				locale={ko}
				placeholderText={placeholder}
				selected={date}
				showTimeSelect={isShowTime}
				timeCaption="시간"
				onChange={(date) => {
					setDate(date);
				}}
				dateFormat={dateFormat}
			/>
		</div>
	);
}

export default DateInput;
