export const Verified19ValueEnum = {
	Verified: "verified",
	Unverified: "unverified",
};

export const TimePeriodEnum = {
	TODAY: "TODAY",
	YESTERDAY: "YESTERDAY",
	ONE_WEEK: "ONE_WEEK",
	HALF_MONTH: "HALF_MONTH",
	FULL_MONTH: "FULL_MONTH",
	THREE_MONTH: "THREE_MONTH",
};

export const DATE_FORMAT_MM_DD_YYYY_HH_MM = "MM/DD/YYYY HH:mm";
export const DATE_FORMAT_MM_DD_YYYY_HH_MM_SS = "MM/DD/YYYY HH:mm:ss";
export const DATE_FORMAT_MM_DD = "MM/DD";
export const TIME_FORMAT_HH_MM = "HH:mm";
export const DATE_FORMAT_YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:mm";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY/MM/DD";

export const PLUS_KIT_STATUS = {
	ON: "ON",
	OFF: "OFF",
};

export const VIDEO_CALL_STATE = {
	CALL_DONE: "CALL_DONE",
	CALLING: "CALLING",
	REFUSED: "REFUSED",
	CANCELED: "CANCELED",
	TIMEOUT: "TIMEOUT",
};

export const PROFILE_IMAGE_STATE = {
	PENDING: "PENDING",
	APPROVED: "APPROVED",
};
