import React, { useContext } from "react";

import "./style.css";
import Checkbox from "@mui/material/Checkbox";
import { pink } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import VideoPlayer from "./VideoPlayer";
import LogoFansome from "src/assets/img/new_logo.png";

import { checkApprovePostImage, getTypeUrl } from "src/util/Utils";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper";

import { Pagination } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

import { checkUserType, checkGenderColor } from "src/util/Utils";
import { userProfileContext } from "src/providers/UserProvider";
import { Stack } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	maxHeight: "100vh",
	bgcolor: "background.paper",
	boxShadow: 24,
};
const imageType = ["jpeg", "gif", "bmp", "png"];

const CardImg = ({ item, handleCheck, typeCard, handleVerify, content, asp = false }) => {
	const { user } = item;
	// console.log("🚀 ~ file: CardImg.js:39 ~ CardImg ~ asp:", item);
	const { handleOpen: handleOpenBox, setid: handleSetId } = useContext(userProfileContext);
	// console.log(imageType);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	return (
		<div className="card__container" style={{ border: "1px solid black", margin: "5px 0 0 0" }}>
			<div
				className="card__name"
				style={{
					color: checkUserType(user.user_type),
					cursor: "pointer",
					...(asp
						? {
								display: "flex",
								flexWrap: "wrap",
								height: "fit-content",
								justifyContent: "left",
								paddingLeft: "10px",
						  }
						: {}),
				}}
				onClick={() => {
					handleSetId(user?.id);
					handleOpenBox();
					console.log("chinhs laf nos ", user?.id);
				}}
			>
				<span style={{ color: "white" }}>{asp ? user?.asp_user?.asp_name : user.nickname}</span>
				{asp && (
					<>
						<span style={{ color: "white" }}>/ {user?.nickname}</span>
						<span style={{ color: "white" }}>/ {user?.identified_id}</span>
					</>
				)}
			</div>
			<div className="card__header">
				{typeCard === "IMAGE" && (
					<div className="type_user" style={{ display: asp ? "none" : "flex" }}>
						<p>{user.user_type}</p>
					</div>
				)}

				<div
					style={{
						background: checkApprovePostImage(item.approve_state).background,
						color: checkApprovePostImage(item.approve_state).color,
						fontWeight: "bold",
					}}
					className="type_gender"
				>
					<p>{checkApprovePostImage(item.approve_state).text}</p>
				</div>
				<div className="type_gender " style={{ background: checkGenderColor(user.sex, "COLOR") }}>
					<p>{checkGenderColor(user.sex, "TEXT")}</p>
				</div>
			</div>
			<div className="card__img" style={{ height: "290px" }}>
				{typeCard === "IMAGE" ? (
					<img
						src={item?.photos[0]}
						width="100%"
						style={{ height: "290px", objectFit: "contain" }}
						alt="a7"
					/>
				) : item?.medias.length < 0 ? (
					<img src={LogoFansome} width="100%" height="290px" alt="a8" />
				) : item?.approve_state === "MEDIA_UPLOADING" ? (
					<div style={{ width: "100%", height: "290px", textAlign: "center" }}>
						제트인코딩 중입니다. 잠시 기다려 주세요.
					</div>
				) : imageType.includes(getTypeUrl(item?.medias[0])) ? (
					<img src={item?.medias[0]} width="100%" height="290px" alt="a9" />
				) : (
					<video src={item?.medias[0]} width={"100%"} height={"290px"} type="video/mp4" />
				)}
			</div>
			<Stack className="card__action" direction="row" spacing={1} sx={{ padding: 0 }}>
				<Button
					variant="contained"
					sx={{
						background: "#E0F1FF",
						width: 60,
						height: 32,
						borderRadius: "0.5rem",
						border: "1px solid #8ACCFF",
						color: "black",
					}}
					onClick={handleOpen}
				>
					확대
				</Button>
				{item.approve_state === "PENDING" ? (
					<Button
						variant="contained"
						sx={{
							background: "#FFDBDB",
							width: 80,
							height: 32,
							borderRadius: "0.5rem",
							border: "1px solid #FEA0A0",
							color: "black",
						}}
						onClick={() => handleVerify(item.id)}
					>
						승인
					</Button>
				) : null}
				<div style={{ display: "flex", alignItems: "center" }}>
					<Checkbox
						sx={{
							padding: "0 2px",
							"&.Mui-checked": {
								color: pink[600],
							},
						}}
						checked={item.checked}
						value={item.id}
						onChange={(e) => handleCheck(e.target.checked, e.target.value)}
					/>
					<p>선택</p>
				</div>
			</Stack>
			<CardModal
				open={open}
				handleClose={handleClose}
				user={user}
				typeCard={typeCard}
				item={item}
				content={content}
			/>
		</div>
	);
};

export const CardModal = ({ open, handleClose, user, typeCard, item, content }) => {
	// console.log(item);
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div
					style={{
						background: "#232323",
						height: "40px",
						padding: "10px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<p style={{ color: "white" }}>{user?.nickname || "닉네임"}</p>
					<CloseIcon onClick={handleClose} />
				</div>
				<div className="card__img" style={{ height: "100%" }}>
					{typeCard === "IMAGE" ? (
						item?.photos?.length === 1 ? (
							<img src={item?.photos[0]} width="100%" alt="a5" style={{ objectFit: "cover" }} />
						) : (
							<Swiper
								modules={[Pagination, Navigation]}
								pagination={{
									clickable: true,
								}}
								navigation={true}
								className="mySwiper"
							>
								{item?.photos?.map((item) => (
									<SwiperSlide>
										{imageType.includes(getTypeUrl(item)) ? (
											<img src={item} width="100%" alt="a5" style={{ objectFit: "cover" }} />
										) : (
											<VideoPlayer link={item[0]} />
										)}
									</SwiperSlide>
								))}
							</Swiper>
						)
					) : item?.medias?.length === 1 ? (
						<>
							{imageType.includes(getTypeUrl(item?.medias[0])) ? (
								<img
									src={item?.medias[0]}
									width="100%"
									height={"auto"}
									alt="a5"
									style={{ objectFit: "cover" }}
								/>
							) : (
								<VideoPlayer link={item?.medias[0]} />
							)}
						</>
					) : (
						<Swiper
							modules={[Pagination, Navigation]}
							pagination={{
								clickable: true,
							}}
							navigation={true}
							className="mySwiper"
							style={{ height: "100%" }}
						>
							{item?.medias?.map((item) => (
								<SwiperSlide>
									{imageType.includes(getTypeUrl(item)) ? (
										<img
											src={item}
											width="100%"
											height={"auto"}
											alt="a6"
											style={{ objectFit: "cover" }}
										/>
									) : (
										<VideoPlayer link={item} />
									)}
								</SwiperSlide>
							))}
						</Swiper>
					)}
				</div>
				{content && typeCard !== "IMAGE" && (
					<div style={{ background: "#FFFF", padding: "10px", borderTop: "1px solid" }}>
						{content}
					</div>
				)}
			</Box>
		</Modal>
	);
};

export default CardImg;
