import { Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import DataTable from "src/components/phase2/DataTable";
import TheHeaderDefault from "src/components/phase2/NewLayout/TheHeaderDefault";
import useFilters from "src/hooks/useFilter";
import { LoadingContext } from "src/providers/LoadingProvider";
import { convertTextToKorean, convertTimeZone } from "src/util/convert";
import { keyHeaderFilter } from "src/util/data";
import "./style.css";

import { SaleHistoryApi } from "src/api";
import PaymentFilter from "src/components/phase2/HeaderFilterInput/UserProfilePayment";

const UserInfo = ({ data }) => {
	const arr = [];
	const [items, setItems] = useState([]);
	const [total, settotal] = useState(0);

	const { showLoading, hideLoading } = useContext(LoadingContext);

	// console.log(data.id);

	const { LIMITED, currentPage, spages, filter } = useFilters();
	const columns = [
		{
			field: "id",
			hide: true,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "no",
			headerName: "No",
			headerAlign: "center",
			flex: 1,
			sortable: false,
		},
		{
			field: "purchase_date",
			headerName: "구매날짜",
			flex: 1,
			minWidth: 200,

			headerAlign: "center",
			sortable: false,
		},
		{
			field: "state",
			headerName: "상태",
			flex: 1,
			minWidth: 200,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "product_name",
			headerName: "상품명",
			headerAlign: "center",
			flex: 1,
			minWidth: 200,
			sortable: false,
		},
		{
			field: "payer_nickname",
			headerName: "입금자 정보",
			headerAlign: "center",
			flex: 1,
			minWidth: 300,
			sortable: false,
		},
		{
			field: "order_number",
			headerName: "주문번호",
			flex: 1,
			minWidth: 400,
			headerAlign: "center",
			sortable: false,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.order_number}>
						<p className="table-cell-trucate">{params.row.order_number}</p>
					</Tooltip>
				);
			},
		},
		{
			field: "method",
			headerName: "결제방법",
			flex: 1,
			minWidth: 200,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "amount",
			headerName: "결제금액",
			headerAlign: "center",
			flex: 1,
			minWidth: 100,
			sortable: false,
		},
		{
			field: "star",
			headerName: "지급 스타",
			flex: 1,
			minWidth: 100,
			headerAlign: "center",
			sortable: false,
		},
	];
	const handleChangePage = (e, value) => {
		currentPage.set(value);
	};
	const fetchAll = async () => {
		showLoading();
		try {
			const response = await SaleHistoryApi.getAll(
				currentPage.value,
				LIMITED.value,
				JSON.stringify({ ...filter.value, user_id: data.id })
			);

			settotal(response?.results?.objects.count);
			if (response.code === 200) {
				// xu li pagination
				let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
				spages.set(pages);
				response.results.objects.rows.map((val, idx) => {
					return arr.push({
						id: val?.id,
						no: idx + (currentPage.value - 1) * LIMITED.value + 1,
						purchase_date: convertTimeZone(+val?.created_at_unix_timestamp),
						state: convertTextToKorean(val?.state || "-"),
						product_name: Number(val?.pack?.title).toLocaleString() || "-",
						payer_nickname: val?.user?.nickname || "-",
						order_number: val?.transaction_number,
						method: val?.type || "-",
						amount: Number(val?.pack.price).toLocaleString(),
						star: Number(val?.pack.amount).toLocaleString(),
					});
				});
			}
		} catch (error) {
		} finally {
			hideLoading();
			setItems(arr);
		}
	};

	useEffect(() => {
		fetchAll();
	}, [currentPage.value, LIMITED.value, filter.value]);
	useEffect(() => {
		currentPage.set(1);
	}, [filter.value]);

	console.log(items);

	return (
		<>
			<Stack spacing={2}>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>결제 정보</p>
					</div>
					<TheHeaderDefault
						Component={PaymentFilter}
						typeHeadeFilter={keyHeaderFilter.PAYMENT_USER_PROFILE}
						setFilter={filter.set}
						initValue={{
							time__data: [],
							status: "ALL",
							order_number: "",
							method_payment: "ALL",
							payer_nickname: "",
						}}
					/>
					<DataTable
						columns={columns}
						total={total}
						items={items}
						page={spages.value}
						currentPage={currentPage.value}
						changePage={handleChangePage}
						limit={LIMITED}
						fileName="block user"
					/>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
