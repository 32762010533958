import { PROFILE_IMAGE_STATE, VIDEO_CALL_STATE } from "src/constants";

export const data = {
	banner: {
		fields: [
			// { key: "#" },
			{ key: "image" },

			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
			},
		],
	},
	banner_asp: {
		fields: [
			// { key: "#" },
			{
				key: "image",
				label: "이미지",
				_style: {
					background: "#3C4B64",
					color: "#ffff",
				},
			},

			{
				key: "show_details",
				label: "수정/삭제",
				_style: { width: "200px", textAlign: "center", background: "#3C4B64", color: "#ffff" },
			},
		],
	},
	bannerV2: {
		fields: [
			// { key: "#" },
			{
				key: "image",
				label: "이미지",
				_style: {
					background: "#3C4B64",
					color: "#ffff",
				},
			},

			{
				key: "show_details",
				label: "수정/삭제",
				_style: { width: "200px", textAlign: "center", background: "#3C4B64", color: "#ffff" },
			},
		],
	},
	employee: {
		optionFilter: [
			{ value: "username", label: "User name" },
			{ value: "fullname", label: "Full name" },
			{ value: "email", label: "Email" },
			{ value: "phone", label: "Phone" },
		],
		fields: [
			{ key: "#" },
			{ key: "inden_id", label: "ID" },
			{ key: "fullname" },

			{ key: "email" },
			{ key: "phone" },
			// { key: "type", _style: { minWidth: 120 } },
			{
				key: "updated_at",
				label: "updated_at",
				_style: { width: "200px", textAlign: "center" },
			},
			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
			},
		],
	},

	member: {
		fields: [
			{ key: "#" },
			// { key: "username" },
			{ key: "nickname" },
			{ key: "identified_id" },
			{ key: "avatar" },
			{ key: "avatar_request", label: "List avatar request" },
			{ key: "description" },
			{ key: "birthday", _style: { minWidth: "100px" } },
			// { key: "job"},
			{ key: "gender" },
			// { key: "image_card", label: "Card", },
			// { key: "register_status", label: "Status Register", _style: { minWidth: "170px" } },
			// text === "INAPP" ? { key: "email" } : { key: "phone" },
			{ key: "email" },
			{ key: "phone" },
			{ key: "posts" },
			// { key: "following" },
			// { key: "follower" },
			// { key: "wallet", label: "Wallet (Dool)" },
			// { key: "average_price_audio", label: "Average Price Audio ($)" },
			// { key: "average_price_video", label: "Average Price Video ($)" },
			// { key: "pay_per_call", label: "Pay Per Call (Dool)", _style: { minWidth: "120px" } },
			// { key: "pay_per_video_call", label: "Pay Per Video Call (Dool)", _style: { minWidth: "150px" } },
			// {
			// 	key: "total_income_audio_call",
			// 	label: "Total Time Received Audio Call",
			// 	_style: { minWidth: "150px" },
			// },
			// {
			// 	key: "total_income_video_call",
			// 	label: "Total Time Received Video Call",
			// 	_style: { minWidth: "150px" },
			// },
			// {
			// 	key: "total_earned_audio_call",
			// 	label: "Total Earned Audio Call (Dool)",
			// 	_style: { minWidth: "150px" },
			// },
			// {
			// 	key: "total_earned_video_call",
			// 	label: "Total Earned Video Call (Dool)",
			// 	_style: { minWidth: "150px" },
			// },
			{ key: "login_type" },
			{ key: "user_type", label: "User Type" },
			{ key: "created_at", label: "Created At", _style: { minWidth: "150px" } },
			{ key: "updated_at", label: "Updated At", _style: { minWidth: "150px" } },

			// { key: "status" },
			{ key: "is_presentative", label: "Presentative" },
			{ key: "is_black", label: "Black celeb" },
			{
				key: "show_details",
				label: "Action",
				_style: { minWidth: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],
		fieldsManageCeleb: [
			{ key: "No" },
			{ key: "nickname" },
			{ key: "email" },
			{ key: "birthday", _style: { minWidth: "100px" } },
			{ key: "gender" },
			{ key: "phone" },
			{ key: "platorm", label: "Platform" },
			{ key: "platorm_link", label: "Platform Link" },
			// { key: "agency_code", label: "Agency Code" },
			{ key: "date_register", label: "Day Register", _style: { minWidth: "100px" } },
			{ key: "processed_at_unix_timestamp", label: "Processing Date" },
			{ key: "register_status", label: "Status Register" },
			{ key: "processing", label: "Processing" },
		],
		optionFilter: [
			// { value: "username", label: "Username" },
			{ value: "nickname", label: "Nick name" },
			// { value: "description", label: "Description" },
			{ value: "sex", label: "Gender" },
			{ value: "phone", label: "Phone" },
			{ value: "login_type", label: "Login type" },
			{ value: "user_type", label: "User type" },
			// { value: "state", label: "Status" },
		],
	},
	report: {
		fields: [
			{ key: "No", sorter: false, _style: { textAlign: "center" } },
			{ key: "nickname", label: "User", sorter: false, _style: { textAlign: "center" } },
			{ key: "gender", label: "Gender", sorter: false, _style: { textAlign: "center" } },
			{ key: "phone", label: "Phone", sorter: false, _style: { textAlign: "center" } },
			{ key: "type", label: "Type", sorter: false, _style: { textAlign: "center" } },
			{ key: "reasons", label: "Reason", sorter: false, _style: { textAlign: "center" } },
			{ key: "content", label: "Content", sorter: false, _style: { textAlign: "center" } },
			{
				key: "user_reported",
				label: "User Reported",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{ key: "user_gender", label: "User Gender", sorter: false, _style: { textAlign: "center" } },
			{ key: "created_at", label: "Created At", sorter: false, _style: { textAlign: "center" } },
			{ key: "status", label: "Status", sorter: false, _style: { textAlign: "center" } },
			{ key: "action", label: "Action", sorter: false, _style: { textAlign: "center" } },
		],
		optionFilter: [
			{ value: "$user.nickname$", label: "Nick name" },
			{ value: "type", label: "Type Report" },
		],
	},

	post: {
		fields: [
			{ key: "No", sorter: false },
			{ key: "user", label: "Nickname", sorter: false },
			{ key: "sex", label: "Gender", sorter: false },
			{ key: "type_media", label: "Type Media", sorter: false },
			{ key: "type_post", label: "Type Post", sorter: false },
			{ key: "subscribe_type", label: "Subcrise", sorter: false },
			{ key: "purchase_start", label: "Purchase start", sorter: false },
			{ key: "expose_time", label: "Expose Time", sorter: false },
			{ key: "content", sorter: false },
			{ key: "file", label: "File", sorter: false },
			// { key: "image", sorter: false },
			// { key: "hashtag", sorter: false },
			// { key: "like_post", label: "Likes" },
			// { key: "amount_comment", label: "Comments" },
			{ key: "created_at", label: "Created At", _style: { minWidth: "100px" } },
			// { key: "updated_at", label: "Updated At", _style: { minWidth: "100px" } },
			// { key: "status", sorter: false },
			{
				key: "show_details",
				label: "Other",
				_style: { textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],
		optionFilterNoU: [
			{ value: "nickname", label: "Nickname" },
			// { value: "content", label: "Content" },
			// { value: "hashtag", label: "Hashtag" },
		],
		optionFilterWithU: [
			// { value: "nickname", label: "Nickname" },
			{ value: "content", label: "Content" },
			{ value: "hashtag", label: "Hashtag" },
		],
		optionStatus: [
			{ value: "ALL", label: "All" },
			{ value: "PUBLISH", label: "Publish" },
			{ value: "HIDDEN", label: "Hidden" },
		],
	},

	faq: {
		fields: [
			{ key: "#", sorter: false },
			{ key: "title", label: "Title", sorter: false },
			{ key: "content", label: "Content", sorter: false },
			{ key: "type", label: "Type", sorter: false },
			{ key: "created_at" },
			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],

		optionFilter: [
			{ value: "title", label: "Title" },
			{ value: "content", label: "Content" },
		],

		optionStatus: [
			{ value: "", label: "All" },
			{ value: "FAQ", label: "FAQ" },
			{ value: "NOTICE", label: "NOTICE" },
		],
	},

	policy: {
		fields: [
			{ key: "#", sorter: false },
			// { key: "content", label: "Content", sorter: false },
			{ key: "type", label: "Type", sorter: false },
			{ key: "created_at" },
			{ key: "updated_at" },
			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],

		optionStatus: [
			{ value: "", label: "All" },
			{ value: "TERMS_OF_SERVICE", label: "TERMS OF SERVICE" },
			{ value: "PRIVACY_STATEMENT", label: "PRIVACY STATEMENT" },
			{ value: "VIDEO_CALL_TUTORIAL", label: "VIDEO CALL TUTORIAL" },
			{ value: "MESSAGE_TUTORIAL", label: "MESSAGE TUTORIAL" },
			{ value: "TERMS_AND_CONDITIONS", label: "TERMS AND CONDITIONS" },
			{ value: "AGE", label: "AGE" },
			{ value: "RECEIVCE_ADS", label: "RECEIVCE ADS" },
			{ value: "DELETE_ACCOUNT", label: "DELETE ACCOUNT" },
		],
	},

	inquiry: {
		fields: [
			{ key: "#" },
			{ key: "nickname" },
			// { key: "admin_nickname" },
			// { key: "content", _style: { minWidth: "300px" } },
			{ key: "type" },
			// { key: "images" },
			{ key: "state", label: "Status" },
			{ key: "created_at", _style: { minWidth: "220px" }, label: "Created At" },
			// { key: "date_edit", label: "Date Edit" },
			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],

		optionFilter: [
			{ value: "nickname", label: "Nickname" },
			// { value: "admin_nickname", label: "Admin Nickname" },
			// { value: "title", label: "Title" },
			// { value: "state", label: "Status" },
		],
		optionStatus: [
			{ value: "", label: "All" },
			{ value: "REQUEST", label: "REQUEST" },
			{ value: "DONE", label: "DONE" },
		],
	},

	defaultMess: {
		fields: [
			{ key: "#", sorter: false },
			// {key : 'title',label : 'Title',sorter : false},
			{ key: "content", label: "Content", sorter: false },
			// { key: "type_account", label: "Type", sorter: false },
			// {key : "subscribe_type", label : "Subcrise Type"},
			{ key: "image", sorter: false },
			{ key: "created_at" },
			{ key: "updated_at" },
			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],

		optionFilter: [
			{ value: "title", label: "Title" },
			{ value: "content", label: "Content" },
		],

		optionStatus: [
			{ value: "", label: "All" },
			{ value: "FAQ", label: "FAQ" },
			{ value: "NOTICE", label: "NOTICE" },
		],
	},

	hashtag: {
		fields: [
			{ key: "#", sorter: false },
			{ key: "hashtag", label: "해시태그명", sorter: false },
			{
				key: "show_details",
				label: "비고",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],
	},
	hashtag_asp: {
		fields: [
			{
				key: "active",
				label: "노출선택",
				sorter: false,
			},
			{
				key: "No",
				sorter: false,
			},
			{
				key: "change_order",
				label: "순서변경",
				sorter: false,
			},
			{
				key: "hashtag",
				label: "해시테그명",
				sorter: false,
			},
			{
				key: "show_details",
				label: "수정 및 삭제",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],
	},
	hashtag_V2: {
		fields: [
			{
				key: "active",
				label: "노출선택",
				sorter: false,
			},
			{
				key: "index",
				sorter: false,
			},
			{
				key: "change_order",
				label: "순서변경",
				sorter: false,
			},
			{
				key: "hashtag",
				label: "해시테그명",
				sorter: false,
			},
			{
				key: "show_details",
				label: "수정 및 삭제",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],
	},
	hashtag_V3: {
		fields: [
			{
				key: "index",
				sorter: false,
			},
			{
				key: "change_order",
				label: "순서변경",
				sorter: false,
			},
			{
				key: "hashtag",
				label: "해시테그명",
				sorter: false,
			},
			{
				key: "show_details",
				label: "수정 및 삭제",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],
	},
	exchange: {
		fields: [
			{ key: "#", sorter: false },
			// { key: "user", label: "Nickname", sorter: false },
			// { key: "amount" },
			// { key: "amount_dool" },
			// { key: "state", label: "Status", sorter: false },
			// { key: "note", label: "Note of Admin", sorter: false },
			{ key: "exchange_rate" },
			// { key: "created_at" },
			{ key: "updated_at" },

			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],

		optionFilter: [
			{ value: "nickname", label: "Nickname" },
			{ value: "amount", label: "Amount" },
			{ value: "amount_dool", label: "Amount Dool" },
			{ value: "note", label: "Note" },
			// { value: "state", label: "Status" },
		],

		optionStatus: [
			{ value: "ALL", label: "All" },
			{ value: "PROCESSING", label: "Processing" },
			{ value: "REJECTED", label: "Rejected" },
			{ value: "COMPLETED", label: "Completed" },
			{ value: "CANCELED", label: "Canceled" },
		],
	},
	conversation: {
		fields: [
			{ key: "No", sorter: false, _style: { textAlign: "center" } },
			{ key: "status", label: "Status", sorter: false },
			{ key: "checked_Celeb", sorter: false, _style: { textAlign: "center" } },
			{ key: "image_celeb", label: "Celeb Image", sorter: false, _style: { textAlign: "center" } },
			{
				key: "celeb_nickname",
				label: "Celeb Nick Name",
				sorter: false,
				_style: { textAlign: "center", width: "200px" },
			},
			{ key: "type", label: "Type", sorter: false, _style: { textAlign: "center" } },
			{ key: "type_detail", label: "Type Detail", sorter: false, _style: { textAlign: "center" } },
			{
				key: "last_message",
				label: "Last Message",
				sorter: false,
				_style: { textAlign: "center", width: "150px" },
			},
			{ key: "file", label: "File", sorter: false, _style: { textAlign: "center" } },
			{ key: "checked_normal", sorter: false, _style: { textAlign: "center" } },
			{
				key: "image_normal",
				label: "Normal Image",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "normal_nickname",
				label: "Normal Nick Name",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "amount_user_send",
				label: "Amount User Send",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "message_updated_at_unix_timestamp",
				label: "Last Message Time",
				_style: { textAlign: "center" },
			},
			// {
			//   key: "show_details",
			//   label: "Action",
			//   _style: { minWidth: "380px", textAlign: "center" },
			//   // sorter: false,
			//   // filter: false,
			// },
		],
	},

	notification: {
		fields: [
			{ key: "#" },
			{ key: "title", _style: { minWidth: "200px" } },
			{ key: "message_title", _style: { minWidth: "200px" } },
			{ key: "message_content", _style: { minWidth: "200px" } },
			{ key: "frequency" },
			{ key: "last_number_of_user_to_send", label: "Total User Send" },
			{ key: "sending_unix_timestamp", label: "Date of processing", _style: { minWidth: "200px" } },
			{
				key: "show_details",
				label: "Action",
				_style: { width: "200px", textAlign: "center" },
				// sorter: false,
				// filter: false,
			},
		],

		optionFilter: [
			{ value: "title", label: "Title" },
			{ value: "message_title", label: "Message title" },
			{ value: "message_content", label: "Message content" },
		],
	},
	follow: {
		fields: [
			{ key: "No" },
			{
				key: "celeb_nickname",
				label: "Celeb",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "celeb_gender",
				label: "Gender",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "celeb_phone",
				label: "Phone",
				sorter: false,
				_style: { textAlign: "center" },
			},
			// {
			// 	key: "agency_code",
			// 	label: "Agency Code",
			// 	sorter: false,
			// 	_style: { textAlign: "center" },
			// },
			{
				key: "created_at",
				label: "Created At",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "last_access",
				label: "Last Access",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "user_nickname",
				label: "User",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "user_gender",
				label: "Gender",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "user_phone",
				label: "phone",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "start_date",
				label: "Start Date",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "end_date",
				label: "End Date",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "status",
				label: "Status",
				sorter: false,
				_style: { textAlign: "center" },
			},
		],
	},
	subcription: {
		fields: [
			{ key: "No" },
			{
				key: "celeb_nickname",
				label: "Celeb",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "celeb_gender",
				label: "Gender",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "celeb_phone",
				label: "Phone",
				sorter: false,
				_style: { textAlign: "center" },
			},
			// {
			// 	key: "agency_code",
			// 	label: "Agency Code",
			// 	sorter: false,
			// 	_style: { textAlign: "center" },
			// },
			{
				key: "type_subcribe",
				label: "Type Subcrise",
				sorter: false,
				_style: { textAlign: "center" },
			},

			{
				key: "user_nickname",
				label: "User",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "user_gender",
				label: "Gender",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "created_at",
				label: "Subcribed date",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "deleted_at",
				label: "Unsubcribed date",
				sorter: false,
				_style: { textAlign: "center" },
			},
			{
				key: "status",
				label: "Status",
				sorter: false,
				_style: { textAlign: "center" },
			},
		],
	},

	purchaseHistory: {
		fields: [
			{ key: "No", label: "번호" },
			{
				key: "created_at_unix_timestamp",
				label: "구매 날짜",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "status_purchase",
				label: "구매 상태",
				_style: { textAlign: "center", minWidth: 100 },
				sorter: false,
			},
			{
				key: "platform_paid",
				label: "결제 플랫폼",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "product_name",
				label: "상품 이름",
				_style: { textAlign: "center" },
				sorter: false,
			},
			// {
			// 	key: "membership_number",
			// 	label: "회원번호",
			// 	_style: { textAlign: "center" },
			// 	sorter: false,
			// },
			{
				key: "sender_nickname",
				label: "회원 (ID)",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "sender_fullname",
				label: "입금자정보",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "order_number",
				label: "주문 번호",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "payment_method",
				label: "결제 방식",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "amount_of_payment",
				label: "결제 금액 ",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "paid_star",
				label: "결제 스타 ",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "process",
				label: "프로세스 ",
				_style: { textAlign: "center" },
				sorter: false,
			},
		],
	},
	subscriberHistory: {
		fields: [
			{ key: "No", label: "번호" },
			{
				key: "created_at_unix_timestamp",
				label: "구매 날짜",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "pack_sub_name",
				label: "구독 명칭",
				_style: { textAlign: "center", minWidth: 100 },
				sorter: false,
			},
			{
				key: "price",
				label: "구독 금액",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "celeb_nickname",
				label: "셀럽 닉네임(ID)",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "celeb_gender",
				label: "셀럽 성별",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "agency_username",
				label: "에이전시코드",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "user_nickname",
				label: "구독자 닉네임(ID)",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "user_gender",
				label: "구독자 성별",
				_style: { textAlign: "center" },
				sorter: false,
			},
		],
	},
	webPurchaseHistory: {
		fields: [
			{ key: "No", label: "번호" },
			{
				key: "created_at_unix_timestamp",
				label: "구매 날짜",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "status_purchase",
				label: "구매 상태",
				_style: { textAlign: "center", minWidth: 100 },
				sorter: false,
			},
			{
				key: "payment_type",
				label: "결제 종류",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "product_name",
				label: "상품 이름",
				_style: { textAlign: "center" },
				sorter: false,
			},
			// {
			// 	key: "membership_number",
			// 	label: "회원번호",
			// 	_style: { textAlign: "center" },
			// 	sorter: false,
			// },
			{
				key: "user_nickname",
				label: "회원 (ID)",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "user_fullname",
				label: "입금자정보",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "order_number",
				label: "주문 번호",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "payment_method",
				label: "결제 방법",
				_style: { textAlign: "center", minWidth: 100 },
				sorter: false,
			},
			{
				key: "amount_of_payment",
				label: "결제 금액 ",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "paid_star",
				label: "결제 스타 ",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "process",
				label: "프로세스 ",
				_style: { textAlign: "center" },
				sorter: false,
			},
		],
	},
	monthlysale: {
		fieldsMonthInYear: [
			{ key: "No", label: "번호", _style: { textAlign: "center", whiteSpace: "nowrap" } },
			{ key: "date", label: "날짜", _style: { textAlign: "center" }, sorter: false },
			{ key: "total_month", label: "합계", _style: { textAlign: "center" }, sorter: false },
			{ key: "commission", label: "수수료", _style: { textAlign: "center" }, sorter: false },
			{
				key: "profit_or_loss",
				label: "손익",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "number_of_subscribers",
				label: "가입자수",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "payment_rate",
				label: "결제율",
				_style: { textAlign: "center" },
				sorter: false,
			},
			// {
			// 	key: "cell_phone",
			// 	label: "핸드폰",
			// 	_style: { textAlign: "center" },
			// 	sorter: false,
			// },
			// {
			// 	key: "card",
			// 	label: "카드",
			// 	_style: { textAlign: "center" },
			// 	sorter: false,
			// },
			{
				key: "google",
				label: "구글",
				_style: { textAlign: "center" },
				sorter: false,
			},
			// {
			// 	key: "per_store",
			// 	label: "원스토어",
			// 	_style: { textAlign: "center" },
			// 	sorter: false,
			// },
			{
				key: "apple",
				label: "애플",
				_style: { textAlign: "center" },
				sorter: false,
			},
			// {
			// 	key: "no_bank_book",
			// 	label: "무통장",
			// 	_style: { textAlign: "center" },
			// 	sorter: false,
			// },
			// {
			// 	key: "manual_no_bank_book",
			// 	label: "무통장수동",
			// 	_style: { textAlign: "center" },
			// 	sorter: false,
			// },
		],
	},
	dailysale: {
		fields: [
			{ key: "No", label: "번호" },
			{ key: "date", label: "날짜", _style: { textAlign: "center" }, sorter: false },
			{ key: "total", label: "합계", _style: { textAlign: "center" }, sorter: false },
			{ key: "commission", label: "수수료", _style: { textAlign: "center" }, sorter: false },
			{
				key: "profit_or_loss",
				label: "손익",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "number_of_subscribers",
				label: "가입자수",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "payment_rate",
				label: "결제율",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "google",
				label: "구글",
				_style: { textAlign: "center" },
				sorter: false,
			},

			{
				key: "apple",
				label: "애플",
				_style: { textAlign: "center" },
				sorter: false,
			},
		],
	},
	accumulatedStarCeleb: {
		fields: [
			{ key: "No", label: "번호", _style: { textAlign: "center", whiteSpace: "nowrap" } },
			{
				key: "celeb_nickname",
				label: "셀럽 닉네임",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "age",
				label: "나이",
				_style: { textAlign: "center", whiteSpace: "nowrap" },
				sorter: false,
			},
			{
				key: "celeb_gender",
				label: "성별",
				_style: { textAlign: "center", whiteSpace: "nowrap" },
				sorter: false,
			},
			{
				key: "celeb_phone",
				label: "전화번호",
				_style: { textAlign: "center", whiteSpace: "nowrap" },
				sorter: false,
			},
			{
				key: "total_fee",
				label: "적립금액",
				_style: { textAlign: "center", whiteSpace: "nowrap" },
				sorter: false,
			},
			{
				key: "donated_money",
				label: "적립 후 금액",
				_style: { textAlign: "center", whiteSpace: "nowrap" },
				sorter: false,
			},
			{
				key: "type",
				label: "사유",
				_style: { textAlign: "center", whiteSpace: "nowrap" },
				sorter: false,
			},
			{
				key: "user_nickname",
				label: "유저",
				_style: { textAlign: "center" },
				sorter: false,
			},
			{
				key: "created_at_unix_timestamp",
				label: "변동날짜",
				_style: { textAlign: "center" },
				sorter: false,
			},
		],
	},
};

export const typeMedia = {
	Image: "사진",
	video: "동영상",
	album: "앨범",
};

export const typePost = {
	publicAll: "FREE",
	ONE_TIME: "ONE_TIME",
	SUBCCRIBE: "SUBSCRIBE",
	FREE_SUBCRISE: "FREE_SUBSCRIBER",
};

export const platFormname = {
	YOUTUBE: "YOUTUBE",
	INSTAGRAM: "INSTAGRAM",
	OTHER: "OTHER",
	TWITTER: "TWITTER",
	TIKTOK: "TIKTOK",
};

export const titleHeaderFilter = {
	POST_CELEB: "셀럽 피드 관리",
	CHAT_CELEB: "셀럽 채팅 관리",
	APPECPT_CELEB: "셀럽 승인",
	FOLLOW_CELEB: "셀럽 팔로우 관리",
	SUBCRIPTION_CELEB: "셀럽 구독 관리",
	MONTHLY_SALE: "월 매출 통계",
	DAILY_SALE: "일 매출 통계",
	ACCUMULATED_STAR_CELEB: "코인 적립 통계",
	PURCHASE_HISTORY: "스타 구매내역",
	SURSCRIBER_HISTORY: "구독 매출 통계",
	WEB_PURCHASE_HISTORY: "웹 구매 내역",
	CELEB_CONNECTION_STATUS: "셀럽 접속 현황 및 통계",
	CELEB_CONNECTION_STATUS_COUNT: "실시간 셀럽 접속 현황",
	CELEB_CONNECTION_STATUS_CHART: "일간 셀럽 상태 추이 변화 (1시간마다)",
	ACTIVITY_STATUS_CELEB_MANAGEMENT: "관리 셀럽 리스트",
	ACTIVITY_STATUS_CELEB_MANAGEMENT_INSERT: "셀럽 추가",
	ACTIVITY_STATUS_CELEB_MANAGEMENT_SEARCH: "셀럽 검색",
	PLUSKIT_VIDEO_MANAGEMENT: "플러스킷_스토리지",
	ASP_PLUSKIT_CELEB_MANAGEMENT: "ASP 라운지 개인영상 랜덤 셀럽",
	ASP_PLUSKIT_CELEB_MANAGEMENT_INSERT: "셀럽 추가",
	ASP_PLUSKIT_CELEB_MANAGEMENT_SEARCH: "셀럽 검색",
	BLOCK_USER: "차단회원",
	REPORT_USER: "신고회원",
	PROFILE_IMAGE: "프로필 이미지 승인",
	APPROVE_POST: "피드 승인",
	LIST_USER: "회원 목록",
	LIST_CELEB: "회원 목록",
	SETTING_DEFAULT_MESS: "셀럽 자동메세지 I 설정관리",
	DEFAULT_MESS: "셀럽 자동메세지 I 관리",
	SETTING_DEFAULT_MESS_II: "셀럽 자동메세지 II 설정관리",
	DEFAULT_MESS_II: "셀럽 자동메세지 II 관리",
	SETTING_AUTO_MESS_ASP: "ASP 전용 자동메세지 설정관리",
	AUTO_MESS_ASP: "ASP 전용 자동메세지 관리",
	VIDEO_CALL_LOG: "채팅관리_영상통화로그",
	DELETE_USER: "탈퇴회원",
	CHAT_LOG: "채팅관리_문자채팅로그",
	CELEB_WITHDRAW: "셀럽환급내역",
	INAPP_PAYMENT: "인앱 결제 내역",
	CELEB_REQUEST_WITHDRAW: "셀럽 환급 신청내역",
	AGENCY: "에이전시 관리",
	AGENCY_WITHDRAW: "에이전시 환급 내역",
	AGENCY_REQUEST_WITHDRAW: "에이전시 환급 신청 내역",
	GIFT_LOG: "포인트관리_선물관리",
	STAR_LOG: "포인트관리_스타로그",
	SMS_LOG: "기능관리_SMS로그",
	SMS_PUSH: "기능관리_SMS 발송관리",
	DAILY_SALE_ASP: "일매출통계",
	MONTHLY_SALE_ASP: "월 매출 통계",
	Qna_ASP: "게시판관리_QnA",
	STAR_LOG_ASP: "스타로그",
	COIN_LOG_ASP: "코인로그",
	LIST_USER_ASP: "회원목록",
	VIDEO_CHAT_LOG_ASP: "영상채팅로그",
	CHAT_LOG_ASP: "문자채팅로그",
	PLUSKIT_PURCHASE_LOG_ASP: "ASP 플러스킷 상품 구매 로그",
	PROFILE_IMAGE_ASP: "프로필 이미지 승인",
	REPORT_USER_ASP: "신고회원",
	LIST_INSTAGRAM_USER: "SNS 리포스트 요청 관리",
	INTERMEDIARY_FEE: "중개수수료",
	AGENCY_PUSH_NOTI_ASP_MEMBER: "ASP 회원 전용 푸쉬 발송할 셀럽 에이전시 선택",
	TIME_ZONE_ACCESS_STATISTICS: "시간대별 접속 통계",
	DAILY_ACCESS_STATISTICS: "일별 접속 통계",
	ON_OFF_PLUS_KIT_MANAGEMENT: "셀럽 플러스킷 관리",
	VIDEO_CALL_ERROR_STATISTICS: "영상 통화 오류 통계",
	VIDEO_CALL_CONNECTION_STATUS: "영상통화 연결현황",
	REASON_FOR_VIDEO_CALL_FAILURE: "영상통화 실패사유",
	PLUS_KIT_CONNECTION_STATUS: "PLUS KIT 연결현황",
	PLUS_KIT_FAILURE_REASON: "PLUS KIT 실패사유",
	VIDEO_CALL_RESULT_SYSTEM_LOG: "영상통화 결과 시스템 로그",
	GIFT_REGISTRATION_MANAGEMENT: "선물등록관리",
};

export const keyHeaderFilter = {
	FOLLOW_CELEB: "follow",
	APPECPT_CELEB: "appecpt",
	CHAT_CELEB: "chat",
	POST_CELEB: "post",
	PURCHASE_HISTORY: "purchase_history",
	SUBSCRIBER_HISTORY: "subscriber_history",
	WEB_PURCHASE_HISTORY: "web_purchase_history",
	SUBCRIPTION_CELEB: "subcription",
	ACCUMULATED_STAR_CELEB: "accumulated_star_celeb",
	BLOCK_USER: "block_user",
	PROFILE_IMAGE: "profile_image",
	REPORT_USER: "report_user",
	INQUIRY: "inquiry",
	LIST_USER: "list_user",
	LIST_CELEB: "list_celeb",
	SETTING_DEFAULT_MESS: "setting_default_mess",
	DEFAULT_MESS: "default_mess",
	DELETE_USER: "delete_user",
	VIDEO_CALL_LOG: "video_call_log",
	CHAT_LOG: "CHAT_LOG",
	CELEB_REQUEST_WITHDRAW: "CELEB_REQUEST_WITHDRAW",
	CELEB_WITHDRAW: "CELEB_WITHDRAW",
	STAR_LOG: "STAR_LOG",
	AGENCY_WITHDRAW: "AGENCY_WITHDRAW",
	APPROVE_POST: "approve_post",
	PAYMENT_USER_PROFILE: "PAYMENT_USER_PROFILE",
	STAR_USER_PROFILE: "STAR_USER_PROFILE",
	LIST_COIN_USER_PROFILE: "LIST_USER_PROFILE",
	FEED_LIST_USER_PROFILE: "FEED_LIST_USER_PROFILE",
	FOLLOW_LIST_USER_PROFILE: "FOLLOW_USER_PROFILE",
	FOLLOW_LIST_USER_PROFILE_CELEB: "FOLLOW_USER_PROFILE_CELEB",
	SUPCRIPTION_LIST_USER_PROFILE: "SUPCRIPTION_USER_PROFILE",
	INAPP_PAYMENT: "INAPP_PAYMENT",
	AGENCY_REQUEST_WITHDRAW: "AGENCY_REQUEST_WITHDRAW",
	AGENCY: "AGENCY",
	DAILY_SALE: "DAILY_SALE",
	GIFT_LOG: "GIFT_LOG",
	DAILY_SALE_ASP: "DAILY_SALE_ASP",
	MONTHLY_SALE_ASP: "MONTHLY_SALE_ASP",
	Qna_ASP: "Qna_ASP",
	STAR_LOG_ASP: "Star_LOG_ASP",
	COIN_LOG_ASP: "Coin_LOG_ASP",
	LIST_USER_ASP: "LIST_USER_ASP",
	VIDEO_CHAT_LOG_ASP: "VIDEO_CHAT_LOG_ASP",
	CHAT_LOG_ASP: "CHAT_LOG_ASP",
	PLUSKIT_PURCHASE_LOG_ASP: "PLUSKIT_PURCHASE_LOG_ASP",
	PROFILE_IMAGE_ASP: "PROFILE_IMAGE_ASP",
	REPORT_USER_ASP: "REPORT_USER_ASP",
	LIST_INSTAGRAM_USER: "list_instagram_user",
	INTERMEDIARY_FEE: "INTERMEDIARY_FEE",
	TIME_ZONE_ACCESS_STATISTICS: "TIME_ZONE_ACCESS_STATISTICS",
	DAILY_ACCESS_STATISTICS: "DAILY_ACCESS_STATISTICS",
	CELEB_CONNECTION_STATUS: "CELEB_CONNECTION_STATUS",
	ON_OFF_PLUS_KIT_MANAGEMENT: "ON_OFF_PLUS_KIT_MANAGEMENT",
	VIDEO_CALL_ERROR_STATISTICS: "VIDEO_CALL_ERROR_STATISTICS",
	PLUSKIT_PACKAGE_LIST_ASP: "PLUSKIT_PACKAGE_LIST_ASP",
	GIFT_REGISTRATION_MANAGEMENT: "GIFT_REGISTRATION_MANAGEMENT",
};

export const typeDetailConversation = {
	GROUP: "GROUP",
	PERSONAL: "PERSONAL",
};

export const userType = {
	NORMAL: "NORMAL",
	CELEB: "CELEBRITY",
};

export const blockType = {
	BLOCK: "BLOCK",
	UNBLOCK: "UNBLOCK",
};

export const initValue = {
	CELEB: {
		ACCEPT: {
			memberInfoSelect: "identified_id",
			search_time: "ALL",
			time__data: [],
			memberInfoInput: "",
			platForm: "ALL",
			code_agency: "",
			sex: "ALL",
			platform_link: "",
			status: "ALL",
		},
		SUCCRIPTION: {
			memberInfoSelect: "$pack_sub.celeb.nickname$",
			search_time: "created_at_unix_timestamp",
			time__data: [],
			memberInfoInput: "",
			sex: "ALL",
			code_agency: "",
			type_subcribe: "ALL",
			subcribe_status: "ALL",
		},
		POST: {
			memberInfoSelect: "$user.nickname$",
			search_time: "ALL",
			memberInfoInput: "",
			purchase_starStart: "",
			purchase_starEnd: "",
			time__data: [],
			sex: "ALL",
			public: "ALL",
			paid: "ALL",
		},
		CHAT: {
			// memberInfoSelect: "$admin.nickname$",
			memberInfoSelect: "nickname",
			time__data: [],
			chatState: "ALL",
			memberInfoInput: "",
		},
		FOLLOW: {
			memberInfoSelect: "$user.nickname$",
			search_time: "ALL",
			time__data: [],
			sex: "ALL",
			follow: "ALL",
			memberInfoInput: "",
			code_agency: "",
			phone: "",
		},
		DEFAULT_MESS: {
			memberInfoSelect: "$sender.nickname$",
			memberInfoInput: "",
			time__data: [],
		},
		SETTING_DEFAULT_MESS: {
			memberInfoSelect: "$sender.nickname$",
			time__data: [0, new Date()],
			search_time: "created_at_unix_timestamp",
			sex: "ALL",
			state: "ALL",
			memberInfoInput: "",
			code_agency: "",
		},
		SETTING_DEFAULT_MESS_2: {
			memberInfoSelect: "$sender.nickname$",
			time__data: [0, new Date()],
			search_time: "created_at_unix_timestamp",
			sex: "ALL",
			state: "ACCEPT",
			memberInfoInput: "",
			code_agency: "",
		},
		INTERMEDIARY_FEE: {
			celebInfoSelect: "identified_id",
			celebInfoInput: "",
			sex: "ALL",
			codeAgency: "",
		},
	},
	BOARD: {
		INQUIRY: {
			memberInfoSelect: "$user.identified_id$",
			time__data: [],
			state: "ALL",
			memberInfoInput: "",
			type_question: "ALL",
			title: "",
			user_type: "ALL",
			content: "",
			search_time: "created_at_unix_timestamp",
		},
	},
	USERPROFILE: {
		FOLLOW: {
			memberInfoSelect: "$followed.identified_id$",
			time__data: [0, new Date()],
			search_time: "ALL",
			status: "ALL",
		},
		FOLLOWCELEB: {
			memberInfoSelect: "$user.identified_id$",
			time__data: [0, new Date()],
			search_time: "ALL",
			status: "ALL",
		},
	},
};
export const UseProfile = {
	CELEB: {
		title: "셀럽 상세프로필",
	},
	NORMAL: {
		title: "회원 상세프로필",
	},
};

export const headerFilter = {
	Post: {
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "등록날짜",
			},
			{
				value: "expose_time",
				label: "예약날짜",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		subscriber: [
			{
				value: "ALL",
				checked: true,
				name: "ALL",
				label: "전체",
			},
			{
				value: "BROZEN",
				checked: false,
				name: "BROZEN",
				label: "브론즈",
			},
			{
				value: "SLIVER",
				checked: false,
				name: "SLIVER",
				label: "실버",
			},
			{
				value: "GOLD",
				checked: false,
				name: "GOLD",
				label: "골드",
			},
			{
				value: "PLATINUM",
				checked: false,
				name: "PLANTINUM",
				label: "플래티넘",
			},
		],
		memeberInfo: [
			{
				value: "$user.nickname$",
				label: "셀럽닉네임",
			},
			{
				value: "$user.identified_id$",
				label: "셀럽아이디",
			},
		],
		publics: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "FREE",
				label: "모두공개",
			},
			{
				value: "SUBSCRIBE",
				label: "구독",
			},
			{
				value: "FREE_SUBSCRIBER",
				label: "무료 구독",
			},
			{
				value: "ONE_TIME",
				label: "유료",
			},
		],
		paids: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "FREE_SUBSCRIBER",
				label: "구독자무료",
			},
			{
				value: "SUBSCRIBE",
				label: "구독자유료",
			},
		],
	},
	Supcription: {
		seacrhTime: [
			{
				value: "created_at_unix_timestamp",
				label: "구독시작일",
			},
			{
				value: "expire_at_unix_timestamp",
				label: "구독종료일",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "셀럽남자",
			},
			{
				value: "FEMALE",
				label: "셀럽여자",
			},
			{
				value: "SUB_MALE",
				label: "구독남자",
			},
			{
				value: "SUB_FEMALE",
				label: "구독여자",
			},
		],
		memeberInfo: [
			{
				value: "$pack_sub.celeb.nickname$",
				label: "셀럽닉네임",
			},
			{
				value: "$pack_sub.celeb.identified_id$",
				label: "셀럽아이디",
			},
			{
				value: "$user.nickname$",
				label: "구독자 닉네임 ",
			},
			{
				value: "$user.identified_id$",
				label: "구독자 아이디",
			},
		],
		type_subscriber: [
			{
				value: "ALL",

				label: "전체",
			},
			{
				value: "BROZEN",

				label: "브론즈",
			},
			{
				value: "SLIVER",

				label: "실버",
			},
			{
				value: "GOLD",

				label: "골드",
			},
			{
				value: "PLATINUM",

				label: "플래티넘",
			},
		],
		subscriber_status: [
			{
				value: "ALL",

				label: "전체",
			},
			{
				value: "SUBSCRIBE",

				label: " 구독중",
			},
			{
				value: "UNSUBSCRIBE",

				label: "구독종료",
			},
		],
	},

	Follow: {
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			// {
			// 	value: "$followed.processed_at_unix_timestamp$",
			// 	label: "신청날짜",
			// },
			{
				value: "$followed.last_online$",
				label: "최신로그인",
			},
			{
				value: "created_at_unix_timestamp",
				label: "시작날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "종료일",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "셀럽남자",
			},
			{
				value: "FEMALE",
				label: "셀럽여자",
			},
			{
				value: "SUB_MALE",
				label: "회원남자",
			},
			{
				value: "SUB_FEMALE",
				label: "회원여자",
			},
		],
		memeberInfo: [
			{
				value: "$followed.identified_id$",
				label: "셀럽아이디",
			},
			{
				value: "$followed.nickname$",
				label: "셀럽닉네임",
			},
			{
				value: "$user.identified_id$",
				label: "회원아이디",
			},

			{
				value: "$user.nickname$",
				label: "회원닉네임",
			},
		],
		memeberFollow: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "FOLLOW",
				label: "팔로우",
			},
			{
				value: "UNFOLLOW",
				label: "언팔로우",
			},
		],
	},
	chat: {
		memeberInfo: [
			// {
			// 	value: "$other.nickname$",
			// 	label: "셀럽닉네임",
			// },
			// {
			// 	value: "$other.identified_id$",
			// 	label: "셀럽아이디",
			// },
			// {
			// 	value: "$admin.nickname$",
			// 	label: "회원닉네임",
			// },
			// {
			// 	value: "$admin.identified_id$",
			// 	label: "회원아이디",
			// },
			{
				value: "nickname",
				label: " 닉네임",
			},
			{
				value: "identified_id",
				label: " 아이디",
			},
		],
		statusChatArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "CREATED",
				label: "발송",
			},
			{
				value: "CHATING",
				label: "대화중",
			},
			{
				value: "END_CHAT",
				label: "대화종료",
			},
		],
	},
	BlockUser: {
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		statusType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: blockType.BLOCK,
				label: "차단",
			},
			{
				value: blockType.UNBLOCK,
				label: "차단해제",
			},
		],
	},
	DeleteUser: {
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
	},
	ProfileImage: {
		state: [
			{ value: PROFILE_IMAGE_STATE.PENDING, label: "미인증" },
			{ value: "ALL", label: "전체" },
			{ value: PROFILE_IMAGE_STATE.APPROVED, label: "인증" },
		],
		type: [
			{ value: "ALL", label: "전체" },
			{ value: "AVATAR", label: "프로필 사진" },
			{ value: "PHOTO_COVER", label: "프로필 커버 사진" },
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		limit: [
			{
				value: 20,
				label: 20,
			},
			{
				value: 50,
				label: 50,
			},
			{
				value: 100,
				label: 100,
			},
			{
				value: 200,
				label: 200,
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
	},
	ApproverPost: {
		statusAprrove: [
			{ value: "WAITING_APPROVED", label: "미승인" },
			{ value: "ALL", label: "전체" },
			{ value: "APPROVED", label: "승인" },
			{ value: "ARCHIVED", label: " 보류" },
			// { value: "DELETE", label: "삭제" },
		],
		feedType: [
			{ value: "ALL", label: "전체" },
			{ value: "IMAGE", label: "사진" },
			{ value: "VIDEO", label: "동영상" },
			// { value: "ALBUM", label: "앨범" },
		],
		limit: [
			{ value: 20, label: 20 },
			{ value: 50, label: 50 },
			{ value: 100, label: 100 },
			{ value: 200, label: 200 },
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
	},
	// ApproverPost: {
	// 	statusAprrove: [
	// 		{ value: "PENDING", label: "미승인" },
	// 		{ value: "ALL", label: "전체" },
	// 		{ value: "APPROVED", label: "승인" },
	// 		{ value: "DELETE", label: "삭제" },
	// 	],
	// 	feedType: [
	// 		{ value: "ALL", label: "전체" },
	// 		{ value: "PICTURE", label: "사진" },
	// 		{ value: "VIDEO", label: "동영상" },
	// 		{ value: "ALBUM", label: "앨범" },
	// 	],
	// 	limit: [
	// 		{ value: 20, label: 20 },
	// 		{ value: 50, label: 50 },
	// 		{ value: 100, label: 100 },
	// 		{ value: 200, label: 200 },
	// 	],
	// 	genderArr: [
	// 		{
	// 			value: "ALL",
	// 			label: "전체",
	// 		},
	// 		{
	// 			value: "MALE",
	// 			label: "남자",
	// 		},
	// 		{
	// 			value: "FEMALE",
	// 			label: "여자",
	// 		},
	// 	],
	// },

	report: {
		typeReport: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "OBSCENE_POSTS_OR_REMARKS",
				label: "음란성포스트 또는 발언",
			},
			{
				value: "IMPERSONATING_OTHERS_AND_STEALING",
				label: "타인사칭 및 도용",
			},
			{
				value: "SLANDER_AND_INAPPROPRIATE_REMARKS",
				label: "비방 및 부적절한 단어 ",
			},
		],
		SatusReport: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "PENDING",
				label: "미처리",
			},
			{
				value: "DONE",
				label: "처리완료",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
	},
	listUser: {
		statusAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
			{
				value: "DELETE",
				label: "탈퇴",
			},
		],
		device: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "DESKTOP",
				label: "desktop",
			},
			{
				value: "PHONE",
				label: "phone",
			},
		],
		platform: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "WEB",
				label: "browser",
			},
			{
				value: "IOS",
				label: "ios",
			},
			{
				value: "AOS",
				label: "aos",
			},
		],
		authenticateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: true,
				label: "인증",
			},
			{
				value: false,
				label: "미인증",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		csLevel: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "WARNING",
				label: "주의",
			},
			{
				value: "RISK",
				label: "위험",
			},
		],

		pushVideo: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: true,
				label: "ON",
			},
			{
				value: false,
				label: "OFF",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		pushChat: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "ON",
				label: "ON",
			},
			{
				value: "OFF",
				label: "OFF",
			},
		],
		accept: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "PENDING",
				label: "승인대기",
			},
			{
				value: "ACCEPT",
				label: "승인완료",
			},
			{
				value: "DENY",
				label: "비승인",
			},
		],
	},
	listInstagramUser: {
		instagramUpdateStatus: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: true,
				label: "완료",
			},
			{
				value: false,
				label: "미확인",
			},
		],
		memeberInfo: [
			{
				value: "NORMAL_ID",
				label: "아이디",
			},
			{
				value: "NORMAL_NICKNAME",
				label: "닉네임",
			},
		],
	},
	settingDefaultMess: {
		seacrhTime: [
			{
				value: "created_at_unix_timestamp",
				label: "등록날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "처리날짜",
			},
		],
		accept: [
			// {
			// 	value: "ALL",
			// 	label: "전체",
			// },
			// {
			// 	value: "PENDING",
			// 	label: "승인대기",
			// },
			{
				value: "ACCEPT",
				label: "승인",
			},
			{
				value: "DENY",
				label: "비승인",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],

		memeberInfo: [
			{
				value: "$sender.nickname$",
				label: "셀럽닉네임",
			},
			{
				value: "$sender.identified_id$",
				label: "셀럽아이디",
			},
		],
	},
	qna: {
		state: [
			{
				value: "REQUEST",
				label: "문의중",
			},
			{
				value: "DONE",
				label: "문의완료",
			},
			{
				value: "ALL",
				label: "전체",
			},
		],
		memeberInfo: [
			{
				value: "$user.identified_id$",
				label: "ID",
			},
			{
				value: "$user.nickname$",
				label: "닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			// {
			// 	value: "ALL",
			// 	label: "전체",
			// },
			{
				value: "created_at_unix_timestamp",
				label: "문의날짜",
			},
			{
				value: "processed_at_unix_timestamp",
				label: "답변날짜",
			},
		],
		typeQuestion: [
			{
				value: "ALL",
				label: "전체",
			},
			// {
			// 	value: "SERVICE_INTRODUCTION",
			// 	label: "서비스소개",
			// },
			{
				value: "ACCOUNT_RELATED",
				label: "계정관련",
			},
			{
				value: "PAYMENT_RELATED",
				label: "결제관련",
			},
			{
				value: "OTHER",
				label: "기타",
			},
		],
	},
	StarLogASPHeader: {
		gender: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "BUY_POST",
				label: "피드구매",
			},
			{
				value: "BUY_SECRET_ALBUM",
				label: "앨범구매",
			},

			{
				value: "VIDEO_CALL",
				label: "영상통화",
			},
			{
				value: "TEXT",
				label: "문자채팅",
			},
			{
				value: "PLUS_KIT",
				label: "플러스킷",
			},
			// {
			// 	value: "BUY_SECRET_MESSAGE",
			// 	label: "비밀 메시지 구매",
			// },
		],
		gifts: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "GIFT_FROM_VIDEO",
				label: "영상통화중 선물",
			},
			{
				value: "GIFT_FROM_CHAT",
				label: "문자채팅중 선물",
			},
		],
		memeberInfo: [
			{
				value: "NORMAL_ID",
				label: "회원ID",
			},
			{
				value: "NORMAL_NICKNAME",
				label: "회원닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "문의날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "답변날짜",
			},
		],
		platForm: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "Kdisk",
				label: "Kdisk",
			},
		],
	},
	CoinLogASPHeader: {
		gender: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "BUY_POST",
				label: "피드구매",
			},
			{
				value: "VIDEO_CALL",
				label: "영상통화",
			},
			{
				value: "BUY_SECRET_ALBUM",
				label: "앨범구매",
			},

			{
				value: "TEXT",
				label: "문자채팅",
			},
		],
		gifts: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "GIFT_FROM_VIDEO",
				label: "영상통화중 선물",
			},
			{
				value: "GIFT_FROM_CHAT",
				label: "문자채팅중 선물",
			},
		],
		memeberInfo: [
			{
				value: "CELEB_ID",
				label: "셀럽ID",
			},
			{
				value: "CELEB_NICKNAME",
				label: "셀럽닉네임",
			},
			// {
			// 	value: "NORMAL_ID",
			// 	label: "회원ID",
			// },
			// {
			// 	value: "NORMAL_NICKNAME",
			// 	label: "회원닉네임",
			// },
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "문의날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "답변날짜",
			},
		],
		platForm: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "Kdisk",
				label: "Kdisk",
			},
		],
	},
	VideoChatLogASPHeader: {
		type: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "CELEB_RECEIVER",
				label: "셀럽수신",
			},
			{
				value: "CELEB_CALL",
				label: "셀럽발신",
			},
			{
				value: "PLUS_KIT",
				label: "플러스킷",
			},
		],
		memberInfo: [
			{
				value: "APPLICATION_ID",
				label: "신청ID",
			},
			{
				value: "NORMAL_NICKNAME",
				label: "신청닉네임",
			},
			{
				value: "ACCEPTANCE_ID",
				label: "수락ID",
			},
			{
				value: "ACCEPTANCE_NICKNAME",
				label: "수락닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		searchTime: [
			{
				value: "start_time",
				label: "시작시간",
			},
			{
				value: "end_time",
				label: "종료시간",
			},
		],
		platForm: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "Kdisk",
				label: "Kdisk",
			},
		],
		callState: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: VIDEO_CALL_STATE.CALL_DONE,
				label: "통화 종료",
			},
			{
				value: VIDEO_CALL_STATE.CALLING,
				label: "통화중",
			},
			{
				value: VIDEO_CALL_STATE.CANCELED,
				label: "초대 취소",
			},
			{
				value: VIDEO_CALL_STATE.REFUSED,
				label: "초대 거부",
			},
		],
	},

	ChatLogASPHeader: {
		type: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "CELEB_RECEIVER",
				label: "셀럽수신",
			},
			{
				value: "CELEB_CALL",
				label: "셀럽발신",
			},
		],
		memeberInfo: [
			{
				value: "NORMAL_ID",
				label: "회원ID",
			},
			{
				value: "NORMAL_NICKNAME",
				label: "회원닉네임",
			},
			{
				value: "CELEB_ID",
				label: "셀럽ID",
			},
			{
				value: "CELEB_NICKNAME",
				label: "셀럽닉네임",
			},
		],
		seacrhTime: [
			{
				value: "$conversation.created_at_unix_timestamp$",
				label: "생성일",
			},
			{
				value: "$conversation.last_message.created_at_unix_timestamp$",
				label: "발송일",
			},
			{
				value: "$conversation.updated_at_unix_timestamp$",
				label: "종료일",
			},
		],

		setting: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "FREE_MESSAGE",
				label: "무료",
			},
			{
				value: "PAID_MESSAGE",
				label: "유료",
			},
		],
		state: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "CHATING",
				label: "운영중",
			},
			{
				value: "END_CHAT",
				label: " 종료 ",
			},
		],
		platForm: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "File_group",
				label: "파일조",
			},
			{
				value: "Kdisk",
				label: "Kdisk",
			},
		],
	},

	videoCallLog: {
		seacrhTime: [
			{
				value: "start_time",
				label: "시작시간",
			},
			{
				value: "end_time",
				label: "종료시간",
			},
		],
		type: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "CELEB_RECEIVER",
				label: "셀럽수신",
			},
			{
				value: "CELEB_CALL",
				label: "셀럽발신",
			},
			{
				value: "PLUS_KIT",
				label: "플러스킷",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],

		memeberInfo: [
			{
				value: "$caller.identified_id$",
				label: "신청 아이디",
			},
			{
				value: "$caller.nickname$",
				label: "신청 닉네임",
			},
			{
				value: "$receiver.identified_id$",
				label: "수락 아이디",
			},
			{
				value: "$receiver.nickname$",
				label: "수락 닉네임",
			},
		],

		callState: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: VIDEO_CALL_STATE.CALL_DONE,
				label: "통화 종료",
			},
			{
				value: VIDEO_CALL_STATE.CALLING,
				label: "통화중",
			},
			{
				value: VIDEO_CALL_STATE.CANCELED,
				label: "초대 취소",
			},
			{
				value: VIDEO_CALL_STATE.REFUSED,
				label: "초대 거부",
			},
			{
				value: VIDEO_CALL_STATE.TIMEOUT,
				label: "연결시간초과",
			},
		],
	},
	chatLog: {
		seacrhTime: [
			{
				value: "$conversation.created_at_unix_timestamp$",
				label: "생성일",
			},
			{
				value: "$conversation.updated_at_unix_timestamp$",
				label: "종료일",
			},
		],
		setting: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "FREE_MESSAGE",
				label: "무료",
			},
			{
				value: "PAID_MESSAGE",
				label: "유료",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		type: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "RECEIVCE",
				label: "수신",
			},
			{
				value: "CALL",
				label: "발신",
			},
		],
		state: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "CHATING", //do ông dev trước viết sai chính tả đúng là phải CHATTING
				label: "운영중",
			},
			{
				value: "END_CHAT",
				label: "종료",
			},
			// {
			// 	value: "CREATED",
			// 	label: "만들어진",
			// },
		],
		add_messages: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "FOLLOWER",
				label: "팔로워",
			},
			{
				value: "VIP",
				label: "VIP",
			},
			{
				value: "PERSONAL",
				label: "개인",
			},
			{
				value: "SUBCRIBER",
				label: "구독",
			},
		],
		rank: [
			{
				value: "ALL",
				checked: true,
				name: "ALL",
				label: "전체",
			},
			{
				value: "BROZEN",
				checked: false,
				name: "BROZEN",
				label: "브론즈",
			},
			{
				value: "SLIVER",
				checked: false,
				name: "SLIVER",
				label: "실버",
			},
			{
				value: "GOLD",
				checked: false,
				name: "GOLD",
				label: "골드",
			},
			{
				value: "PLATINUM",
				checked: false,
				name: "PLANTINUM",
				label: "플래티넘",
			},
		],

		memeberInfo: [
			{
				value: "$user.identified_id$",
				label: "ID",
			},
			{
				value: "$user.nickname$",
				label: "닉네임",
			},
			// {
			// 	value: "$receiver.identified_id$",
			// 	label: "수락 아이디",
			// },
			// {
			// 	value: "$receiver.nickname$",
			// 	label: "수락 닉네임",
			// },
		],
	},
	celebWithdraw: {
		stateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
			{
				value: "DELETE",
				label: "탈퇴",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "ACCEPT",
				label: "승인",
			},
			{
				value: "DENY",
				label: "취소",
			},
		],
		memeberInfo: [
			{
				value: "$user.identified_id$",
				label: "ID",
			},
			{
				value: "$user.nickname$",
				label: "닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "신청날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "처리날짜",
			},
		],
	},
	starLog: {
		gender: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "SUB_MALE",
				label: "회원남자",
			},
			{
				value: "SUB_FEMALE",
				label: "회원여자",
			},
			{
				value: "MALE",
				label: "셀럽남자",
			},
			{
				value: "FEMALE",
				label: "셀럽여자",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "BUY_POST",
				label: "피드구매",
			},
			// {
			// 	value: "BUY_SECRET_ALBUM",
			// 	label: "앨범구매",
			// },
			{
				value: "GIFT",
				label: "선물",
			},
			// {
			// 	value: "SUBSCRIPTION",
			// 	label: "구독",
			// },
			{
				value: "VIDEO_CALL",
				label: "영상통화",
			},
			{
				value: "TEXT",
				label: "문자채팅",
			},
			{
				value: "PLUS_KIT",
				label: "플러스킷",
			},
			// {
			// 	value: "BUY_SECRET_MESSAGE",
			// 	label: "비밀 메시지 구매",
			// },
		],
		gifts: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "GIFT_FROM_VIDEO",
				label: "영상통화중 선물",
			},
			{
				value: "GIFT_FROM_CHAT",
				label: "문자채팅중 선물",
			},
		],
		memeberInfo: [
			{
				value: "NORMAL_ID",
				label: "회원ID",
			},
			{
				value: "NORMAL_NICKNAME",
				label: "회원닉네임",
			},
			{
				value: "CELEB_ID",
				label: "셀럽ID",
			},
			{
				value: "CELEB_NICKNAME",
				label: "셀럽닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "문의날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "답변날짜",
			},
		],
	},
	agency: {
		stateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
			{
				value: "DELETE",
				label: "탈퇴",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "ACCEPT",
				label: "완료",
			},
			{
				value: "DENY",
				label: "취소",
			},
		],
		memeberInfo: [
			{
				value: "username",
				label: "ID",
			},
			{
				value: "nickname",
				label: "닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "등록일 ",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "최근로그인",
			},
		],
	},
	inappWithdraw: {
		stateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
			{
				value: "DELETE",
				label: "탈퇴",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "SUCCESS",
				label: "성공",
			},
			{
				value: "DENY",
				label: "실패",
			},
		],
		platform: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "IAP_GOOGLE",
				label: "구글",
			},
			{
				value: "IAP_APPLE",
				label: "애플",
			},
		],
		memeberInfo: [
			{
				value: "$user.identified_id$",
				label: "ID",
			},
			{
				value: "$user.nickname$",
				label: "닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "문의날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "답변날짜",
			},
		],
		code: [
			{
				value: "Partner Code",
				label: "파트너코드",
			},
			{
				value: "Agency Code",
				label: "에이전시코드",
			},
		],
	},
	agencyWithdraw: {
		stateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
			{
				value: "DELETE",
				label: "탈퇴",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "DENY",
				label: "비승인",
			},
			{
				value: "ACCEPT",
				label: "승인",
			},
		],
		memeberInfo: [
			{
				value: "username",
				label: "ID",
			},
			{
				value: "nickname",
				label: "닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "신청날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "처리날짜",
			},
		],
	},
	agencyRequestWithdraw: {
		memeberInfo: [
			{
				value: "username",
				label: "ID",
			},
			{
				value: "nickname",
				label: "닉네임",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "신청날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "처리날짜",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "PROCESS",
				label: "신청",
			},
			{
				value: "ACCEPT",
				label: "완료",
			},
		],
	},
	celebRequest: {
		stateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
			{
				value: "DELETE",
				label: "탈퇴",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "PROCESS",
				label: "신청",
			},

			{
				value: "ACCEPT",
				label: "승인",
			},
			{
				value: "DENY",
				label: "비승인",
			},
		],
		memeberInfo: [
			{
				value: "$user.identified_id$",
				label: "ID",
			},
			{
				value: "$user.nickname$",
				label: "닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "신청날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "처리날짜",
			},
		],
	},
	PaymentUserProfile: {
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "문의날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "답변날짜",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		statusPayment: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "SUCCESS",
				label: "성공",
			},
			{
				value: "DENY",
				label: "취소",
			},
			{
				value: "FAILED",
				label: "실패",
			},
		],
		methodPayment: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "IAP_GOOGLE",
				label: "구글",
			},
			{
				value: "IAP_APPLE",
				label: "애플",
			},
			// {
			// 	value: "ONE_STORE",
			// 	label: "원스토어",
			// },
			// {
			// 	value: "PHONE",
			// 	label: "핸드폰",
			// },
			// {
			// 	value: "CARD",
			// 	label: "카드",
			// },
			// {
			// 	value: "ATM_PAYMENT",
			// 	label: "무통장",
			// },
			// {
			// 	value: "ATM_HANLDE",
			// 	label: "무통장수동",
			// },
		],
	},
	StarUserProfile: {
		state_revenue: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "IN",
				label: "IN",
			},
			{
				value: "OUT",
				label: "OUT",
			},
		],
		reason: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "BUY_STAR",
				label: "스타 구매 ",
			},
			{
				value: "MANAGER_PAYMENT",
				label: "관리자지급",
			},

			{
				value: "VIDEO_CALL",
				label: "영상채팅",
			},
			{
				value: "SUBSCRIPTION",
				label: "구독",
			},
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "TEXT",
				label: "문자채팅",
			},
			{
				value: "BUY_FEED",
				label: "피드구매",
			},
			{
				value: "PLUS_KIT",
				label: "플러스킷",
			},
			// {
			// 	value: "BUY_POST",
			// 	label: "피드구매",
			// },
			// {
			// 	value: "BUY_SECRET_MESSAGE",
			// 	label: "비밀 메시지 구매",
			// },
			// {
			// 	value: "BUY_SECRET_ALBUM",
			// 	label: "비밀 앨범 구매 ",
			// },
			// {
			// 	value: "GIFT_FROM_VIDEO",
			// 	label: "비디오에서 선물",
			// },
			// { value: "ATTENDANCE", label: "출석" },
			// { value: "ADMIN_ADD_STAR", label: "관리자 별 추가" },
			// { value: "ADMIN_SUB_STAR", label: "관리자 서브 스타" },
		],
	},
	ListCointUserProfile: {
		memeberInfo: [
			{
				value: "$sender.identified_id$",
				label: "회원아이디",
			},
			{
				value: "$sender.nickname$",
				label: "회원닉네임",
			},
		],
		reason: [
			{
				value: "ALL",
				label: "전체",
			},
			// {
			// 	value: "TEXT",
			// 	label: "TEXT",
			// },
			{
				value: "VIDEO_CALL",
				label: "영상채팅",
			},
			{
				value: "SUBSCRIPTION",
				label: "구독",
			},
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "CHATTING",
				label: "문자채팅",
			},
			{
				value: "BUY_POST",
				label: "피드구매",
			},
			{
				value: "PLUS_KIT",
				label: "플러스킷",
			},
		],
	},
	ListFollowUserProfile: {
		memeberInfo: [
			{
				value: "$followed.identified_id$",
				label: "셀럽아이디",
			},
			{
				value: "$followed.nickname$",
				label: "셀럽닉네임 ",
			},
		],
		memeberInfo2: [
			{
				value: "$user.identified_id$",
				label: " 회원아이디",
			},
			{
				value: "$user.nickname$",
				label: "회원닉네임",
			},
		],
		status: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: true,
				label: "구독 중",
			},
			{
				value: false,
				label: "구독 종료",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		seacrhTime2: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: " 구독시작날짜",
			},
			{
				value: "deleted_at",
				label: "구독종료날짜",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "팔로우",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "언팔로우 ",
			},
		],
		// Request #278 - Remove type_subscriber
		// type_subscriber: [
		// 	{
		// 		value: "ALL",

		// 		label: "전체",
		// 	},
		// 	{
		// 		value: "BROZEN",

		// 		label: "브론즈",
		// 	},
		// 	{
		// 		value: "SLIVER",

		// 		label: "실버",
		// 	},
		// 	{
		// 		value: "GOLD",

		// 		label: "골드",
		// 	},
		// 	{
		// 		value: "PLATINUM",

		// 		label: "플래티넘",
		// 	},
		// ],
	},
	ListFollowUserProfileCeleb: {
		memeberInfo: [
			{
				value: "$followed.identified_id$",
				label: "셀럽아이디",
			},
			{
				value: "$followed.nickname$",
				label: "셀럽닉네임 ",
			},
		],
		memeberInfo2: [
			{
				value: "$user.identified_id$",
				label: " 회원아이디",
			},
			{
				value: "$user.nickname$",
				label: "회원닉네임",
			},
		],
		status: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: true,
				label: "팔로우",
			},

			{
				value: false,
				label: "언팔로우",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		seacrhTime2: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: " 구독시작날짜",
			},
			{
				value: "deleted_at",
				label: "구독종료날짜",
			},
		],
		seacrhTime: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "created_at_unix_timestamp",
				label: "팔로우",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "언팔로우 ",
			},
		],
		type_subscriber: [
			{
				value: "ALL",

				label: "전체",
			},
			{
				value: "BROZEN",

				label: "브론즈",
			},
			{
				value: "SLIVER",

				label: "실버",
			},
			{
				value: "GOLD",

				label: "골드",
			},
			{
				value: "PLATINUM",

				label: "플래티넘",
			},
		],
	},
	ListFeedUserProfile: {
		memeberInfo: [
			{
				value: "$senderidentified_id$",
				label: "아이디",
			},
			{
				value: "$sender.nickname$",
				label: "닉네임",
			},
		],
		typePost: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "IMAGE",
				label: "사진",
			},
			{
				value: "VIDEO",
				label: "영상",
			},
			{
				value: "ALBUM",
				label: "앨범",
			},
		],
		pruchasePeriod: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "ONE_DAY",
				label: "하루",
			},
			{
				value: "ONE_MONTH",
				label: "한달",
			},
			{
				value: "NO_LIMIT",
				label: "무제한",
			},
		],
		setting: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: { $gt: 0 },
				label: "유료",
			},
			{
				value: "0",
				label: "무료",
			},
		],
		seacrhTime: [
			// {
			// 	value: "ALL",
			// 	label: "전체",
			// },
			{
				value: "created_at_unix_timestamp",
				label: " 등록날짜",
			},
			{
				value: "last_date_purchase",
				label: "구매날짜",
			},
		],
	},
	smsPush: {
		stateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
			{
				value: "DELETE",
				label: "탈퇴",
			},
		],
		typeUser: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "회원",
			},
			{
				value: "CELEB",
				label: "셀럽 ",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		stateRequest: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "ACCEPT",
				label: "인증",
			},
			{
				value: "DENY",
				label: "미인증",
			},
		],
	},
	smsLog: {
		seacrhTime: [
			{
				value: "created_at_unix_timestamp",
				label: "등록날짜",
			},
			{
				value: "updated_at_unix_timestamp",
				label: "처리날짜",
			},
		],
		stateAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "USER",
				label: "회원",
			},
			{
				value: "CELEB",
				label: "셀럽",
			},
			{
				value: "USER AND CELEB",
				label: "모두 (기본값은 전체)",
			},
		],
		statusUser: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "SEND",
				label: "예약",
			},
			{
				value: "SUCCESS",
				label: "발송성공",
			},
			{
				value: "ERROR",
				label: "실패 (기본값은 전체)",
			},
		],
	},
	qnaASP: {
		state: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "REQUEST",
				label: "문의중",
			},
			{
				value: "DONE",
				label: "문의완료",
			},
		],
		memeberInfo: [
			{
				value: "$user.identified_id$",
				label: "ID",
			},
			{
				value: "$user.nickname$",
				label: "닉네임",
			},
		],
		userType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: userType.NORMAL,
				label: "회원",
			},
			{
				value: userType.CELEB,
				label: "셀럽",
			},
		],
		seacrhTime: [
			// {
			// 	value: "ALL",
			// 	label: "전체",
			// },
			{
				value: "created_at_unix_timestamp",
				label: "문의날짜",
			},
			{
				value: "processed_at_unix_timestamp",
				label: "답변날짜",
			},
		],
		typeQuestion: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "ACCOUNT_RELATED",
				label: "계정관련",
			},
			{
				value: "PAYMENT_RELATED",
				label: "결제관련",
			},
			{ value: "BUG_REPORT", label: "버그제보" },
			{ value: "BUG", label: "신고하기" },
			{
				value: "OTHER",
				label: "기타",
			},
		],
	},
	listUserASP: {
		statusAccount: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "정상",
			},
			{
				value: "BLOCK",
				label: "계정정지",
			},
		],

		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		ipType: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "$asp_user.register_ip$",
				label: "가입",
			},
			{
				value: "$asp_user.last_login_ip$",
				label: "로그인 ",
			},
		],
	},
	ProfileImageASP: {
		state: [
			{ value: "ALL", label: "전체" },
			{ value: "APPROVED", label: "인증" },
			{ value: "PENDING", label: "미인증" },
		],

		limit: [
			{
				value: 20,
				label: 20,
			},
			{
				value: 50,
				label: 50,
			},
			{
				value: 100,
				label: 100,
			},
			{
				value: 200,
				label: 200,
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
	},
	StarUserProfileASP: {
		state_revenue: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "IN",
				label: "IN",
			},
			{
				value: "OUT",
				label: "OUT",
			},
		],
		reason: [
			{
				value: "ALL",
				label: "전체",
			},

			{
				value: "MANAGER_PAYMENT",
				label: "관리자지급",
			},

			{
				value: "VIDEO_CALL",
				label: "영상채팅",
			},
			// {
			// 	value: "SUBSCRIPTION",
			// 	label: "구독",
			// },
			{
				value: "GIFT",
				label: "선물",
			},
			{
				value: "TEXT",
				label: "문자채팅",
			},
			{
				value: "BUY_POST",
				label: "피드구매",
			},
			{
				value: "PLUS_KIT",
				label: "플러스킷",
			},
		],
	},
	intermediaryFee: {
		celebInfo: [
			{
				value: "identified_id",
				label: "아이디",
			},
			{
				value: "nickname",
				label: "닉네임",
			},
		],
		genderArr: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
	},
	subscriberList: {
		userTypeValue: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "회원",
			},
			{
				value: "CELEBRITY",
				label: "셀럽",
			},
		],
		genderValue: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "MALE",
				label: "남자",
			},
			{
				value: "FEMALE",
				label: "여자",
			},
		],
		userTypeValue: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "NORMAL",
				label: "회원",
			},
			{
				value: "CELEBRITY",
				label: "셀럽",
			},
		],
		typeSearch: [
			{
				value: "member_id",
				label: "회원 아이디",
			},
			{
				value: "member_nickname",
				label: "회원 닉네임",
			},
			{
				value: "celebrity_id",
				label: "셀럽 아이디",
			},
			{
				value: "celebrity_nickname",
				label: "셀럽 닉네임",
			},
		],
	},
	webPurchaseList: {
		paymentValue: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: 20,
				label: "20스타",
			},
			{
				value: 75,
				label: "75스타",
			},
			{
				value: 150,
				label: "150스타",
			},
			{
				value: 300,
				label: "300스타",
			},
			{
				value: 750,
				label: "750스타",
			},
			{
				value: 1550,
				label: "1550스타",
			},
		],
		paymentMethod: [
			{ value: "ALL", label: "전체" },
			{ value: "CREDIT_CARD", label: "신용카드" },
			{ value: "SAMSUNG", label: "삼성페이" },
			{ value: "NAVER", label: "네이버페이" },
			{ value: "KAKAO", label: "카카오페이" },
			{ value: "MOBILE_PAYMENT", label: "휴대폰 결제" },
			{ value: "VIRTUAL_ACCOUNT", label: "가상계좌" },
			{ value: "MY_BANK_ACCOUNT", label: "내통장결제" },
		],

		memeberInfo: [
			{
				value: "$user_payment.identified_id$",
				label: "결제자ID",
			},
			{
				value: "$user_payment.nickname$",
				label: "결제자닉네임",
			},
			{
				value: "$user_payment.fullname$",
				label: "입금자정보",
			},
			{
				value: "hectorId",
				label: "주문번호",
			},
		],
		paymentType: [
			{ value: "ALL", label: "전체" },
			{ value: "STAR", label: "스타" },
			{ value: "AUTO_PAY", label: "스타자동충전" },
			{ value: "AUTO_PAY_CALL", label: "One Click" },
		],
		paymentStatus: [
			{
				value: "ALL",
				label: "전체",
			},
			{
				value: "SUCCESS",
				label: "성공",
			},
			{
				value: "REFUND",
				label: "취소",
			},
		],
	},
};

export const StarType = {
	GIFT: "GIFT",
	BUY_POST: "BUY_POST",
	BUY_SECRET_MESSAGE: "BUY_SECRET_MESSAGE",
	BUY_SECRET_ALBUM: "BUY_SECRET_ALBUM",
	VIDEO_CALL: "VIDEO_CALL",
	SUBSCRIPTION: "SUBSCRIPTION",
};

export const reportType = {
	OBSCENE_POSTS_OR_REMARKS: "OBSCENE_POSTS_OR_REMARKS",
	IMPERSONATING_OTHERS_AND_STEALING: "IMPERSONATING_OTHERS_AND_STEALING",
	SLANDER_AND_INAPPROPRIATE_REMARKS: "SLANDER_AND_INAPPROPRIATE_REMARKS",
};

export const type = {
	ACCEPT: "ACCEPT",
	PENDING: "PENDING",
	DENY: "DENY",
	DONE: "DONE",
};

export const verify = [
	{ title: "인증", value: 1 },
	{ title: "미인증", value: 0 },
];

export const confirmIsOver19Type = [
	{ title: "인증", value: true },
	{ title: "미인증", value: false },
];

export const Methodverify = [
	{ title: "핸드폰", value: "M" },
	{ title: "미인증", value: 0 },
];

export const genderType = [
	{ title: "여자", value: 0 },
	{ title: "남자", value: 1 },
];

export const imageType = ["jpeg", "gif", "bmp", "png"];

export const adminType = {
	SUPER_ADMIN: "SUPERADMIN",
	SUPER_ADMIN_ASP: "SUPERADMIN_ASP",
	ADMIN_ASP: "ADMIN_ASP",
	CONTENT_MANAGER: "CONTENT_MANAGER",
	OPERATOR: "OPERATOR",
};

export const SaleStatisticTableHeader = [
	{
		key: "default_payment",
		label: "기본결제",
		child: [
			{
				key: "total_mobile_payment",
				label: "휴대폰",
			},
			{
				key: "total_credit_card",
				label: "신용카드",
			},
		],
	},
	{
		key: "bank_payment",
		label: "통장결제",
		child: [
			{
				key: "total_virtual_payment",
				label: "010 가상계좌",
			},
			{
				key: "total_my_bank_account",
				label: "내통장결제",
			},
		],
	},
	{
		key: "easy_payment",
		label: "간편결제",
		child: [
			{
				key: "total_samsung",
				label: "삼성페이",
			},
			{
				key: "total_kakao_payment",
				label: "네이버페이",
			},
			{
				key: "total_naver_payment",
				label: "카카오페이",
			},
		],
	},
	{
		key: "mobile_payment",
		label: "인앱결제",
		child: [
			{
				key: "total_google",
				label: "구글",
			},
			{
				key: "total_apple",
				label: "애플",
			},
			{
				key: "total_one_store",
				label: "원스토어",
			},
		],
	},
	// { key: "total_month", label: "합계" },
	// {
	// 	key: "total_user_payment",
	// 	label: "가입자수",
	// },
	// {
	// 	key: "payment_rate",
	// 	label: "결제율",
	// },
];

export const paymentStatus = {
	SUCCESS: "SUCCESS",
	REFUND: "REFUND",
	FAILURE: "FAILURE",
};

export const TYPE_REVENUE = {
	SUBSCRIBE: "SUBSCRIBE",
	RE_SUBSCRIBE: "RE_SUBSCRIBE",
	PAID_MESSAGE: "PAID_MESSAGE",
	GIFT: "GIFT",
	GIFT_FROM_VIDEO: "GIFT_FROM_VIDEO",
	BUY_POST: "BUY_POST",
	BUY_SECRET_ALBUM: "BUY_SECRET_ALBUM",
	BUY_SECRET_MESSAGE: "BUY_SECRET_MESSAGE",
	VIDEO_CALL: "VIDEO_CALL",
	WITHDRAW: "WITHDRAW",
	ADMIN_ADD_STAR: "ADMIN_ADD_STAR",
	ADMIN_SUB_STAR: "ADMIN_SUB_STAR",
	ADMIN_ADD_DONATE_MONEY: "ADMIN_ADD_DONATE_MONEY",
	ADMIN_SUB_DONATE_MONEY: "ADMIN_SUB_DONATE_MONEY",
	SUBSCRIPTION: "SUBSCRIPTION",
	GIFT_FROM_CHAT: "GIFT_FROM_CHAT",
	WITHDRAW_DENY: "WITHDRAW_DENY",
	ATTENDANCE: "ATTENDANCE",
	TEXT: "TEXT",
	ASP_SUBSCRIBE: "ASP_SUBSCRIBE",
};

export const appName = {
	CELEB_APP: "셀럽앱",
	FANSOME_APP: "팬썸앱",
};

export const STATUS_ACTIVITY = {
	ACTIVE: "ACTIVE",
	PAUSE: "PAUSE",
	CALLING: "CALLING",
	UNKNOWN: "UNKNOWN",
	INACTIVE: "INACTIVE",
	TOTAL: "TOTAL",
};

export const CELEB_MANAGEMENT_LOG_TYPE = {
	ACTIVITY: "ACTIVITY",
	RANDOM_PLUSKIT: "RANDOM_PLUSKIT",
	RANDOM_VIDEO_CALL: "RANDOM_VIDEO_CALL",
	PLUSKIT_INTRO: "PLUSKIT_INTRO",
};

export const getAppName = (app) => {
	if (app?.app_version && app?.celeb_app_version)
		return `${appName.CELEB_APP} / ${appName.FANSOME_APP}`;
	if (app?.app_version || app?.celeb_app_version) {
		return app.app_version ? appName.FANSOME_APP : appName.CELEB_APP;
	}
	return "";
};

export const getAppVersion = (app) => {
	if (app?.app_version && app?.celeb_app_version)
		return `${app.celeb_app_version} / ${app.app_version}`;
	if (app?.app_version || app?.celeb_app_version) {
		return app.app_version ? app.app_version : app.celeb_app_version;
	}
	return "";
};
