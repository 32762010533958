import { convertParams } from "src/util";
import API from "./axiosClient";
const table = "usage_star";

export const getList = async (params) =>
	await API.get(`/${table}/admin/get_list_cms`, {
		params: convertParams(params),
	});

export const delele = async (id) => {
	return await API.delete(`/${table}/${id}`);
};

export const deleteMulti = async (params) => {
	return await API.delete(`/${table}`, {
		params: convertParams(params),
	});
};

export const getItem = async (id, params) =>
	await API.get(`/${table}/${id}`, {
		params: convertParams(params),
	});

export const update = async (id, payload) => await API.put(`/${table}/${id}`, payload);

export const adminUpdateStar = async (payload) => await API.post(`/${table}/admin_update_star`, payload);

export const create = async (payload) => await API.post(`/${table}`, payload);
