import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import Stack from "@mui/material/Stack";
import { LoadingContext } from "src/providers/LoadingProvider";

import TheHeaderDefault from "src/components/phase2/NewLayout/TheHeaderDefault";
import { keyHeaderFilter } from "src/util/data";
import useFilters from "src/hooks/useFilter";
import { convertTextToKorean, convertTimeZone } from "src/util/convert";

import { Button, Grid } from "@mui/material";
import FilterInputWrapper from "../NewLayout/FilterInputWrapper";
import SelectInput from "src/components/CustomComponent/SelectInput";
import { UseStarProfileApi } from "src/api";
import * as usageStarApi from "src/api/usageStarApi_";

import DataTable from "src/components/phase2/DataTable";
import { showError, showSuccess } from "src/util/error";
import UserProfileStarASP from "./filter/UserProfileStarASP";
const type = [
	{ title: "지급", value: "PLUS_STAR" },
	{ title: "차감", value: "MINUS_STAR" },
];

const UserInfo = ({ data, fetchAll }) => {
	const arr = [];
	const [items, setItems] = useState([]);
	const [total, settotal] = useState(0);
	const [typeStar, settypeStar] = useState(null);
	const [adminStar, setAdminStar] = useState(null);

	const { showLoading, hideLoading } = useContext(LoadingContext);

	const { LIMITED, currentPage, spages, filter } = useFilters();
	const columns = [
		{
			field: "id",
			hide: true,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "no",
			headerName: "No",
			headerAlign: "center",
			flex: 1,
			sortable: false,
		},
		{
			field: "state_revenue",
			headerName: "입출",
			flex: 1,
			headerAlign: "center",
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						{params.row.receiver_id === data.id ? (
							<p style={{ color: "#236DFF" }}>IN</p>
						) : (
							<p style={{ color: "#FD5257" }}>OUT</p>
						)}
					</>
				);
			},
		},
		{
			field: "reason",
			headerName: "사유",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},
		{
			field: "update_time",
			headerName: "변동시간",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "star",
			headerName: "변동스타",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "total_star",
			headerName: "잔액",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},
	];
	const handleChangePage = (e, value) => {
		currentPage.set(value);
	};
	const getAll = async () => {
		showLoading();
		try {
			const response = await UseStarProfileApi.getAll(
				currentPage.value,
				LIMITED.value,
				JSON.stringify({
					type: { $notIn: ["ADMIN_ADD_STAR", "ADMIN_SUB_STAR", "SUBSCRIPTION", "ASP_SUBSCRIBE"] },
					...filter.value,
					user_id: data.id,
				})
			);

			console.log("payment list -> response", response.results.objects.rows);
			settotal(response?.results?.objects.count);
			if (response.code === 200) {
				// xu li pagination
				let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
				spages.set(pages);
				response.results.objects.rows.map((val, idx) => {
					return arr.push({
						id: val?.id,
						receiver_id: val?.receiver_id,
						no: response?.results?.objects.count - (idx + (currentPage.value - 1) * LIMITED.value),
						reason: convertTextToKorean(val?.type),
						update_time: convertTimeZone(+val?.created_at_unix_timestamp),
						star: val?.type == "SUBSCRIPTION" ? "-" : val?.total_fee,
						total_star: Number(
							val?.receiver_id == val?.sender.id
								? val?.current_wallet_receiver / 200
								: val?.current_wallet
						).toLocaleString(),
					});
				});
			}
		} catch (error) {
		} finally {
			hideLoading();
			setItems(arr);
		}
	};

	const handleChange = (e) => {
		settypeStar(e.target.value);
	};

	const handleAdminPaymentStar = async () => {
		let type = "ADMIN_ADD_STAR";
		if (typeStar === "MINUS_STAR" && +adminStar <= data.wallet) {
			type = "ADMIN_SUB_STAR";
		} else if (typeStar === "PLUS_STAR" && +adminStar >= 0) {
			type = "ADMIN_ADD_STAR";
		} else {
			showError(`차감될 금액이 소유하고 있는 금액과 같거나 적어야 합니다.`, "error", "오류");
			hideLoading();
			return;
		}
		try {
			const res = await usageStarApi.adminUpdateStar({
				total_fee: +adminStar,
				receiver_id: data.id,
				type,
			});
			if (res.code === 200) {
				showSuccess();
				getAll();
			}
		} catch (error) {
			hideLoading();
			showError(error);
		}
	};

	useEffect(() => {
		getAll();
	}, [currentPage.value, LIMITED.value, filter.value, data]);
	useEffect(() => {
		currentPage.set(1);
	}, [filter.value]);

	return (
		<>
			{/* {console.log("cần sửa Star")} */}
			<Stack spacing={2}>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>스타 내역</p>
					</div>
					<TheHeaderDefault
						Component={UserProfileStarASP}
						typeHeadeFilter={keyHeaderFilter.STAR_USER_PROFILE}
						id={data.id}
						setFilter={filter.set}
						initValue={{ time__data: [], state_revenue: "ALL", reson: "ALL," }}
						// cần sửa
					/>
					<DataTable
						columns={columns}
						total={total}
						items={items}
						page={spages.value}
						currentPage={currentPage.value}
						changePage={handleChangePage}
						limit={LIMITED}
						fileName="block user"
					/>
				</div>
				{!data?.is_asp === true && (
					<div>
						<div
							style={{
								background: "#F6F6F6 0% 0% no-repeat padding-box",
								border: "1px solid #E6ECF3",
								height: "50px",
								padding: "13px 22px",
							}}
						>
							<p
							// onMouseOver={() => console.log("datadatadatadata", data)}
							>
								스타지급
							</p>
						</div>
						<Grid
							container
							rowSpacing={1}
							sx={{ mt: 1, p: 2 }}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={6}>
								{/* type */}
								<FilterInputWrapper label={"종류"}>
									<SelectInput
										data={type}
										value={typeStar}
										className="select"
										handleChange={handleChange}
									/>
								</FilterInputWrapper>
							</Grid>

							<Grid item xs={6}>
								{/* STAR */}
								<FilterInputWrapper label={"스타"}>
									<input
										type="text"
										value={adminStar}
										onChange={(e) => setAdminStar(e.target.value)}
										className="custom-input"
									/>
								</FilterInputWrapper>
							</Grid>
						</Grid>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Button
								variant="contained"
								sx={{
									background: "#3B3B3B ",
									border: "1px solid #E1E1E1",
									borderRadius: "10px",
									width: "300px",
									height: "34px",
								}}
								onClick={handleAdminPaymentStar}
							>
								확인
							</Button>
						</div>
					</div>
				)}
			</Stack>
		</>
	);
};

export default UserInfo;
