import Api from "./Api";
import axiosClient from "./axiosClient";

export default class paymentApi extends Api {
	getListWebPurchase(page, limit, filter, data) {
		const url = `${this.HOSTNAME}/${this.table}/admin/get_list_web_payment?filter=${filter}&page=${page}&limit=${limit}&order=[["created_at_unix_timestamp","desc"]]`;
		return axiosClient.get(url, data);
	}

	refundPayment(user_id) {
		const url = `${this.HOSTNAME}/${this.table}/admin/refund_payment/${user_id}`;
		return axiosClient.post(url);
	}
}
