import "./style.css";
import React from "react";
import "./style.scss";
const FilterInputWrapper = ({ label, children, styleFilter={},style_label = {}, styleBody={} }) => {
	return (
		<div className="filterInput" style={{...styleFilter}}>
			<div className="filterInput__label" style={{ ...style_label }}>
				<label style={{ fontSize: 16 }}>{label}</label>
			</div>
			<div className="filterInput__body" style={{ ...styleBody }}>{children}</div>
		</div>
	);
};

export default FilterInputWrapper;
