import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { useState } from "react";
import { headerFilter } from "src/util/data";
import { DataRangePickerField, SelectField } from "../Fields";
import FilterInputWrapper from "../NewLayout/FilterInputWrapper";

import "../style.css";

const ManagepostCeleb = () => {
	const [DateRange, setDateRange] = useState([]);
	const { state_revenue, reason } = headerFilter.StarUserProfile;
	return (
		<FormGroup>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={6}>
					{/* revenue/expenditure */}
					<FilterInputWrapper label="입출">
						<Field name="state_revenue" component={SelectField} data={state_revenue} />
					</FilterInputWrapper>

					{/* time */}
					<FilterInputWrapper label="기간검색">
						<Field
							name="time__data"
							component={DataRangePickerField}
							DateRange={DateRange}
							setDateRange={setDateRange}
						/>
					</FilterInputWrapper>
				</Grid>
				<Grid item xs={6}>
					{/* reason */}
					<FilterInputWrapper label="사유">
						<Field name="reason" component={SelectField} data={reason} />
					</FilterInputWrapper>
				</Grid>
			</Grid>
		</FormGroup>
	);
};

export default ManagepostCeleb;
