import { getToken } from "src/util/Utils";
import axiosClient from "./axiosClient";
// const HOSTNAME = process.env.REACT_APP_HOST_NAME;
const HOSTNAME = process.env.REACT_APP_API;

const token = getToken();

const UploadApi = {
	upload: (file, option) => {
		const url = `${HOSTNAME}/file/upload/1000`;

		return axiosClient.post(url, file, option);
	},
	uploadImg: (file, option) => {
		const url = `${HOSTNAME}/image/upload/1000`;

		return axiosClient.post(url, file, { ...option, headers: { "content-type": "image/*" } });
	},
	uploadImg_FTP: (file, option) => {
		const url = `${HOSTNAME}/file/upload_image_only_jetendcoding`;

		return axiosClient.post(url, file, { ...option, headers: { "content-type": "image/*" } });
	},

	uploadImgs: (file, option) => {
		const url = `${HOSTNAME}/image/upload_multi/400`;
		return axiosClient.post(url, file, option);
	},
	uploadRecord: (file) => {
		const url = `${HOSTNAME}/file/upload/1000`;

		return axiosClient.post(url, file, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
};

export default UploadApi;
