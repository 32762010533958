import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { useState } from "react";
import { headerFilter } from "src/util/data";
import { DataRangePickerField, InputField, SelectField } from "../Fields";
import FilterInputWrapper from "../NewLayout/FilterInputWrapper";
import "../style.css";

const ManagepostCeleb = () => {
	const [DateRange, setDateRange] = useState([]);
	const { statusPayment, methodPayment } = headerFilter.PaymentUserProfile;
	return (
		<FormGroup>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={6}>
					{/* state payment */}
					<FilterInputWrapper label="상태">
						<Field name="status" component={SelectField} data={statusPayment} />
					</FilterInputWrapper>
					{/* order number */}
					<FilterInputWrapper label="주문번호">
						<Field
							name="order_number"
							component={InputField}
							placeholder="입력해주세요"
							className="inputFilter"
						/>
					</FilterInputWrapper>
					{/* time */}
					<FilterInputWrapper label="기간검색">
						<Field
							name="time__data"
							component={DataRangePickerField}
							DateRange={DateRange}
							setDateRange={setDateRange}
						/>
					</FilterInputWrapper>
				</Grid>
				<Grid item xs={6}>
					{/* state payment */}
					<FilterInputWrapper label="결제방법">
						<Field name="method_payment" component={SelectField} data={methodPayment} />
					</FilterInputWrapper>
					{/* payer info */}
					<FilterInputWrapper label="입금자 정보">
						<Field
							name="payer_nickname"
							component={InputField}
							placeholder="입력해주세요"
							className="inputFilter"
						/>
					</FilterInputWrapper>
				</Grid>
			</Grid>
		</FormGroup>
	);
};

export default ManagepostCeleb;
