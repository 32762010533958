import { convertParams } from "src/util";
import API from "./axiosClient";
const HOSTNAME = process.env.REACT_APP_API;

const table = "user";

export const getList = async (params) =>
	await API.get(`/${table}/admin/get_list_cms`, {
		params: convertParams(params),
	});
export const getListEmpty = async (params) =>
	await API.get(`/${table}/admin/get_empty_nice_infos`, {
		params: convertParams(params),
	});
export const getListWhenHaveHashTag = async (params) => {
	return await API.get(`/${table}/admin/get_list_cms`, { params: convertParams(params) });
};
// note function name, delele not delete
export const delele = async (id) => {
	return await API.delete(`/${table}/${id}`);
};

export const deleteMulti = async (params) => {
	return await API.delete(`/${table}`, {
		params: convertParams(params),
	});
};

export const getItem = async (id, params) =>
	await API.get(`/${table}/${id}`, {
		params: convertParams(params),
	});

export const update = async (id, payload) => await API.put(`/${table}/${id}`, payload);

export const create = async (payload) => await API.post(`/${table}`, payload);
export const updateToCeleb = async (id, payload) => {
	try {
		const url = `${HOSTNAME}/${table}/${id}`;
		console.log("url", url, payload);
		return await API.put(url, payload);
	} catch (error) {
		alert(error.response.data.message);
	}
};
export const getListASP = async (params) =>
	await API.get(`/${table}/admin/get_list_cms`, {
		params: convertParams(params),
	});

export const getListASPNotLogin = async (params) =>
	await API.get(`/${table}/admin/get_list_user_cms`, {
		params: convertParams(params),
	});
