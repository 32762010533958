import Swal from "sweetalert2";
import moment from "moment";
import {
	typeMedia,
	typePost,
	platFormname,
	userType,
	reportType,
	type,
	adminType,
	STATUS_ACTIVITY,
} from "./data";
import { AsYouType } from "libphonenumber-js";
import * as _ from "lodash";
import company from "src/data/company.json";
import { PROFILE_IMAGE_STATE, TimePeriodEnum } from "src/constants";
import { CALL_STATUS_DONE, CALL_STATUS_TYPE } from "src/Pages/StatisticsManagement/constants";
import { GIFT_REGISTRATION_STATUS } from "src/Pages/GiftRegistrationManagement/constants";

export const getToken = () => JSON.parse(localStorage.getItem("token"));

export const toHHMMSS = (item) => {
	if (!item) {
		return "00:00:00";
	} else {
		var sec_num = parseInt(item, 10); // don't forget the second param
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor((sec_num - hours * 3600) / 60);
		var seconds = sec_num - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return hours + ":" + minutes + ":" + seconds;
	}
};
export function validateString(value) {
	let error;
	if (value.trim() === "") {
		error = ` this field  must have `;
	}
	return error;
}

export function validateUrlImg(value) {
	let error;
	if (!value) {
		error = ` this field  must have `;
	}
	return error;
}
export function validateNumber(value) {
	let error;
	if (isNaN(value)) {
		error = ` this field  must is a number `;
	}
	return error;
}

export function validateWebUrl(value) {
	let error;
	let url = isWebUrl(value);
	if (!url) {
		error = ` this field  must be start with http or https `;
	}
	return error;
}
export function isWebUrl(string) {
	let url;
	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	return url.protocol === "http:" || url.protocol === "https:";
}
export const preventkeyBoard = (event, colFilter) => {
	if (colFilter === "phone") {
		if (!/[0-9]/.test(event.key)) {
			event.preventDefault();
		}
	}
	return;
};

export const handleDelete = async (id, API, setItems, items, num_index = 0) => {
	const response = await API.delete(id, getToken());
	if (response.code === 200) {
		let itemFind;
		if (num_index !== 0) {
			itemFind = items
				.filter((item) => item.id !== id)
				.map((item, index) => {
					return { ...item, no: index + num_index };
				});
		} else {
			itemFind = items.filter((item) => item.id !== id);
		}
		setItems(itemFind);
	} else {
		return;
	}
};
export const handleDeletev3 = async (id, API, fetchAll) => {
	const response = await API.delete(id, getToken());
	if (response.code === 200) {
		fetchAll();
	} else {
		return;
	}
};

const handleDeleteV2 = async (id, API, setItems, items, num_index = 0) => {
	const response = await API.delele(id, getToken());
	if (response.code === 200) {
		let itemFind;
		if (num_index !== 0) {
			itemFind = items
				.filter((item) => item.id !== id)
				.map((item, index) => {
					return { ...item, no: index + num_index };
				});
		} else {
			itemFind = items.filter((item) => item.id !== id);
		}
		setItems(itemFind);
	} else {
		return;
	}
};

export const deleteAction = (id, API, setItems, items, num_index) => {
	Swal.fire({
		title: "삭제 하시겠습니까?",
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "확인",
		cancelButtonColor: "#d33",
		cancelButtonText: "취소",
		customClass: "custom-swal",
	}).then((result) => {
		if (result.isConfirmed) {
			handleDelete(id, API, setItems, items, num_index);
			Swal.fire({
				icon: "success",
				title: "Deleted!",
				showConfirmButton: false,
				timer: 1500,
				customClass: "custom-swal",
			});
		}
	});
};

export const deleteActionV2 = (id, API, setItems, items, num_index) => {
	Swal.fire({
		title: "삭제 하시겠습니까?",
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "확인",
		cancelButtonColor: "#d33",
		cancelButtonText: "취소",
		customClass: "custom-swal",
	}).then((result) => {
		if (result.isConfirmed) {
			handleDeleteV2(id, API, setItems, items, num_index);
			Swal.fire({
				icon: "success",
				title: "Deleted!",
				showConfirmButton: false,
				timer: 1500,
				customClass: "custom-swal",
			});
		}
	});
};

export const checkTypeMedia = (media) => {
	console.log("🚀 ~ file: Utils.js:170 ~ checkTypeMedia ~ media:", media);
	const type = media[0].split(".").slice(-1)[0];
	if (type === "png") return `${typeMedia.Image}(${media.length})`;
	else if (type === "mp4") return `${typeMedia.video}(${media.length})`;
	else return `${typeMedia.album}(${media.length})`;
};

export const checkGender = (gender) => {
	if (gender === "MALE") return "남";
	else return "여";
};

export const checkTypePost = (type) => {
	if (type === typePost.publicAll) return "모두공개";
	if (type === typePost.ONE_TIME) return "유료";
	if (type === typePost.SUBCCRIBE) return "구독";
	if (type === typePost.FREE_SUBCRISE) return "무료 구독";
};
export const checkPlatForm = (platForm) => {
	const { instagram_link, other_link, twitter_link, youtube_link, tiktok_link } = platForm;
	if (JSON.stringify(instagram_link) !== "null") return platFormname.INSTAGRAM;
	else if (JSON.stringify(other_link) !== "null") return platFormname.OTHER;
	else if (JSON.stringify(twitter_link) !== "null") return platFormname.TWITTER;
	else if (JSON.stringify(youtube_link) !== "null") return platFormname.YOUTUBE;
	else if (JSON.stringify(tiktok_link) !== "null") return platFormname.TIKTOK;
	else return null;
};
export const CheckPlatFormV2 = (
	youtube_link,
	instagram_link,
	twitter_link,
	tiktok_link,
	other_link
) => {
	if (instagram_link) return platFormname.INSTAGRAM;
	else if (other_link) return platFormname.OTHER;
	else if (twitter_link) return platFormname.TWITTER;
	else if (youtube_link) return platFormname.YOUTUBE;
	else if (tiktok_link) return platFormname.TIKTOK;
	else return null;
};
export const checkPlatFormLink = (platForm) => {
	const { instagram_link, other_link, twitter_link, youtube_link, tiktok_link } = platForm;
	if (instagram_link) return instagram_link;
	else if (other_link) return other_link;
	else if (twitter_link) return twitter_link;
	else if (youtube_link) return youtube_link;
	else if (tiktok_link) return tiktok_link;
	else return null;
};
export const checkPlatFormLinkV2 = (
	youtube_link,
	instagram_link,
	twitter_link,
	tiktok_link,
	other_link
) => {
	if (instagram_link) return instagram_link;
	else if (other_link) return other_link;
	else if (twitter_link) return twitter_link;
	else if (youtube_link) return youtube_link;
	else if (tiktok_link) return tiktok_link;
	else return null;
};

export const checkUserType = (type) => (type === userType.NORMAL ? "#236DFF" : "#FD5257");
export const checkGenderColor = (type, option) => {
	switch (option) {
		case "COLOR":
			return type === "MALE" ? "#E0F1FF" : "#FFDBDB";
		case "TEXT":
			return type === "MALE" ? "M" : "F";
		default:
			break;
	}
};
export const checkApprovePostImage = (status) => {
	switch (status) {
		case "PENDING":
			return { color: "white", background: "red", text: "미승인" };
		case "APPROVED":
			return { color: "white", background: "blue", text: "승인" };
		default:
			return { color: "white", background: "white", text: "NaN" };
	}
};
export const valueFilter = {
	Follow: (data) => {
		console.log("🎃🎃🎃 ~ file: Utils.js:259 ~ data:", data);
		// console.log(data.sex);
		let filterValue = {};
		//time
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					$or: [
						{
							"$followed.processed_at_unix_timestamp$": {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
						{
							"$followed.last_online$": {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
						{
							created_at_unix_timestamp: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
						{
							updated_at_unix_timestamp: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
					],
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}
		//sex
		if (data.sex) {
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				if (data.sex === "SUB_MALE" || data.sex === "SUB_FEMALE") {
					filterValue = { ...filterValue, "$user.sex$": data?.sex.split("_")[1] };
				} else {
					filterValue = { ...filterValue, "$followed.sex$": data?.sex };
				}
			}
		}
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data?.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		if (data.phone)
			filterValue = {
				...filterValue,
				"$user.phone$": { $iLike: `%25${data?.phone.replace(/\+84/g, "")}%25` },
			};
		if (data.follow) {
			switch (data.follow) {
				case "FOLLOW": {
					filterValue = { ...filterValue, status: true };
					break;
				}
				case "UNFOLLOW": {
					filterValue = { ...filterValue, status: false };
					break;
				}
				default:
					break;
			}
		}
		if (data.code_agency) {
			filterValue = {
				...filterValue,
				"$followed.agency.username$": { $iLike: `%25${data?.code_agency.trim()}%25` },
			};
		}
		console.log("🎃🎃🎃 ~ file: Utils.js:356 ~ filterValue:", filterValue);
		return filterValue;
	},
	Appecpt: (data) => {
		console.log("Appecpt", data);
		let filterValue = {};
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
					updated_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}
		if (data.memberInfoInput) {
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput.trim()}%25` },
			};
		}
		if (data.code_agency) {
			filterValue = {
				...filterValue,
				"$agency.username$": { $iLike: `%25${data?.code_agency}%25` },
			};
		}
		if (data.status) {
			data.status === "ALL"
				? (filterValue = {
						...filterValue,
						register_status: { $in: ["PENDING", "ACCEPT", "DENY"] },
				  })
				: (filterValue = {
						...filterValue,
						register_status: data.status,
				  });
		}
		if (data.sex) {
			data.sex === "ALL"
				? (filterValue = {
						...filterValue,
				  })
				: (filterValue = {
						...filterValue,
						sex: data.sex,
				  });
		}
		// if (data.platform_link) {
		//     console.log("platform_link", data.platform_link);
		//     filterValue = {
		//         ...filterValue,
		//         platform_link: data.platform_link,
		//     };
		// }

		// real
		if (!data.platForm && data.platform_link) {
			filterValue = {
				...filterValue,
				platform_link: data.platform_link,
			};
		} else if (data.platForm) {
			if (data.platForm === "ALL") {
				filterValue = { ...filterValue };
				if (data.platform_link) {
					filterValue.platform_link = data.platform_link;
				}
			} else {
				filterValue = {
					...filterValue,
					[data.platForm]: data.platform_link
						? { $iLike: `%25${data.platform_link}%25` }
						: { $ne: null },
				};
			}
		}
		return filterValue;
	},
	Chat: (data) => {
		console.log("kakaka", data);
		let filterValue = {};
		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				last_time_chat: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		// user info
		if (data.memberInfoInput) {
			// filterValue = {
			//     ...filterValue,
			//     [data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			// };
			if (data?.memberInfoSelect === "nickname") {
				filterValue = {
					...filterValue,
					$or: [
						{ "$other.nickname$": { $iLike: `%25${data?.memberInfoInput}%25` } },
						{ "$admin.nickname$": { $iLike: `%25${data?.memberInfoInput}%25` } },
					],
				};
			}
			if (data?.memberInfoSelect === "identified_id") {
				filterValue = {
					...filterValue,
					$or: [
						{ "$admin.identified_id$": { $iLike: `%25${data?.memberInfoInput}%25` } },
						{ "$other.identified_id$": { $iLike: `%25${data?.memberInfoInput}%25` } },
					],
				};
			}
		}

		// chat state
		if (data.chatState) {
			if (data.chatState === "ALL") filterValue = { ...filterValue };
			else
				filterValue = {
					...filterValue,
					state: data.chatState,
				};
		}
		return filterValue;
	},
	Post: (data) => {
		console.log("Post", data.time__data.length);
		let filterValue = {};
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					$or: [
						{
							created_at_unix_timestamp: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
						{
							expose_time: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
					],
				};
			} else {
				if (data.search_time !== "expose_time")
					filterValue = {
						...filterValue,
						[data.search_time]: {
							$between: [
								moment(data.time__data[0]).valueOf(),
								moment(data.time__data[1]).valueOf(),
							],
						},
					};
				else {
					filterValue = {
						...filterValue,
						$and: [
							{
								[data.search_time]: {
									$between: [
										moment(data.time__data[0]).valueOf(),
										moment(data.time__data[1]).valueOf(),
									],
								},
							},
							{ expose_time: { $not: { $col: "tbl_post.created_at_unix_timestamp" } } },
						],
					};
				}
			}
		}
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		if (data.sex) {
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				filterValue = { ...filterValue, "$user.sex$": data?.sex };
			}
		}
		if (data.public) {
			if (data.public === "ALL") {
				filterValue = { ...filterValue };
			} else {
				filterValue = { ...filterValue, type: data?.public };
			}
		}
		if (data.purchase_starStart)
			filterValue = {
				...filterValue,
				price: {
					$gte: data.purchase_starStart,
				},
			};
		if (data.purchase_starEnd) {
			filterValue = {
				...filterValue,
				price: {
					$lte: data.purchase_starEnd,
				},
			};
		}
		if (data.purchase_starStart && data.purchase_starEnd)
			filterValue = {
				...filterValue,
				price: {
					$between: [data.purchase_starStart, data.purchase_starEnd],
				},
			};

		if (data.paid) {
			if (data.paid === "ALL") {
				filterValue = { ...filterValue };
			} else {
				filterValue = { ...filterValue, type: data.paid };
			}
		}
		if (data.public_detail) {
			const value = data.public_detail.filter((item) => item.checked).map((item) => item.value);
			filterValue = { ...filterValue, subscribe_type: value };
		}
		console.log("🎃🎃🎃 ~ file: Utils.js:569 ~ filterValue:", filterValue);
		return filterValue;
	},
	subcription: (data) => {
		console.log("subcription", data);
		let filterValue = {};
		// time
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
					expire_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [
							moment(data.time__data[0]).valueOf(),
							isNaN(data.time__data[1])
								? moment(data.time__data[0])
										.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
										.valueOf()
								: moment(data.time__data[1]).valueOf(),
						],
					},
				};
			}
		}
		// gender
		if (data.sex) {
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				if (data.sex === "SUB_MALE" || data.sex === "SUB_FEMALE") {
					filterValue = { ...filterValue, "$user.sex$": data?.sex.split("_")[1] };
				} else {
					filterValue = { ...filterValue, "$celeb.sex$": data?.sex };
				}
			}
		}

		// status
		if (data.subcribe_status) {
			if (data.subcribe_status === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = { ...filterValue, type: data.subcribe_status };
			}
		}

		// user info
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		//  code agency
		if (data.code_agency)
			filterValue = {
				...filterValue,
				"$pack_sub.celeb.agency.username$": { $iLike: `%25${data?.code_agency}%25` },
			};

		// title subcrise
		if (data.title_subcribe) {
			filterValue = {
				...filterValue,
				"$pack_sub.title$": { $iLike: `%25${data?.title_subcribe}%25` },
			};
		}
		return filterValue;
	},
	accumulated_star_celeb: (data) => {
		console.log("accumulated_star_celeb", data);
		let filterValue = {};
		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		// user info
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]:
					data.memberInfoSelect === "$receiver.sex$"
						? `${
								data?.memberInfoInput === "여" || data?.memberInfoInput === "여자"
									? "FEMALE"
									: data?.memberInfoInput === "남" || data?.memberInfoInput === "남자"
									? "MALE"
									: data?.memberInfoInput
						  }`.toUpperCase()
						: { $iLike: `${data?.memberInfoInput}` },
			};
		// type
		if (data.type) {
			if (data.type === "ALL") {
				filterValue = { ...filterValue };
			} else {
				filterValue = {
					...filterValue,
					type: data.type,
				};
			}
		}
		console.log("accumulated_star_celeb filterValue", filterValue);
		return filterValue;
	},
	purchase_history: (data) => {
		console.log("purchase_history", data);

		let filterValue = {};

		//// member info
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		////platformPaidValue
		if (data.platformPaidValue === "ALL") {
			filterValue = { ...filterValue };
		} else {
			filterValue = { ...filterValue, "$user.sign_in_platform$": data.platformPaidValue };
		}
		//// paymentplatformValue
		if (data.paymentplatformValue === "ALL") {
			filterValue = { ...filterValue };
		} else {
			filterValue = { ...filterValue, type: data.paymentplatformValue };
		}
		//// trang thasi
		if (data.statusValue === "ALL") {
			filterValue = { ...filterValue };
		} else {
			filterValue = { ...filterValue, state: data.statusValue };
		}
		///// sao
		if (data.paymentValue === "ALL") {
			filterValue = { ...filterValue };
		} else {
			filterValue = { ...filterValue, "$pack.sorting$": data.paymentValue };
		}
		if (data.paymentplatformValue) {
			if (data.paymentplatformValue === "ALL") {
				filterValue = { ...filterValue };
			} else {
				filterValue = {
					...filterValue,
					type: data.paymentplatformValue,
				};
			}
		}
		///time
		if (data.time__data.length === 0) {
			filterValue = { ...filterValue };
		} else {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		console.log("purchase_history filterValue", filterValue);

		return filterValue;
	},
	subscriber_history: (data) => {
		let filterValue = {};
		let body = {};

		if (data.userTypeSelect)
			body = {
				...body,
				user_type: data.userTypeSelect,
			};

		if (data.genderSelect)
			body = {
				...body,
				gender: data.genderSelect,
			};

		if (data.agencyInput) {
			body = { ...body, agency_username: data.agencyInput };
		}

		if (data.typeSearchSelect) {
			body = { ...body, type_search: data.typeSearchSelect };
		}

		if (data.dataSearchInput) {
			body = { ...body, data_search: data.dataSearchInput };
		}

		if (data.time__data.length === 0) {
			filterValue = { ...filterValue };
		} else {
			filterValue = {
				...filterValue,
				updated_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		return { filterValue, body };
	},
	web_purchase_history: (data) => {
		let filterValue = {};

		//// member info
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		////payment Method Value
		switch (data.paymentplatformValue) {
			case "KAKAO":
				filterValue = { ...filterValue, ezpDivCd: "KKP" };
				break;
			case "NAVER":
				filterValue = { ...filterValue, ezpDivCd: "NVP" };
				break;
			case "SAMSUNG":
				filterValue = { ...filterValue, mchtId: "fanstudy2" };
				break;
			case "CREDIT_CARD":
				filterValue = { ...filterValue, mchtId: "fanstudy1" };
				break;
			case "MY_BANK_ACCOUNT":
				filterValue = { ...filterValue, method: "MBA" };
				break;
			case "VIRTUAL_ACCOUNT":
				filterValue = { ...filterValue, method: "VA" };
				break;
			case "MOBILE_PAYMENT":
				filterValue = { ...filterValue, method: "MP" };
				break;
			default:
				filterValue = { ...filterValue };
				break;
		}

		///// sao
		if (data.paymentValue === "ALL") {
			filterValue = { ...filterValue, star_id: { $not: null } };
		} else {
			filterValue = { ...filterValue, "$payment_star.star$": data.paymentValue };
		}
		if (data.paymentType) {
			if (data.paymentType === "ALL") {
				filterValue = {
					...filterValue,
					mchtParam: { $and: [{ $not: "SUBSCRIBE" }, { $not: "EXTEND_SUBSCRIBER" }] },
				};
			} else {
				filterValue = {
					...filterValue,
					mchtParam: data.paymentType,
				};
			}
		}
		///time
		if (data.time__data.length === 0) {
			filterValue = { ...filterValue };
		} else {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		// Payment status
		if (data.paymentStatus !== "ALL") {
			filterValue = { ...filterValue, status: data.paymentStatus };
		}

		return filterValue;
	},
	Block: (data) => {
		console.log("Block", data);
		let filterValue = {};
		if (data.id_sender) {
			filterValue = {
				...filterValue,
				"$sender.identified_id$": { $iLike: `%25${data.id_sender}%25` },
			};
		}
		if (data.id_blocked) {
			filterValue = {
				...filterValue,
				"$receiver.identified_id$": { $iLike: `%25${data.id_blocked}%25` },
			};
		}
		if (data.sender_nickname) {
			filterValue = {
				...filterValue,
				"$sender.nickname$": { $iLike: `%25${data.sender_nickname}%25` },
			};
		}
		if (data.blocked_nickname) {
			filterValue = {
				...filterValue,
				"$receiver.nickname$": { $iLike: `%25${data.blocked_nickname}%25` },
			};
		}
		if (data.date_blocked[0]) {
			console.log("data.date_blocked");
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [
						moment(data.date_blocked[0]).valueOf(),
						moment(data.date_blocked[1]).valueOf(),
					],
				},
			};
		}
		if (data.sender_type) {
			if (data.sender_type === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$sender.user_type$": data.sender_type,
				};
			}
		}
		if (data.blocked_type) {
			if (data.blocked_type === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$receiver.user_type$": data.blocked_type,
				};
			}
		}

		if (data.status) {
			if (data.status === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					deleted_at: data.status === "BLOCK" ? null : { $ne: null },
				};
			}
		}

		return filterValue;
	},
	SettingDefaultMess: (data) => {
		console.log("SettingDefaultMess", data);
		let filterValue = {};
		// time

		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				[data.search_time]: {
					$between: [
						moment(data.time__data[0]).valueOf(),
						isNaN(data.time__data[1])
							? moment(data.time__data[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data.time__data[1]).valueOf(),
					],
				},
			};
		}
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		if (data.code_agency)
			filterValue = {
				...filterValue,
				"$sender.agency.username$": { $iLike: `%25${data?.code_agency}%25` },
			};
		if (data.sex) {
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				filterValue = { ...filterValue, "$sender.sex$": { $iLike: `${data?.sex}` } };
			}
		}
		if (data.state) {
			if (data.state === "ALL") filterValue = { ...filterValue };
			else filterValue = { ...filterValue, state: data.state };
		}
		console.log("SettingDefaultMess filterValue", filterValue);
		return filterValue;
	},
	DefaultMess: (data) => {
		console.log(data.time__data.length);
		let filterValue = {};
		// time

		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [
						moment(data.time__data[0]).valueOf(),
						isNaN(data.time__data[1])
							? moment(data.time__data[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data.time__data[1]).valueOf(),
					],
				},
			};
		}
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		return filterValue;
	},
	report: (data) => {
		console.log("report", data);
		let filterValue = {};
		if (data.id_reporter) {
			filterValue = {
				...filterValue,
				"$user.identified_id$": { $iLike: `%25${data.id_reporter}%25` },
			};
		}
		if (data.id_reported) {
			filterValue = {
				...filterValue,
				"$reported.identified_id$": { $iLike: `%25${data.id_reported}%25` },
			};
		}
		if (data.reporter_nickname) {
			filterValue = {
				...filterValue,
				"$user.nickname$": { $iLike: `%25${data.reporter_nickname}%25` },
			};
		}
		if (data.reported_nickname) {
			filterValue = {
				...filterValue,
				"$reported.nickname$": { $iLike: `%25${data.reported_nickname}%25` },
			};
		}

		if (data.account_admin) {
			filterValue = {
				...filterValue,
				"$employee_report.username$": { $iLike: `%25${data.account_admin}%25` },
			};
		}

		if (data.time__data[0]) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		if (data.reporter_userType) {
			if (data.reporter_userType === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$user.user_type$": data.reporter_userType,
				};
			}
		}
		if (data.reported_useType) {
			if (data.reported_useType === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$reported.user_type$": data.reported_useType,
				};
			}
		}
		if (data.type_report) {
			if (data.type_report === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.type_report,
				};
			}
		}
		if (data.status_report) {
			if (data.status_report === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					state: data.status_report,
				};
			}
		}

		return filterValue;
	},
	deleteAccount: (data) => {
		console.log("deleteAccount", data);
		let filterValue = {};
		if (data.id) {
			filterValue = {
				...filterValue,
				identified_id: { $iLike: `%25${data.id}%25` },
			};
		}

		if (data.nickname) {
			filterValue = {
				...filterValue,
				nickname: { $iLike: `%25${data.nickname}%25` },
			};
		}
		if (data.agency) {
			filterValue = {
				...filterValue,
				"$agency.username$": { $iLike: `%25${data.agency}%25` },
			};
		}

		if (data.date_delete.length > 0) {
			filterValue = {
				...filterValue,
				deleted_at_unix_timestamp: {
					$between: [moment(data.date_delete[0]).valueOf(), moment(data.date_delete[1]).valueOf()],
				},
			};
		}
		if (data.user_type) {
			if (data.user_type === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					user_type: data.user_type,
				};
			}
		}
		if (data.gender) {
			if (data.gender === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sex: data.gender,
				};
			}
		}
		if (data.phone_number) {
			filterValue = {
				...filterValue,
				phone: { $iLike: `%25${data?.phone_number.replace(/\+84/g, "")}%25` },
			};
		}

		return filterValue;
	},
	inquiry: (data) => {
		console.log("inquiry", data);
		let filterValue = {};
		// title
		if (data.title) {
			filterValue = {
				...filterValue,
				title: { $iLike: `%25${data.title}%25` },
			};
		}
		// content
		if (data.content) {
			filterValue = {
				...filterValue,
				content: { $iLike: `%25${data.content}%25` },
			};
		}
		// user info
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				[data.search_time]: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		// state
		if (data.state) {
			if (data.state === "ALL")
				filterValue = { ...filterValue, state: { $in: ["DONE", "REQUEST"] } };
			else {
				filterValue = {
					...filterValue,
					state: data.state,
				};
			}
		}
		// type question
		if (data.type_question) {
			if (data.type_question === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.type_question,
				};
			}
		}
		// user type
		if (data.user_type) {
			if (data.user_type === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$user.user_type$": data.user_type,
				};
			}
		}

		return filterValue;
	},
	approvePost: (data) => {
		console.log("approvePost", data);
		let filterValue = {};
		if (data.state) {
			if (data.state === "ALL")
				filterValue = {
					...filterValue,
					state: {
						$in: ["WAITING_APPROVED", "APPROVED", "ARCHIVED", "DELETE"],
					},
				};
			else {
				filterValue = {
					...filterValue,
					state: data.state,
				};
			}
		}
		if (data.type_feed) {
			if (data.type_feed === "ALL") {
				filterValue = {
					...filterValue,
				};
			} else {
				filterValue = {
					...filterValue,
					type_media: data.type_feed,
				};
			}
		}
		if (data.nickname) {
			filterValue = {
				...filterValue,
				"$user.nickname$": { $iLike: `%25${data?.nickname}%25` },
			};
		}
		if (data.identified_id) {
			filterValue = {
				...filterValue,
				"$user.identified_id$": { $iLike: `%25${data?.identified_id}%25` },
			};
		}
		if (data.agency) {
			filterValue = {
				...filterValue,
				agency: { $iLike: `%25${data?.agency}%25` },
			};
		}
		if (data.sex) {
			if (data.sex === "ALL")
				filterValue = {
					...filterValue,
					sex: {
						$in: ["MALE", "FEMALE"],
					},
				};
			else {
				filterValue = {
					...filterValue,
					sex: data.sex,
				};
			}
		}
		if (data?.limit) {
			filterValue = {
				...filterValue,
				limit: data?.limit,
			};
		}
		console.log("approvePost ", filterValue);
		return filterValue;
	},
	// approvePost1: (data) => {
	//     console.log("approvePost", data);
	//     let filterValue = {};
	//     if (data.approve_state) {
	//         if (data.approve_state === "ALL")
	//             filterValue = {
	//                 ...filterValue,
	//                 approve_state: { $in: ["PENDING", "APPROVED", "MEDIA_UPLOADING", "DELETE", "MEDIA_UPLOAD_FAIL"] },
	//             };
	//         else {
	//             filterValue = {
	//                 ...filterValue,
	//                 approve_state: data.approve_state,
	//             };
	//         }
	//     }
	//     if (data.nickname) {
	//         filterValue = {
	//             ...filterValue,
	//             "$user.nickname$": { $iLike: `%25${data?.nickname}%25` },
	//         };
	//     }
	//     if (data.identified_id) {
	//         filterValue = {
	//             ...filterValue,
	//             "$user.identified_id$": { $iLike: `%25${data?.identified_id}%25` },
	//         };
	//     }
	//     if (data.agency) {
	//         filterValue = {
	//             ...filterValue,
	//             "$user.agency.nickname$": { $iLike: `%25${data?.agency}%25` },
	//         };
	//     }
	//     if (data.sex) {
	//         if (data.sex === "ALL") filterValue = { ...filterValue };
	//         else {
	//             filterValue = {
	//                 ...filterValue,
	//                 "$user.sex$": data.sex,
	//             };
	//         }
	//     }

	//     return filterValue;
	// },

	profileImage: (data) => {
		console.log("profileImage", data);
		let filterValue = {};

		// state
		if (data.state) {
			if (data.state === "ALL")
				filterValue = {
					...filterValue,
					state: { $in: [PROFILE_IMAGE_STATE.PENDING, PROFILE_IMAGE_STATE.APPROVED] },
				};
			else {
				filterValue = {
					...filterValue,
					state: data.state,
				};
			}
		}
		// type image
		if (data.type_image) {
			if (data.type_image === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.type_image,
				};
			}
		}
		// type user
		if (data.type_user) {
			if (data.type_user === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$user.user_type$": data.type_user,
				};
			}
		}
		// gender
		if (data.gender) {
			if (data.gender === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$user.sex$": data.gender,
				};
			}
		}
		// nickname
		if (data.nickname) {
			filterValue = {
				...filterValue,
				"$user.nickname$": { $iLike: `%25${data.nickname}%25` },
			};
		}

		// id
		if (data.identified_id) {
			filterValue = {
				...filterValue,
				"$user.identified_id$": { $iLike: `%25${data.identified_id}%25` },
			};
		}
		if (data?.limit) {
			filterValue = {
				...filterValue,
				limit: data?.limit,
			};
		}
		console.log("🎃🎃🎃 ~ file: Utils.js:1269 ~ filterValue:", filterValue);
		return filterValue;
	},
	paymentUserProfile: (data) => {
		console.log("paymentUserProfile", data);
		let filterValue = {};
		// status
		if (data.status) {
			if (data.status === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					state: data.status,
				};
			}
		}
		// order_number
		if (data.order_number) {
			filterValue = {
				...filterValue,
				transaction_number: { $iLike: `%25${data.order_number}%25` },
			};
		}
		// time
		if (data.time__data[0]) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		// method payment
		if (data.method_payment) {
			if (data.method_payment === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.method_payment,
				};
			}
		}
		if (data.payer_nickname) {
			filterValue = {
				...filterValue,
				"$user.nickname$": { $iLike: `%25${data.payer_nickname}%25` },
			};
		}

		return filterValue;
	},
	starUserProfile: (data, id) => {
		console.log("starUserProfile", data);
		let filterValue;
		// time
		if (data.time__data.length === 0) {
			filterValue = { ...filterValue };
		} else {
			if (data.time__data) {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}

		// reason
		if (data.reason) {
			if (data.reason === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.reason,
				};
			}
		}
		//revenue/expenditure
		if (data.state_revenue) {
			if (data.state_revenue === "ALL") filterValue = { ...filterValue };
			else if (data.state_revenue === "IN") {
				filterValue = {
					...filterValue,
					receiver_id: id,
				};
			} else {
				filterValue = {
					...filterValue,
					receiver_id: { $ne: id },
				};
			}
		}
		console.log("starUserProfile filterValue", filterValue);
		return filterValue;
	},
	listCoinUserProfile: (data) => {
		console.log("listCoinUserProfile", data);
		let filterValue;
		// time
		if (data.time__data.length === 0) {
			filterValue = {
				...filterValue,
			};
		} else {
			if (data.time__data) {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}
		// reason
		if (data.reason) {
			if (data.reason === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.reason,
				};
			}
		}
		// user info
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		else {
			filterValue = { ...filterValue };
		}
		console.log("listCoinUserProfile filterValue", filterValue);
		return filterValue;
	},
	feedListUserProfile: (data) => {
		console.log("feedListUserProfile", data);
		let filterValue;

		// type
		if (data?.type_post) {
			if (data.type_post === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type_media: data.type_post,
				};
			}
		}
		// type
		if (data?.setting && data.setting !== "ALL") {
			filterValue = { ...filterValue, price: data?.setting };
		}
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%${data?.memberInfoInput}%` },
			};
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					$or: [
						{
							created_at_unix_timestamp: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
						{
							last_date_purchase: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
					],
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [
							moment(data.time__data[0]).valueOf(),
							isNaN(data.time__data[1])
								? moment(data.time__data[0])
										.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
										.valueOf()
								: moment(data.time__data[1]).valueOf(),
						],
					},
				};
			}
		}

		return filterValue;
	},
	followListUserProfile: (data) => {
		console.log("followListUserProfile", data);
		let filterValue;
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
					updated_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			} else if (data.search_time === "updated_at_unix_timestamp") {
				filterValue = {
					status: false,
					...filterValue,
					[data.search_time]: {
						$between: [
							moment(data.time__data[0]).valueOf(),
							isNaN(data.time__data[1])
								? moment(data.time__data[0])
										.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
										.valueOf()
								: moment(data.time__data[1]).valueOf(),
						],
					},
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [
							moment(data.time__data[0]).valueOf(),
							isNaN(data.time__data[1])
								? moment(data.time__data[0])
										.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
										.valueOf()
								: moment(data.time__data[1]).valueOf(),
						],
					},
				};
			}
		}

		if (data.status) {
			if (data.status === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					status: data.status,
				};
			}
		} else {
			filterValue = {
				...filterValue,
				status: data.status,
			};
		}

		// reason
		if (data?.sex) {
			if (data.sex === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$followed.sex$": data.sex,
				};
			}
		}
		console.log("filterValue followListUserProfile", filterValue);
		return filterValue;
	},
	followListUserProfileCeleb: (data) => {
		console.log("followListUserProfileCeleb", data);
		let filterValue;
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
					updated_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			} else if (data.search_time === "updated_at_unix_timestamp") {
				filterValue = {
					status: false,
					...filterValue,
					[data.search_time]: {
						$between: [
							moment(data.time__data[0]).valueOf(),
							isNaN(data.time__data[1])
								? moment(data.time__data[0])
										.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
										.valueOf()
								: moment(data.time__data[1]).valueOf(),
						],
					},
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [
							moment(data.time__data[0]).valueOf(),
							isNaN(data.time__data[1])
								? moment(data.time__data[0])
										.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
										.valueOf()
								: moment(data.time__data[1]).valueOf(),
						],
					},
				};
			}
		}

		if (data.status) {
			if (data.status === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					status: data.status,
				};
			}
		} else {
			filterValue = {
				...filterValue,
				status: data.status,
			};
		}

		// reason
		if (data?.sex) {
			if (data.sex === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$user.sex$": data.sex,
				};
			}
		}
		console.log("filterValue followListUserProfileCeleb", filterValue);
		return filterValue;
	},
	pluskitPackageListASP: (data) => {
		let filterValue;
		if (data.celebInfoSelect && data.celebInfoInput) {
			filterValue = {
				...filterValue,
				[data.celebInfoSelect]: { $iLike: `%25${data.celebInfoInput}%25` },
			};
		}
		if (data.status !== "ALL") {
			filterValue = {
				...filterValue,
				status: data.status,
			};
		}
		if (data.time__data.length > 0) {
			if (data.timeInfoSelect !== "ALL") {
				filterValue = {
					...filterValue,
					[data.timeInfoSelect]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
					[data.timeInfoSelect]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}
		if (data.type !== "ALL") {
			filterValue = {
				...filterValue,
				"$pack_sub.type$": data.type,
			};
		}
		return filterValue;
	},
	supcriptionListUserProfile: (data) => {
		console.log("supcriptionListUserProfile", data);
		let filterValue;
		// time
		if (data.time__data.length > 0) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
					expire_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}
		// gender
		if (data.gender) {
			if (data.gender === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$user.sex$": data.gender,
				};
			}
		}
		// type
		if (data.status) {
			if (data.status === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.status,
				};
			}
		}
		// type
		if (data.type_subcriber) {
			if (data.type_subcriber === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$pack_sub.type$": data.type_subcriber,
				};
			}
		}

		return filterValue;
	},
	listUser: (data) => {
		let filterValue = {};
		let niceInfo = {};
		let agency = {};
		let partner = {};
		//     data.concerns_1 || data.concerns_2 || data.concerns_3
		//         ? {
		//                 "$user_hashtags.hashtag.title$": {
		//                     $in: [data.concerns_1, data.concerns_2, data.concerns_3],
		//                 },
		//           }
		//         : {};

		if (data.status_account) {
			if (data.status_account === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					status_account: data.status_account,
				};
			}
		}
		if (data.sign_up_platform) {
			if (data.sign_up_platform === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sign_up_platform: data.sign_up_platform,
				};
			}
		}
		if (data.sign_in_platform) {
			if (data.sign_in_platform === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sign_in_platform: data.sign_in_platform,
				};
			}
		}
		if (data.sign_up_device) {
			if (data.sign_up_device === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sign_up_device: data.sign_up_device,
				};
			}
		}
		if (data.sign_in_device) {
			if (data.sign_in_device === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sign_in_device: data.sign_in_device,
				};
			}
		}

		// if (data.auth_account === "ALL") filterValue = { ...filterValue };
		// else {
		//     filterValue = {
		//         ...filterValue,
		//         is_over_19: data.auth_account,
		//     };
		// }

		if (data.sex) {
			if (data.sex === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sex: data.sex,
				};
			}
		}

		if (data.push_chat === "ALL") filterValue = { ...filterValue };
		else {
			filterValue = {
				...filterValue,
				"$user_setting.allow_message$": data.push_chat,
			};
		}

		if (data.state_register) {
			if (data.state_register === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					register_status: data.state_register,
				};
			}
		}
		if (data.cs) {
			if (data.cs === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					cs_level: data.cs,
				};
			}
		}

		if (data.push_video === "ALL") filterValue = { ...filterValue };
		else {
			filterValue = {
				...filterValue,
				"$user_setting.allow_video_call$": data.push_video,
			};
		}

		if (data.user_type) {
			if (data.user_type === "ALL") filterValue = { ...filterValue };
			else if (data.user_type === "CELEBRITY") {
				filterValue = {
					...filterValue,
					user_type: data.user_type,
					register_status: "ACCEPT",
				};
			} else {
				filterValue = {
					...filterValue,
					user_type: data.user_type,
				};
			}
		}
		//id
		if (data.ID)
			filterValue = {
				...filterValue,
				identified_id: { $iLike: `%${data?.ID}%` },
			};
		//nice_infooooo
		if (data.auth_account === "ALL") {
			niceInfo = { ...niceInfo };
		} else {
			if (data.auth_account === true) {
				niceInfo = {
					...niceInfo,
					id: { $ne: null },
				};
			} else {
				filterValue = {
					...filterValue,
					auth_account: false,
				};
			}
			// else {
			//     filterValue = {
			//         ...filterValue,
			//         $nice_infos$: { $ne: null },
			//     };
			// }
		}
		const nice_utf8_name = encodeURIComponent(data?.realname);
		if (data.realname)
			niceInfo = {
				...niceInfo,
				nice_utf8_name: { $iLike: `%${nice_utf8_name}%` },
			};
		// phone
		if (data.phone)
			niceInfo = {
				...niceInfo,
				nice_mobile_no: { $iLike: `%${data.phone}%` },
			};
		//nice_info end
		if (data.nickname)
			filterValue = {
				...filterValue,
				nickname: { $iLike: `%${data?.nickname}%` },
			};
		//agency
		if (data.agency)
			agency = {
				...agency,
				username: { $iLike: `%${data?.agency}%` },
			};
		// agency end

		//Partner
		if (data.partner)
			partner = {
				...partner,
				pid: { $iLike: `%${data?.partner}%` },
			};

		// usgae star
		if (data.usage_star_count_start && data.usage_star_count_end) {
			filterValue = {
				...filterValue,
				total_iap_count: {
					$between: [+data.usage_star_count_start, +data.usage_star_count_end],
				},
			};
		} else if (!data.usage_star_count_start && data.usage_star_count_end) {
			filterValue = {
				...filterValue,
				total_iap_count: {
					$between: [+data.usage_star_count_end, +data.usage_star_count_end],
				},
			};
		} else if (data.usage_star_count_start && !data.usage_star_count_end) {
			filterValue = {
				...filterValue,
				total_iap_count: {
					$between: [+data.usage_star_count_start, +data.usage_star_count_start],
				},
			};
		}
		// coin
		if (data.coin_start && data.coin_end) {
			filterValue = {
				...filterValue,
				wallet: {
					$between: [+data.coin_start, +data.coin_end],
				},
			};
		} else if (!data.coin_start && data.coin_end) {
			filterValue = {
				...filterValue,
				wallet: {
					$between: [+data.coin_end, +data.coin_end],
				},
			};
		} else if (data.coin_start && !data.coin_end) {
			filterValue = {
				...filterValue,
				wallet: {
					$between: [+data.coin_start, +data.usage_star_count_start],
				},
			};
		}

		// // TODO: Star
		// if (data.star_start && data.star_end) {
		//     filterValue = {
		//         ...filterValue,
		//         donated_money: {
		//             $between: [+data.star_start, +data.star_end],
		//         },
		//     };
		// } else if (!data.star_start && data.star_end) {
		//     filterValue = {
		//         ...filterValue,
		//         donated_money: {
		//             $between: [+data.star_start, +data.star_end],
		//         },
		//     };
		// } else if (data.star_start && !data.star_end) {
		//     filterValue = {
		//         ...filterValue,
		//         donated_money: {
		//             $between: [+data.star_start, +data.usage_star_count_start],
		//         },
		//     };
		// }

		// birthday
		if (data.age_start && data.age_end) {
			filterValue = {
				...filterValue,
				birthday: {
					$between: [
						moment(moment().subtract(+data.age_start, "year").calendar()).valueOf(),
						moment(moment().subtract(+data.age_end, "year").calendar()).valueOf(),
					],
				},
			};
		} else if (!data.age_start && data.age_end) {
			filterValue = {
				...filterValue,
				birthday: {
					$between: [
						moment(moment().subtract(+data.age_end, "year").calendar()).valueOf(),
						moment(moment().subtract(+data.age_end, "year").calendar()).valueOf(),
					],
				},
			};
		} else if (data.age_start && !data.age_end) {
			filterValue = {
				...filterValue,
				birthday: {
					$between: [
						moment(moment().subtract(+data.age_start, "year").calendar()).valueOf(),
						moment(moment().subtract(+data.age_start, "year").calendar()).valueOf(),
					],
				},
			};
		}

		// sign up time
		if (data.sign_up_time.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [
						moment(data.sign_up_time[0]).valueOf(),
						isNaN(data.sign_up_time[1])
							? moment(data.sign_up_time[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data.sign_up_time[1]).valueOf(),
					],
				},
			};
		}

		// process time
		if (data.celeb_register_day.length > 0) {
			filterValue = {
				...filterValue,
				updated_at_unix_timestamp: {
					$between: [
						moment(data.celeb_register_day[0]).valueOf(),
						isNaN(data.celeb_register_day[1])
							? moment(data.celeb_register_day[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data.celeb_register_day[1]).valueOf(),
					],
				},
			};
		}

		// celeb_accept_day time
		if (data.celeb_accept_day.length > 0) {
			filterValue = {
				...filterValue,
				processed_at_unix_timestamp: {
					$between: [
						moment(data.celeb_accept_day[0]).valueOf(),
						isNaN(data.celeb_accept_day[1])
							? moment(data.celeb_accept_day[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data.celeb_accept_day[1]).valueOf(),
					],
				},
			};
		}

		// sign in time
		if (data.sign_in_time.length > 0) {
			filterValue = {
				...filterValue,
				sign_in_time_unix_timestamp: {
					$between: [
						moment(data.sign_in_time[0]).valueOf(),
						isNaN(data.sign_in_time[1])
							? moment(data.sign_in_time[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data.sign_in_time[1]).valueOf(),
					],
				},
			};
		}
		if (data.email) {
			filterValue = {
				...filterValue,
				email: { $iLike: `%${data?.email}%` },
			};
		}
		if (data?.hashtag_selected.length > 0) {
			filterValue = {
				...filterValue,
				hashtags: data?.hashtag_selected,
			};
		}
		console.log("filterValue listUser", filterValue);
		if (Object.keys(niceInfo).length !== 0) {
			filterValue = { ...filterValue, niceInfo: { ...niceInfo } };
		}
		if (Object.keys(agency).length !== 0) {
			filterValue = { ...filterValue, agency: { ...agency } };
		}
		if (Object.keys(partner).length !== 0) {
			filterValue = { ...filterValue, partner_user: { ...partner } };
		}
		return filterValue;
	},

	listInstagramUser: (data) => {
		console.log("listInstagramUser", data);

		let filterValue = {};

		if (data.instagram_update_status === "ALL") filterValue = { ...filterValue };
		else {
			filterValue = {
				...filterValue,
				instagram_update_status: data.instagram_update_status,
			};
		}

		if (data.memberInfoInput) {
			switch (data.memberInfoSelect) {
				case "NORMAL_ID":
					filterValue = {
						...filterValue,
						identified_id: { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;
				case "NORMAL_NICKNAME":
					filterValue = {
						...filterValue,
						nickname: { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;

				default:
					break;
			}
		}

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				instagram_updated_at: {
					$between: [
						moment(data.time__data[0]).format("YYYY-MM-DD HH:mm:ss"),
						moment(data.time__data[1]).format("YYYY-MM-DD HH:mm:ss"),
					],
				},
			};
		}
		return filterValue;
	},

	listCeleb: (data) => {
		return data;
	},

	celebRequestWithdraw: (data) => {
		console.log("celebRequestWithdraw", data);
		let filterValue;
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};

		//withdrawal_bank
		if (data.withdrawal_bank)
			filterValue = {
				...filterValue,
				"$user.withdrawal_bank$": { $iLike: `%25${data?.withdrawal_bank}%25` },
			};

		//account_holder_name
		if (data.account_holder_name)
			filterValue = {
				...filterValue,
				"$user.account_holder_name$": { $iLike: `%25${data?.account_holder_name}%25` },
			};
		//account_number
		if (data.account_number)
			filterValue = {
				...filterValue,
				"$user.account_number$": { $iLike: `%25${data?.account_number}%25` },
			};
		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				[data.search_time]: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		// state account
		if (data.status_account) {
			if (data.status_account === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					"$user.status_account$": data.status_account,
				};
			}
		}
		// state request
		if (data.status_request) {
			if (data.status_request === "ALL")
				filterValue = { ...filterValue, state: { $in: ["ACCEPT", "DENY", "PROCESS"] } };
			else {
				filterValue = {
					...filterValue,
					state: data.status_request,
				};
			}
		}
		return filterValue;
	},
	celebWithdraw: (data) => {
		console.log("celebWithdraw", data);
		let filterValue;
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};

		//memeberInfo
		if (data.account_admin)
			filterValue = {
				...filterValue,
				// "$employee.id$": data?.account_admin,
				"$employee.username$": { $iLike: `%25${data?.account_admin}%25` },
			};

		// state account
		if (data.status_account) {
			if (data.status_account === "ALL") {
				filterValue = { ...filterValue };
			} else {
				filterValue = {
					...filterValue,
					"$user.status_account$": data.status_account,
				};
			}
		}
		// time
		if (data.time__data.length > 0) {
			if (data?.search_time === "ALL") {
				filterValue = {
					...filterValue,
					$or: [
						{
							created_at_unix_timestamp: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
						{
							updated_at_unix_timestamp: {
								$between: [
									moment(data.time__data[0]).valueOf(),
									moment(data.time__data[1]).valueOf(),
								],
							},
						},
					],
				};
			} else {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}
		// state request
		if (data.status_request) {
			if (data.status_request === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					state: data.status_request,
				};
			}
		}
		return filterValue;
	},

	agencyWithdraw: (data) => {
		console.log("agencyWithdraw", data);
		let filterValue;
		// time
		if (data.time__data.length === 0) {
			filterValue = {
				...filterValue,
			};
		} else {
			if (data.time__data.length > 0) {
				if (data.search_time === "ALL") {
					console.log("ALLALL");
					filterValue = {
						...filterValue,
						$or: [
							{
								updated_at_unix_timestamp: {
									$between: [
										moment(data.time__data[0]).valueOf(),
										moment(data.time__data[1]).valueOf(),
									],
								},
							},
							{
								created_at_unix_timestamp: {
									$between: [
										moment(data.time__data[0]).valueOf(),
										moment(data.time__data[1]).valueOf(),
									],
								},
							},
						],
						// updated_at_unix_timestamp: {
						// $between: [
						//     moment(data.time__data[0]).valueOf(),
						//     moment(data.time__data[1]).valueOf(),
						// ],
						// },
						// created_at_unix_timestamp: {
						// $between: [
						//     moment(data.time__data[0]).valueOf(),
						//     moment(data.time__data[1]).valueOf(),
						// ],
						// },
					};
				} else {
					filterValue = {
						...filterValue,
						[data.search_time]: {
							$between: [
								moment(data.time__data[0]).valueOf(),
								moment(data.time__data[1]).valueOf(),
							],
						},
					};
				}
			}
		}
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: data?.memberInfoInput,
			};

		//account
		if (data.account_admin)
			filterValue = {
				...filterValue,
				employeeFullname: data?.account_admin,
			};
		//email
		if (data.email)
			filterValue = {
				...filterValue,
				email: data?.email,
			};
		//phone
		if (data.phone)
			filterValue = {
				...filterValue,
				phone_number: data?.phone,
			};
		// state request
		if (data.status_request) {
			if (data.status_request === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					state: data.status_request,
				};
			}
		}

		return filterValue;
	},

	agencyRequestWithdraw: (data) => {
		console.log("agencyRequestWithdraw", data);
		let filterValue;
		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				[data.search_time]: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: data?.memberInfoInput,
			};

		//account
		if (data.account_admin)
			filterValue = {
				...filterValue,
				"$employee.fullname$": { $iLike: `%25${data?.account_admin}%25` },
			};
		//email
		if (data.email)
			filterValue = {
				...filterValue,
				email: data?.email,
			};
		//phone
		if (data.phone)
			filterValue = {
				...filterValue,
				phone: data?.phone,
			};
		// state request
		if (data.status_request) {
			if (data.status_request === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					state: data.status_request,
				};
			}
		}

		console.log("🎃🎃🎃 ~ file: Utils.js:2333 ~ filterValue:", filterValue);
		return filterValue;
	},
	ChatLog: (data) => {
		let filterValue = {};
		console.log("ChatLog", data);
		if (data.rank === "ALL") {
			filterValue = {
				...filterValue,
			};
		} else {
			filterValue = {
				...filterValue,
			};
		}
		if (data.state === "ALL") {
			filterValue = {
				...filterValue,
			};
		} else {
			filterValue = {
				...filterValue,
				"$conversation.state$": data.state,
			};
		}
		if (data.setting === "ALL") {
			filterValue = {
				...filterValue,
			};
		} else {
			filterValue = {
				...filterValue,
				"$receiver.user_setting.type_message$": data.setting,
			};
		}
		if (data.type) {
			if (data.type === "ALL") filterValue = { ...filterValue };
			// else {
			//     filterValue = {
			//         ...filterValue,
			//         "$user.type$": data.type,
			//     };
			// }
		}
		//memeberInfo
		if (data.memberInfoInput)
			if (data.memberInfoSelect === "$user.identified_id$") {
				filterValue = {
					...filterValue,
					// [data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
					$or: [
						{ "$user.identified_id$": { $iLike: `%25${data?.memberInfoInput}%25` } },
						{ "$receiver.identified_id$": { $iLike: `%25${data?.memberInfoInput}%25` } },
					],
				};
			} else {
				filterValue = {
					...filterValue,
					// [data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
					$or: [
						{ "$user.nickname$": { $iLike: `%25${data?.memberInfoInput}%25` } },
						{ "$receiver.nickname$": { $iLike: `%25${data?.memberInfoInput}%25` } },
					],
				};
			}

		// $or: [
		//     { "$user.identified_id$": { $iLike: "%thisisinput%" } },
		//     { "$receiver.identified_id$": { $iLike: "%thisisinput%" } },
		// ];
		// time
		if (
			(data.time__data[0] === "" || data.time__data[0] === null) &&
			(data.time__data[1] === "" || data.time__data[1] === null)
		) {
			filterValue = { ...filterValue };
		} else {
			if (data.time__data.length > 0) {
				if (data?.search_time === "$conversation.updated_at_unix_timestamp$") {
					filterValue = {
						...filterValue,
						[data?.search_time]: {
							$between: [
								moment(data.time__data[0]).valueOf(),
								moment(data.time__data[1]).valueOf(),
							],
						},
						"$conversation.state$": "END_CHAT",
					};
				} else {
					filterValue = {
						...filterValue,
						[data?.search_time]: {
							$between: [
								moment(data.time__data[0]).valueOf(),
								moment(data.time__data[1]).valueOf(),
							],
						},
					};
				}
				// if (data.search_time === "ALL") {
				//     filterValue = {
				//         ...filterValue,
				//         created_at_unix_timestamp: {
				//             $between: [
				//                 moment(data.time__data[0]).valueOf(),
				//                 moment(data.time__data[1]).valueOf(),
				//             ],
				//         },
				//         updated_at_unix_timestamp: {
				//             $between: [
				//                 moment(data.time__data[0]).valueOf(),
				//                 moment(data.time__data[1]).valueOf(),
				//             ],
				//         },
				//     };
				// } else
				//     filterValue = {
				//         ...filterValue,
				//         [data.search_time]: {
				//             $between: [
				//                 moment(data.time__data[0]).valueOf(),
				//                 moment(data.time__data[1]).valueOf(),
				//             ],
				//         },
				//     };
			}
		}

		console.log("cau ChatLog", filterValue);

		return filterValue;
	},
	inAppPayment: (data) => {
		console.log("🎃🎃🎃 ~ file: Utils.js:2438 ~ filterValue:", data);
		let filterValue;
		if (data.user_type) {
			if (data.user_type === "ALL") filterValue = { ...filterValue };
			else {
				if (data.user_type !== "CELEBRITY") {
					filterValue = {
						...filterValue,
						"$user.register_status$": { $ne: "ACCEPT" },
					};
				} else {
					filterValue = {
						...filterValue,
						"$user.user_type$": data.user_type,
						"$user.register_status$": "ACCEPT",
					};
				}
			}
		}
		if (data.status_request) {
			if (data.status_request === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					state: data.status_request,
				};
			}
		}
		if (data.platform) {
			if (data.platform === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.platform,
				};
			}
		}
		//memeberInfo
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};

		if (data.transaction_number) {
			filterValue = {
				...filterValue,
				transaction_number: { $iLike: `%25${data?.transaction_number}%25` },
			};
		}
		if (data.agency_code) {
			filterValue = {
				...filterValue,
				"$user.agency.username$": { $iLike: `%25${data?.agency_code}%25` },
			};
		}
		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		console.log(filterValue);

		return filterValue;
	},

	agency: (data) => {
		console.log("agency", data);
		let filterValue;
		// time
		if (data.time__data[0]) {
			if (data.search_time === "ALL") {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
					updated_at_unix_timestamp: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			} else
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
		}
		//phone
		if (data.phone) {
			filterValue = {
				...filterValue,
				phone_number: { $iLike: `%25${data?.phone}%25` },
			};
		}
		//email
		if (data.email) {
			filterValue = {
				...filterValue,
				email: { $iLike: `%25${data?.email}%25` },
			};
		}
		//pin
		if (data.pin) {
			filterValue = {
				...filterValue,
				// account_number: { $iLike: `%25${data?.pin}%25` },
				resident_registration_number: { $iLike: `%25${data?.pin}%25` },
			};
		}
		//memeberInfo
		if (data.memberInfoSelect)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		return filterValue;
	},
	dailySale: (data) => {
		let filterValue;
		if (data.type) {
			switch (data.type) {
				case "TODAY":
					filterValue = {
						...filterValue,
						time__data: [moment().startOf("day").valueOf(), moment().endOf("day").valueOf()],
					};
					break;
				case "YESTERDAY":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(1, "day").startOf("day").valueOf(),
							moment().subtract(1, "day").endOf("day").valueOf(),
						],
					};
					break;
				case "ONEWEEK":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(6, "day").startOf("day").valueOf(),
							moment().endOf("day").valueOf(),
						],
					};
					break;
				case "FULLMOON":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(14, "day").startOf("day").valueOf(),
							moment().endOf("day").valueOf(),
						],
					};
					break;
				case "ONEMONTH":
					filterValue = {
						...filterValue,
						time__data: [moment().startOf("month").valueOf(), moment().valueOf()],
					};
					break;
				case "THREEMONTH":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(3, "months").startOf("day").valueOf(),
							moment().endOf("day").valueOf(),
						],
					};
					break;

				default:
					filterValue = data;
					break;
			}
		}

		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,

				time__data: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
			};
		}
		return filterValue;
	},
	starLog: (data) => {
		let filterValue;
		console.log("starLog", data);

		//memeberInfo
		if (data.memberInfoInput) {
			switch (data.memberInfoSelect) {
				case "NORMAL_ID":
					filterValue = {
						...filterValue,
						"$sender.identified_id$": { $iLike: `%${data?.memberInfoInput}%` },
						"$sender.user_type$": "NORMAL",
					};
					break;
				case "CELEB_ID":
					filterValue = {
						...filterValue,
						"$sender.identified_id$": { $iLike: `%${data?.memberInfoInput}%` },
						"$sender.user_type$": "CELEBRITY",
						"$sender.register_status$": "ACCEPT",
					};
					break;
				case "CELEB_NICKNAME":
					filterValue = {
						...filterValue,
						"$sender.nickname$": { $iLike: `%${data?.memberInfoInput}%` },
						"$sender.user_type$": "CELEBRITY",
						"$sender.register_status$": "ACCEPT",
					};
					break;
				case "NORMAL_NICKNAME":
					filterValue = {
						...filterValue,
						"$sender.nickname$": { $iLike: `%${data?.memberInfoInput}%` },
						"$sender.user_type$": "NORMAL",
					};
					break;

				default:
					break;
			}
		}

		//phone
		if (data.phone_number) {
			filterValue = {
				...filterValue,
				"$sender.phone$": { $iLike: `%${data?.phone_number}%` },
			};
		}

		// gender
		if (data.sex) {
			console.log("file: Utils.js:2388 ~ data:", data);
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				if (data.sex === "SUB_MALE" || data.sex === "SUB_FEMALE") {
					filterValue = {
						...filterValue,
						"$sender.sex$": data?.sex.split("_")[1],
						"$sender.user_type$": "NORMAL",
					};
				} else if (data.sex === "FEMALE" || data.sex === "MALE") {
					filterValue = {
						...filterValue,
						"$sender.sex$": data?.sex,
						"$sender.user_type$": "CELEBRITY",
					};
				}
			}
		}

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		// reason
		if (data.reason) {
			if (data.reason === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.reason,
				};
			}
		}
		console.log("filterValue starlog", filterValue);
		return filterValue;
	},

	dailySaleMonth: (data) => {
		let filterValue = data;
		return filterValue;
	},

	VideoCallLog: (data) => {
		let filterValue = {};

		console.log("🎃🎃🎃 ~ file: Utils.js:2694 ~ data:", data);
		if (data.type) {
			if (data.type === "All") {
				filterValue = {
					...filterValue,
				};
			} else if (data.type === "CELEB_RECEIVER") {
				filterValue = {
					...filterValue,
					"$receiver.register_status$": "ACCEPT",
					"$receiver.user_type$": "CELEBRITY",
					type: "VIDEO_CALL",
				};
			} else if (data.type === "CELEB_CALL") {
				filterValue = {
					...filterValue,
					$or: [{ "$receiver.register_status$": "PENDING" }, { "$receiver.user_type$": "NORMAL" }],
					type: "VIDEO_CALL",
				};
			} else if (data.type === "PLUS_KIT") {
				filterValue = {
					...filterValue,
					type: "PLUS_KIT",
				};
			}
		}
		//memeberInfo
		if (data?.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				[data.search_time]: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		// call state
		if (data?.callState && data?.callState !== "ALL") {
			filterValue = {
				...filterValue,
				state: data?.callState,
			};
		}

		console.log("🎃🎃🎃 ~ file: Utils.js:2730 ~ filterValue:", filterValue);
		return filterValue;
	},
	GiftLog: (data) => {
		let filterValue = {};
		//memeberInfo
		console.log("🎃🎃🎃 ~ file: Utils.js:2734 ~ data:", data);
		if (data.memberInfoInput) {
			switch (data.memberInfoSelect) {
				case "NORMAL_ID":
					filterValue = {
						...filterValue,
						"$sender.identified_id$": { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;
				case "CELEB_ID":
					filterValue = {
						...filterValue,
						"$receiver.identified_id$": { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;
				case "CELEB_NICKNAME":
					filterValue = {
						...filterValue,
						"$receiver.nickname$": { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;
				case "NORMAL_NICKNAME":
					filterValue = {
						...filterValue,
						"$sender.nickname$": { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;

				default:
					break;
			}
		}

		// phone

		if (data.phone) {
			filterValue = {
				...filterValue,
				"$sender.phone": { $iLike: `%25${data?.phone}%25` },
			};
		}

		// gender
		if (data.sex) {
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				if (data.sex === "SUB_MALE" || data.sex === "SUB_FEMALE") {
					filterValue = {
						...filterValue,
						"$sender.sex$": data?.sex.split("_")[1],
						"$sender.user_type$": "NORMAL",
					};
				} else {
					filterValue = {
						...filterValue,
						"$sender.sex$": data?.sex,
						"$sender.user_type$": "CELEBRITY",
						"$sender.register_status$": "ACCEPT",
					};
				}
			}
		}

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		// reason
		if (data.reason) {
			if (data.reason === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.reason,
				};
			}
		}

		return filterValue;
	},
	DailySaleASP: (data) => {
		let filterValue = {};
		console.log("DailySaleASP", data);
		if (data.type) {
			switch (data.type) {
				case "TODAY":
					filterValue = {
						...filterValue,
						time__data: [moment().startOf("day").valueOf(), moment().endOf("day").valueOf()],
					};
					break;
				case "YESTERDAY":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(1, "day").startOf("day").valueOf(),
							moment().subtract(1, "day").endOf("day").valueOf(),
						],
					};
					break;
				case "ONEWEEK":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(6, "day").startOf("day").valueOf(),
							moment().endOf("day").valueOf(),
						],
					};
					break;
				case "FULLMONTH":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(1, "month").startOf("day").valueOf(),
							moment().endOf("day").valueOf(),
						],
					};
					break;
				case "ONEMONTH":
					filterValue = {
						...filterValue,
						time__data: [moment().subtract(14, "day").startOf("day").valueOf(), moment().valueOf()],
					};
					break;
				case "THREEMONTH":
					filterValue = {
						...filterValue,
						time__data: [
							moment().subtract(3, "months").startOf("day").valueOf(),
							moment().endOf("day").valueOf(),
						],
					};
					break;

				default:
					break;
			}
		}
		if (data.typePlatform) {
			filterValue = { ...filterValue, typePlatform: data?.typePlatform };
		}
		if (data?.time__data?.length > 0) {
			filterValue = {
				...filterValue,

				time__data: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
			};
		}
		console.log("DailySaleASP filterValue", filterValue);
		return filterValue;
	},
	MonthlySaleMonthASP: (data) => {
		console.log("MonthlySaleMonthASP", data);
		let filterValue;
		if (data?.type) {
			filterValue = { ...filterValue, year: data?.type };
		}
		if (data?.typePlatform !== "ALL") {
			filterValue = { ...filterValue, typePlatform: data?.typePlatform };
		}
		console.log("🚀 ~ file: Utils.js:2722 ~ filterValue:", filterValue);
		return filterValue;
	},
	inquiryASP: (data) => {
		console.log("inquiryASP", data);
		let filterValue = {};
		// title
		if (data.title) {
			filterValue = {
				...filterValue,
				title: { $iLike: `%25${data.title}%25` },
			};
		}
		// content
		if (data.content) {
			filterValue = {
				...filterValue,
				content: { $iLike: `%25${data.content}%25` },
			};
		}
		// user info
		if (data.memberInfoInput)
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				[data.search_time]: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}
		// state
		if (data.state) {
			if (data.state === "ALL")
				filterValue = { ...filterValue, state: { $in: ["DONE", "REQUEST"] } };
			else {
				filterValue = {
					...filterValue,
					state: data.state,
				};
			}
		}
		// type question
		if (data.type_question) {
			if (data.type_question === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.type_question,
				};
			}
		}
		// user type
		if (data?.platForm !== "ALL") {
			filterValue = { ...filterValue, "$user.asp_user.asp_member_id$": data?.platForm };
		}

		return filterValue;
	},
	starLogASP: (data) => {
		let filterValue;
		console.log("starLogASP", data);

		//memeberInfo
		if (data.memberInfoInput) {
			switch (data.memberInfoSelect) {
				case "NORMAL_ID":
					filterValue = {
						...filterValue,
						"$sender.identified_id$": { $iLike: `%${data?.memberInfoInput}%` },
						"$sender.user_type$": "NORMAL",
					};
					break;
				case "NORMAL_NICKNAME":
					filterValue = {
						...filterValue,
						"$sender.nickname$": { $iLike: `%${data?.memberInfoInput}%` },
						"$sender.user_type$": "NORMAL",
					};
					break;
				default:
					break;
			}
		}

		//platform

		if (data?.platForm !== "ALL") {
			filterValue = { ...filterValue, "$sender.asp_user.asp_member_id$": data?.platForm };
		}

		// gender
		if (data.sex) {
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				if (data.sex === "MALE" || data.sex === "FEMALE") {
					filterValue = {
						...filterValue,
						"$sender.sex$": data?.sex,
						"$sender.user_type$": "NORMAL",
					};
				}
			}
		}

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		// reason
		if (data.reason) {
			if (data.reason === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.reason,
				};
			}
		}
		console.log("filterValue starlog", filterValue);
		return filterValue;
	},

	coinLogASP: (data) => {
		let filterValue;
		console.log("coinLogASP", data);

		//memeberInfo
		if (data.memberInfoInput) {
			switch (data.memberInfoSelect) {
				case "CELEB_ID":
					filterValue = {
						...filterValue,
						"$receiver.identified_id$": { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;
				case "CELEB_NICKNAME":
					filterValue = {
						...filterValue,
						"$receiver.nickname$": { $iLike: `%${data?.memberInfoInput}%` },
					};
					break;

				default:
					break;
			}
		}

		//platform

		if (data?.platForm !== "ALL") {
			filterValue = { ...filterValue, "$sender.asp_user.asp_member_id$": data?.platForm };
		}

		// gender
		if (data.sex) {
			if (data.sex === "ALL") {
				filterValue = { ...filterValue };
			} else {
				if (data.sex === "MALE" || data.sex === "FEMALE") {
					filterValue = {
						...filterValue,
						"$receiver.sex$": data?.sex,
					};
				}
			}
		}

		// time
		if (data.time__data.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				},
			};
		}

		// reason
		if (data.reason) {
			if (data.reason === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					type: data.reason,
				};
			}
		}
		console.log("filterValue coinLogASP", filterValue);
		return filterValue;
	},
	videoChatLogASP: (data) => {
		let filterValue;
		//memeberInfo
		if (data.memberInfoInput) {
			switch (data.memberInfoSelect) {
				case "APPLICATION_ID": {
					filterValue = {
						...filterValue,
						"$caller.identified_id$": { $iLike: `%25${data?.memberInfoInput}%25` },
					};
					break;
				}
				case "NORMAL_NICKNAME": {
					filterValue = {
						...filterValue,
						"$caller.nickname$": { $iLike: `%25${data?.memberInfoInput}%25` },
					};
					break;
				}
				case "ACCEPTANCE_ID": {
					filterValue = {
						...filterValue,
						"$receiver.identified_id$": { $iLike: `%25${data?.memberInfoInput}%25` },
					};
					break;
				}
				case "ACCEPTANCE_NICKNAME": {
					filterValue = {
						...filterValue,
						"$receiver.nickname$": { $iLike: `%25${data?.memberInfoInput}%25` },
					};
					break;
				}
				default:
					break;
			}
		}

		// Platform
		const platformValueFilter =
			data.platForm && data.platForm.toUpperCase() !== "ALL" ? data.platForm : { $ne: null };

		// Type
		if (!data.type || data.type.toUpperCase() === "ALL") {
			filterValue = {
				...filterValue,
				$or: [
					{ "$caller.asp_user.asp_member_id$": platformValueFilter },
					{ "$receiver.asp_user.asp_member_id$": platformValueFilter },
				],
			};
		} else if (data.type === "CELEB_RECEIVER") {
			filterValue = {
				...filterValue,
				type: "VIDEO_CALL",
				"$receiver.register_status$": "ACCEPT",
				"$receiver.user_type$": "CELEBRITY",
				"$caller.asp_user.asp_member_id$": platformValueFilter,
			};
		} else if (data.type === "CELEB_CALL") {
			filterValue = {
				...filterValue,
				type: "VIDEO_CALL",
				"$caller.register_status$": "ACCEPT",
				"$caller.user_type$": "CELEBRITY",
				"$receiver.asp_user.asp_member_id$": platformValueFilter,
			};
		} else if (data.type === "PLUS_KIT") {
			filterValue = {
				...filterValue,
				type: "PLUS_KIT",
			};
		}
		if (data.time__data) {
			if (data.time__data.length > 0) {
				filterValue = {
					...filterValue,
					[data.search_time]: {
						$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
					},
				};
			}
		}

		// call state
		if (data?.callState && data?.callState !== "ALL") {
			filterValue = {
				...filterValue,
				state: data?.callState,
			};
		}

		return filterValue;
	},
	ChatLogASP: (data) => {
		let filterValue;
		let andConditions = [];

		if (data.memberInfoInput) {
			let orConditions = [];
			switch (data.memberInfoSelect) {
				case "NORMAL_ID":
					orConditions = [
						{
							$and: [
								{ "$user.identified_id$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$user.user_type$": "NORMAL" },
							],
						},
						{
							$and: [
								{ "$receiver.identified_id$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$receiver.user_type$": "NORMAL" },
							],
						},
					];
					break;
				case "NORMAL_NICKNAME":
					orConditions = [
						{
							$and: [
								{ "$user.nickname$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$user.user_type$": "NORMAL" },
							],
						},
						{
							$and: [
								{ "$receiver.nickname$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$receiver.user_type$": "NORMAL" },
							],
						},
					];
					break;
				case "CELEB_ID":
					orConditions = [
						{
							$and: [
								{ "$user.identified_id$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$user.user_type$": "CELEBRITY" },
							],
						},
						{
							$and: [
								{ "$receiver.identified_id$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$receiver.user_type$": "CELEBRITY" },
							],
						},
					];
					break;
				case "CELEB_NICKNAME":
					orConditions = [
						{
							$and: [
								{ "$user.nickname$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$user.user_type$": "CELEBRITY" },
							],
						},
						{
							$and: [
								{ "$receiver.nickname$": { $iLike: `%${data?.memberInfoInput}%` } },
								{ "$receiver.user_type$": "CELEBRITY" },
							],
						},
					];
					break;
				default:
					break;
			}
			andConditions.push({ $or: orConditions });
		}

		if (!data.setting || data.setting.toUpperCase() === "ALL") {
			andConditions.push({
				$or: [
					{ "$user.user_setting.type_message$": { $ne: null } },
					{ "$receiver.user_setting.type_message$": { $ne: null } },
				],
			});
		} else {
			andConditions.push({
				$or: [
					{ "$user.user_setting.type_message$": data.setting },
					{ "$receiver.user_setting.type_message$": data.setting },
				],
			});
		}

		if (data.time__data) {
			if (data.time__data.length > 0) {
				const timestampRange = {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				};

				if (data.search_time === "$conversation.created_at_unix_timestamp$") {
					filterValue = {
						...filterValue,
						"$conversation.created_at_unix_timestamp$": timestampRange,
					};
				} else if (data.search_time === "$conversation.last_message.created_at_unix_timestamp$") {
					filterValue = {
						...filterValue,
						"$conversation.last_message.created_at_unix_timestamp$": timestampRange,
					};
				} else if (data.search_time === "$conversation.updated_at_unix_timestamp$") {
					filterValue = {
						...filterValue,
						"$conversation.updated_at_unix_timestamp$": timestampRange,
					};
				} else {
					// Reset data.ab to null if none of the conditions match
				}
			}
		}

		// Platform
		const platformFilterValue =
			data.platForm && data.platForm.toUpperCase() !== "ALL" ? data.platForm : { $ne: null };

		// Type
		if (!data.type || data.type.toUpperCase() === "ALL") {
			andConditions.push({
				$or: [
					{ "$user.register_status$": { $ne: "PENDING" } },
					{ "$receiver.register_status$": { $ne: "PENDING" } },
				],
			});
			andConditions.push({
				$or: [
					{ "$user.asp_user.asp_member_id$": platformFilterValue },
					{ "$receiver.asp_user.asp_member_id$": platformFilterValue },
				],
			});
		} else if (data.type.toUpperCase() === "CELEB_RECEIVER") {
			filterValue = {
				...filterValue,
				"$receiver.register_status$": "ACCEPT",
				"$receiver.user_type$": "CELEBRITY",
				"$user.asp_user.asp_member_id$": platformFilterValue,
			};
		} else if (data.type.toUpperCase() === "CELEB_CALL") {
			filterValue = {
				...filterValue,
				"$user.register_status$": "ACCEPT",
				"$user.user_type$": "CELEBRITY",
				"$receiver.asp_user.asp_member_id$": platformFilterValue,
			};
		}

		// State
		if (!data.state || data.state.toUpperCase() === "ALL") {
			filterValue = {
				...filterValue,
				"$conversation.state$": { $ne: "CREATED" },
			};
		} else {
			filterValue = {
				...filterValue,
				"$conversation.state$": data.state,
			};
		}

		if (andConditions.length > 0) {
			filterValue.$and = andConditions;
		}
		return filterValue;
	},
	PluskitPurchaseLogASP: (data) => {
		let filterValue;
		let andConditions = [];

		if (data.memberInfoInput) {
			let orConditions = [];
			switch (data.memberInfoSelect) {
				case "identified_id":
					orConditions = [{ "$user.identified_id$": { $iLike: `%${data?.memberInfoInput}%` } }];
					break;
				case "nickname":
					orConditions = [{ "$user.nickname$": { $iLike: `%${data?.memberInfoInput}%` } }];
					break;
				default:
					break;
			}
			andConditions.push({ $or: orConditions });
		}

		// Time data
		if (data.time__data) {
			if (data.time__data.length > 0) {
				const timestampRange = {
					$between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
				};
				filterValue = {
					...filterValue,
					[data.searchTime]: timestampRange,
				};
			}
		}

		// Platform
		const platformFilterValue =
			data.platForm && data.platForm.toUpperCase() !== "ALL" ? data.platForm : { $ne: null };
		andConditions.push({
			"$user.asp_user.asp_member_id$": platformFilterValue,
		});

		// Status
		if (!data.status || data.status.toUpperCase() === "ALL") {
			filterValue = {
				...filterValue,
			};
		} else {
			filterValue = {
				...filterValue,
				type: data.status,
			};
		}

		if (andConditions.length > 0) {
			filterValue.$and = andConditions;
		}
		return filterValue;
	},
	listUserASP: (data) => {
		console.log("listUserASP", data);
		let filterValue = {};
		if (data?.status_account) {
			if (data?.status_account === "ALL")
				filterValue = { ...filterValue, status_account: { $in: ["NORMAL", "BLOCK", "DELETE"] } };
			else {
				filterValue = {
					...filterValue,
					status_account: data?.status_account,
				};
			}
		}
		if (data?.sex) {
			if (data?.sex === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sex: data?.sex,
				};
			}
		}
		if (data?.ID)
			filterValue = {
				...filterValue,
				identified_id: { $iLike: `%${data?.ID}%` },
			};
		if (data?.nickname)
			filterValue = {
				...filterValue,
				nickname: { $iLike: `%${data?.nickname}%` },
			};
		if (data?.sign_up_time.length > 0) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [
						moment(data?.sign_up_time[0]).valueOf(),
						isNaN(data?.sign_up_time[1])
							? moment(data?.sign_up_time[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data?.sign_up_time[1]).valueOf(),
					],
				},
			};
		}
		if (data?.sign_in_time.length > 0) {
			filterValue = {
				...filterValue,
				last_open_app_unix_timestamp: {
					$between: [
						moment(data?.sign_in_time[0]).valueOf(),
						isNaN(data?.sign_in_time[1])
							? moment(data?.sign_in_time[0])
									.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
									.valueOf()
							: moment(data?.sign_in_time[1]).valueOf(),
					],
				},
			};
		}
		if (data?.platForm && data?.platForm !== "ALL") {
			filterValue = { ...filterValue, "$asp_user.asp_member_id$": { $eq: data?.platForm } };
		}
		if (data?.ipInput) {
			if (data?.ipType === "ALL") {
				filterValue = {
					...filterValue,
					$or: [
						{ "$asp_user.register_ip$": { $iLike: `%${data?.ipInput}%` } },
						{ "$asp_user.last_login_ip$": { $iLike: `%${data?.ipInput}%` } },
					],
				};
			} else {
				filterValue = {
					...filterValue,
					[data?.ipType]: { $iLike: `%${data?.ipInput}%` },
				};
			}
		}
		console.log("listUserASP filterValue", filterValue);
		return filterValue;
	},
	profileImageASP: (data) => {
		console.log("profileImageASP", data);
		let filterValue = {};

		if (data?.state === "ALL") {
			filterValue = { ...filterValue, state: { $in: ["PENDING", "APPROVED"] } };
		} else {
			filterValue = {
				...filterValue,
				state: data.state,
			};
			console.log("bafp", filterValue);
		}

		if (data?.gender !== "ALL") {
			filterValue = {
				...filterValue,
				"$user.sex$": data.gender,
			};
		}
		if (data.nickname) {
			filterValue = {
				...filterValue,
				"$user.nickname$": { $iLike: `%25${data.nickname}%25` },
			};
		}
		if (data?.ID) {
			filterValue = {
				...filterValue,
				"$user.identified_id$": { $iLike: `%25${data?.ID}%25` },
			};
		}
		if (data?.limit) {
			console.log("🚀 ~ file: Utils.js:3059 ~ limit:", data?.limit);
			filterValue = {
				...filterValue,
				limit: data?.limit,
			};
		}
		if (data?.platForm !== "ALL") {
			filterValue = { ...filterValue, "$user.asp_user.asp_member_id$": data?.platForm };
		}

		console.log("profileImageASP filterValue:", filterValue);
		return filterValue;
	},
	reportUserASP: (data) => {
		console.log("reportUserASP", data);
		let filterValue = {};
		if (data.id_reporter) {
			filterValue = {
				...filterValue,
				"$user.identified_id$": { $iLike: `%25${data.id_reporter}%25` },
			};
		}
		if (data.id_reported) {
			filterValue = {
				...filterValue,
				"$reported.identified_id$": { $iLike: `%25${data.id_reported}%25` },
			};
		}
		if (data.reporter_nickname) {
			filterValue = {
				...filterValue,
				"$user.nickname$": { $iLike: `%25${data.reporter_nickname}%25` },
			};
		}
		if (data.reported_nickname) {
			filterValue = {
				...filterValue,
				"$reported.nickname$": { $iLike: `%25${data.reported_nickname}%25` },
			};
		}
		if (data.status_report) {
			if (data.status_report === "ALL")
				filterValue = { ...filterValue, state: { $in: ["PENDING", "DONE"] } };
			else {
				filterValue = {
					...filterValue,
					state: data.status_report,
				};
			}
		}
		if (data?.type_report && data?.type_report !== "ALL") {
			filterValue = { ...filterValue, type: data.type_report };
		}
		if (data?.platForm && data?.platForm !== "ALL") {
			filterValue = { ...filterValue, "$user.asp_user.asp_member_id$": data?.platForm };
		}
		if (data?.time_report.length > 1) {
			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [
						moment(data?.time_report[0]).valueOf(),
						moment(data?.time_report[1]).valueOf(),
					],
				},
			};
		}
		if (data?.time_process.length > 1) {
			filterValue = {
				...filterValue,
				updated_at_unix_timestamp: {
					$between: [
						moment(data?.time_process[0]).valueOf(),
						moment(data?.time_process[1]).valueOf(),
					],
				},
			};
		}
		console.log("reportUserASP filterValue:", filterValue);
		return filterValue;
	},
	intermediaryFee: (data) => {
		let filterValue = {};

		if (data.celebInfoInput) {
			switch (data.celebInfoSelect) {
				case "identified_id":
					filterValue = {
						...filterValue,
						identified_id: { $iLike: `%${data?.celebInfoInput}%` },
					};
					break;
				case "nickname":
					filterValue = {
						...filterValue,
						nickname: { $iLike: `%${data?.celebInfoInput}%` },
					};
					break;

				default:
					break;
			}
		}

		if (data.sex) {
			if (data.sex === "ALL") filterValue = { ...filterValue };
			else {
				filterValue = {
					...filterValue,
					sex: data.sex,
				};
			}
		}

		if (data.codeAgency) {
			filterValue = {
				...filterValue,
				"$agency.username$": { $iLike: `%${data?.codeAgency.trim()}%` },
			};
		}

		return filterValue;
	},
	TimezoneAccessStatistics: (data) => {
		let filterValue = {};
		if (data.type) {
			filterValue = {
				...filterValue,
				time__data: getTimeRangeDataByType(data.type),
			};
		}
		if (data.typePlatform) {
			filterValue = { ...filterValue, typePlatform: data?.typePlatform };
		}
		if (data?.time__data?.length > 0) {
			filterValue = {
				...filterValue,

				time__data: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
			};
		}
		return filterValue;
	},
	DailyAccessStatistics: (data) => {
		let filterValue = {};
		if (data.type) {
			filterValue = {
				...filterValue,
				time__data: getTimeRangeDataByType(data.type),
			};
		}
		if (data.typePlatform) {
			filterValue = { ...filterValue, typePlatform: data?.typePlatform };
		}
		if (data?.time__data?.length > 0) {
			filterValue = {
				...filterValue,

				time__data: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
			};
		}
		return filterValue;
	},
	VideoCallErrorStatistics: (data) => {
		let filterValue = {};

		// User Info
		if (data?.userInfoInput) {
			filterValue = {
				...filterValue,
				[data.userInfoSelect]: { $iLike: `%25${data?.userInfoInput}%25` },
			};
		}

		// Connect Status
		if (data?.connectStatus && data?.connectStatus !== "ALL") {
			filterValue = {
				...filterValue,
				call_method: data.connectStatus,
			};
		}
		// Time Range
		if (data?.time__data.length === 2) {
			let startTime = moment(data.time__data[0]).valueOf();
			let endTime = moment(data.time__data[1]).valueOf();

			filterValue = {
				...filterValue,
				created_at_unix_timestamp: {
					$between: [startTime, endTime],
				},
			};
		}
		// Agency Code
		if (data?.agencyCode) {
			filterValue = {
				...filterValue,
				$or: [
					{
						"$caller.agency.username$": { $iLike: `%25${data?.agencyCode}%25` },
					},
					{
						"$receiver.agency.username$": { $iLike: `%25${data?.agencyCode}%25` },
					},
				],
			};
		}
		// Fail Reason
		if (data?.failReason && data?.failReason !== "ALL") {
			filterValue = {
				...filterValue,
				"$error_log.code$": Number(data.failReason),
			};
		}
		// Call Result
		if (data?.callResult && data?.callResult !== "ALL") {
			if (data.callResult === CALL_STATUS_TYPE.SUCCESS) {
				filterValue = {
					...filterValue,
					state: CALL_STATUS_DONE,
				};
			} else {
				filterValue = {
					...filterValue,
					state: {
						$ne: CALL_STATUS_DONE,
					},
					"$error_log.id$": {
						$ne: null,
					},
				};
			}
		}

		// Call Type
		if (data?.callType && data?.callType !== "ALL") {
			filterValue = {
				...filterValue,
				type: data.callType,
			};
		}

		return filterValue;
	},
	CelebConnectionStatus: (data) => {
		let filterValue = {};
		if (data?.memberInfoInput) {
			filterValue = {
				...filterValue,
				[data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
			};
		}
		if (data?.connectionStatus && data?.connectionStatus !== "ALL") {
			filterValue = {
				...filterValue,
				status: data.connectionStatus,
			};
		}
		if (data?.time__data.length === 2) {
			let startTime = moment(data.time__data[0]).valueOf();
			let endTime = moment(data.time__data[1]).valueOf();
			if (data?.connectionStatus === STATUS_ACTIVITY.CALLING) {
				filterValue = {
					...filterValue,
					status_update_time: {
						$between: [startTime, endTime],
					},
				};
			} else {
				filterValue = {
					...filterValue,
					created_at_unix_timestamp: {
						$between: [startTime, endTime],
					},
				};
			}
		}
		if (data?.agency) {
			filterValue = {
				...filterValue,
				"$user.agency.username$": { $iLike: `%25${data?.agency}%25` },
			};
		}
		return filterValue;
	},
	OnOffPlusKitManagement: (data) => {
		let filterValue = {};

		// User Info
		if (data?.userInfoInput) {
			filterValue = {
				...filterValue,
				[data.userInfoSelect]: { $iLike: `%25${data?.userInfoInput}%25` },
			};
		}

		// Status
		if (data?.plusKitStatus && data?.plusKitStatus !== "ALL") {
			filterValue = {
				...filterValue,
				status: data.plusKitStatus,
			};
		}

		// Time Range
		if (data?.time__data.length === 2) {
			let startTime = moment(data.time__data[0]).valueOf();
			let endTime = moment(data.time__data[1]).valueOf();

			filterValue = {
				...filterValue,
				updated_at_unix_timestamp: {
					$between: [startTime, endTime],
				},
			};
		}

		return filterValue;
	},

	GiftRegistrationManagement: (data) => {
		let filterValue = {};

		// username
		if (data?.username) {
			filterValue = {
				...filterValue,
				"$employee.username$": { $iLike: `%25${data?.username}%25` },
			};
		}

		// Status Gift
		if (data?.statusGift && data?.statusGift !== "ALL") {
			filterValue = {
				...filterValue,
				status: data.statusGift === GIFT_REGISTRATION_STATUS.REGISTER ? true : false,
			};
		}

		// Time Range
		if (data?.time__data.length === 2) {
			let startTime = moment(data.time__data[0]).valueOf();
			let endTime = moment(data.time__data[1]).valueOf();

			filterValue = {
				...filterValue,
				[data?.dateTypeSelect]: {
					$between: [startTime, endTime],
				},
			};

			if (data?.dateTypeSelect === "updated_at_unix_timestamp") {
				filterValue = {
					...filterValue,
					status: false,
				};
			}
		}

		return filterValue;
	},
};

export const getTimeRangeDataByType = (type) => {
	let value = [];
	switch (type) {
		case TimePeriodEnum.TODAY:
			value = [moment().startOf("day").valueOf(), moment().endOf("day").valueOf()];
			break;
		case TimePeriodEnum.YESTERDAY:
			value = [
				moment().subtract(1, "day").startOf("day").valueOf(),
				moment().subtract(1, "day").endOf("day").valueOf(),
			];
			break;
		case TimePeriodEnum.ONE_WEEK:
			value = [
				moment().subtract(6, "day").startOf("day").valueOf(),
				moment().endOf("day").valueOf(),
			];
			break;
		case TimePeriodEnum.FULL_MONTH:
			value = [
				moment().subtract(1, "month").startOf("day").valueOf(),
				moment().endOf("day").valueOf(),
			];
			break;
		case TimePeriodEnum.HALF_MONTH:
			value = [moment().subtract(14, "day").startOf("day").valueOf(), moment().valueOf()];
			break;
		case TimePeriodEnum.THREE_MONTH:
			value = [
				moment().subtract(3, "months").startOf("day").valueOf(),
				moment().endOf("day").valueOf(),
			];
			break;
		default:
			break;
	}
	return value;
};

export const convertPhoneNumber = (phone) => {
	if (!phone) {
		return "";
	}
	// Set default country code to US if no real country code is specified
	const defaultCountryCode = phone.substr(0, 1) !== "+" ? "US" : null;
	let formatted = new AsYouType(defaultCountryCode).input(phone);
	let countryCode = "";
	let withoutCountryCode = formatted;

	if (defaultCountryCode === "US") {
		countryCode = "+1";
		formatted = "+1 " + formatted;
	} else {
		const parts = formatted.split(" ");
		countryCode = parts.length > 1 ? parts.shift() : "";
		withoutCountryCode = parts.join("");
	}

	return `(${countryCode}) ${withoutCountryCode}`;
};

export const convertPhoneNumber2 = (phone, prefix) => {
	if (!phone) {
		return "-";
	}

	let newPrefix;
	if (prefix) {
		newPrefix = `+${prefix}`;
	} else {
		newPrefix = "?";
	}

	return `(${newPrefix}) ${phone}`;
};

export const convertPhoneNumber3 = (phone) => {
	if (!phone) {
		return "-";
	}
	if (phone.length === 10 && !isNaN(phone)) {
		// Sử dụng slice() để tách chuỗi thành ba phần và thêm dấu gạch ngang (-)
		return "0" + phone.slice(0, 2) + "-" + phone.slice(2, 6) + "-" + phone.slice(6);
	} else {
		// Trả về thông báo lỗi nếu đầu vào không hợp lệ
		return "-";
	}
};
export const USersInConverSation = (listUSer) => {
	return listUSer.filter((user) => user.type === "MEMBER");
};
export const getAgeUser = (age) => {
	// console.log("age data", age);
	if (age === null) {
		return "미입력";
	} else {
		const today = moment();
		const birth = moment(Number(age), "x");
		const data = today.diff(birth, "years");
		// console.log("data", data);
		return Number(data);
	}
};
export const getAgeNice = (age) => {
	if (!age) {
		return null;
	} else {
		const birthdateMoment = moment(Number(age), "YYYYMMDD");
		const currentDate = moment();
		return currentDate.diff(birthdateMoment, "years");
	}
};

export const getAgeUserNew = (age) => {
	console.log(age);
	if (age === null) {
		return "미입력";
	} else {
		const birthday = getAge(age);
		console.log("file: Utils.js:2566 ~ birthday:", birthday);
		return birthday;
	}
};

export const converAgeToMilisecond = (age) => {
	console.log("converAgeToMilisecond", age);
	if (age === -1) return null;
	else {
		const currentYear = moment().subtract(Number(age), "years").valueOf();
		console.log("getAgeUser", getAgeUser(currentYear));
		return currentYear;
	}
};
export const getAge = (birthday) => {
	const today = moment();
	const birth = moment(birthday, "x");
	const data = today.diff(birth, "years");
	return data ? data : "-";
};

export const getTypeUrl = (url) => {
	// return url ? url.slice(-3) : "";
	if (typeof url === "string") {
		const parts = url.split("."); // Tách chuỗi thành mảng các phần tử dựa trên dấu chấm
		// console.log("urrrrrl", parts[parts.length - 1]);
		return parts.length > 1 ? parts[parts.length - 1] : "";
	} // Trả về phần tử cuối cùng hoặc toàn bộ chuỗi nếu không có dấu chấm
	return url;
};
export const checkStarType = (type) => {
	switch (type) {
		case "GIFT":
			return <p>선물</p>;
		case "SEND_GIFT":
			return <p>스타선물 </p>;
		case "GIFT_FROM_VIDEO":
			return <p>영상통화중 선물</p>;
		case "GIFT_FROM_CHAT":
			return <p>문자채팅중 선물</p>;
		case "BUY_SECRET_ALBUM":
			return <p> 앨범구매 </p>;
		// case "BUY_SECRET_MESSAGE":
		//     return <p>비밀 메시지 구매</p>;
		case "BUY_POST":
			return <p>피드구매</p>;
		case "VIDEO_CALL":
			return <p> 영상통화 </p>;
		case "SUBSCRIPTION":
			return <p>구독 </p>;
		case "TEXT":
			return <p> 문자채팅 </p>;
		case "ADMIN_ADD_STAR":
			return <p> 관리자 지급 </p>;
		case "ADMIN_SUB_STAR":
			return <p> 관리자 차감</p>;
		case "ASP_SUBSCRIBE":
			return <p> 플러스킷 상품 구매 </p>;
		default:
			return <p>{type}</p>;
	}
};
export const checkStarTypeText = (type) => {
	switch (type) {
		case "GIFT":
			return "스타선물";
		case "SEND_GIFT":
			return "스타선물";
		case "GIFT_FROM_VIDEO":
			return "선물을 보내다";
		case "BUY_SECRET_ALBUM":
			return "앨범구매";
		case "BUY_SECRET_MESSAGE":
			return "앨범 구매";
		case "BUY_POST":
			return "피드구매";
		case "VIDEO_CALL":
			return "영상채팅";
		case "SUBSCRIPTION":
			return "구독";
		case "TEXT":
			return "유료채팅";
		case "STICKER":
			return "이모티콘";
		case "IN_APP_PURCHASE":
			return "스타구매";
		case "PLUS_KIT":
			return "플러스킷";
		default:
			return "관리자 지급";
	}
};

export const translateCategoryFaq = {
	SERVICE: "서비스 소개",
	USAGE: "이용방법",
	PAYMENT: "결제 및 환불",
	REFUND: "결제 및 환불",
	ETC: "기타",
};

export const checkReportType = (type) => {
	switch (type) {
		case reportType.OBSCENE_POSTS_OR_REMARKS:
			return "음란성 포스트 또는 발언";
		case reportType.IMPERSONATING_OTHERS_AND_STEALING:
			return "타인 사칭 및 도용";
		case reportType.SLANDER_AND_INAPPROPRIATE_REMARKS:
			return "비방 및 부적절한 발언";
		default:
			return "-";
	}
};

export const checkStateReport = (state) => {
	switch (state) {
		case type.DONE:
			return "처리완료";
		case type.PENDING:
			return "미처리";
		default:
			break;
	}
};

export function openImageInNewWindow(imageUrl) {
	window.open(imageUrl, "_blank", "width=500,height=500");
}

export const IsCeleb = (user) => {
	if (user?.user_type !== "CELEBRITY" && user?.user_type !== "NORMAL") {
		return user?.user_type;
	} else {
		if (user?.user_type === "CELEBRITY" && user?.register_status === "ACCEPT") {
			return true;
		} else {
			return false;
		}
	}
};

export const handlePhoneNumber = (phone_prefix, phone) => {
	if (phone_prefix === null && phone === null) {
		return "-";
	} else {
		if (phone === null) return "010";
		else {
			if (phone_prefix === null) {
				const numberPhone1 = "+82 " + String(phone);
				return numberPhone1;
			} else {
				const numberPhone2 = "+" + String(phone_prefix) + " " + String(phone);
				return numberPhone2;
			}
		}
	}
};

export const numberWithCommas = (number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const niceCompany = (value) => {
	const data = String(value);
	switch (data) {
		case "1":
			return "SKT";
		case "2":
			return "KT";
		case "3":
			return "LG";
		default:
			return value;
	}
};

export const niceSex = (value) => {
	const data = String(value);
	switch (data) {
		case "0":
			return "여자";
		case "1":
			return "남자";
		default:
			return "미인증";
	}
};

// export const niceMethodverify = (value) => {
//     const data = String(value);
//     switch (data) {
//         case 0:
//             return "미인증";
//         case 'M':
//             return "핸드폰";
//         default:
//             return value;
//     }
// };
export const valueNiceAuth = (value) => {
	const data = String(value);
	switch (data) {
		case "M":
			return "핸드폰";
		case "0":
			return "미인증";
		default:
			return value;
	}
};

export const handleCaculatorCoin = (data) => {
	let totalSum = 0;
	data.forEach((item) => {
		const intermediaryFee = item.user.intermediary_fee === null ? 0 : item.user.intermediary_fee;
		totalSum += (item.total_revenue * intermediaryFee) / 100;
	});
	console.log("console.log(totalSum);", totalSum);
	return Math.ceil(totalSum);
};
export const fakeData = {
	count: 19,
	rows: [
		{
			id: "d72b3060-6341-11ee-9cec-e377c3230fd3",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/laughing_cat.json",
			type: "GIFT",
			user_hidden: [],
			price: 5,
			status: true,
			created_at_unix_timestamp: "1696484492904",
			updated_at_unix_timestamp: "1696484492904",
			created_at: "2023-10-05T05:41:32.875Z",
			updated_at: "2023-10-05T05:41:32.875Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "f1996862-5c3c-11ee-b8f3-b9a1eedd1e0d",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
			secret_album_id: "d8e9acd0-5c3c-11ee-b8f3-b9a1eedd1e0d",
			default_message_id: null,
			admin_message_id: null,
			content: "",
			type: "MEDIA",
			user_hidden: [],
			price: 100,
			status: true,
			created_at_unix_timestamp: "1695712731628",
			updated_at_unix_timestamp: "1695712731628",
			created_at: "2023-09-26T07:18:51.638Z",
			updated_at: "2023-09-26T07:18:51.638Z",
			deleted_at: null,
			are_you: true,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_3_3-1.png",
				avatar: [
					"https://fansomftp.flexcloud.co.kr/0085280abfa7800b614fe3876aba8fd9_248177/0085280abfa7800b614fe3876aba8fd9_248177.jpeg",
					"https://fansomftp.flexcloud.co.kr/d0d6bc49d131217859e7fe9ed16ed398_152593/d0d6bc49d131217859e7fe9ed16ed398_152593.jpeg",
				],
				id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
				nickname: "가연잉",
				fullname: null,
				email: "pppqqq123@gmail.com",
				user_type: "CELEBRITY",
				register_status: "ACCEPT",
				identified_id: "GSOC36HCTtnd",
			},
			medias: [
				{
					id: "f19c2780-5c3c-11ee-b8f3-b9a1eedd1e0d",
					url: "https://fansomftp.flexcloud.co.kr/c3fdc5a48e29d987c95219ad1782a663_4326/c3fdc5a48e29d987c95219ad1782a663_4326.png",
					message_id: "f1996862-5c3c-11ee-b8f3-b9a1eedd1e0d",
					status: true,
					media_meta_data: {
						id: "7e84fff0-5eae-11ee-bd41-e79d9871794f",
						size: 4326,
						type: "IMAGE",
						status: true,
						sources: {
							first: {
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 90,
							},
							third: {
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 30,
							},
							second: {
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 60,
							},
						},
						thumbnails: [
							{
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 80,
							},
						],
						original_url:
							"https://fansomftp.flexcloud.co.kr/c3fdc5a48e29d987c95219ad1782a663_4326/c3fdc5a48e29d987c95219ad1782a663_4326.png",
					},
					created_at_unix_timestamp: "1695712731641",
					updated_at_unix_timestamp: "1695981403503",
					created_at: "2023-09-26T07:18:51.642Z",
					updated_at: "2023-09-29T09:56:43.196Z",
				},
			],
			secret_message: null,
			secret_album: {
				id: "d8e9acd0-5c3c-11ee-b8f3-b9a1eedd1e0d",
				user_id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
				medias: [
					"https://fansomftp.flexcloud.co.kr/c3fdc5a48e29d987c95219ad1782a663_4326/c3fdc5a48e29d987c95219ad1782a663_4326.png",
				],
				content: "",
				price: 100,
				type: "PAID",
				receiver: null,
				period: null,
				period_type: "NO_LIMIT",
				status: null,
				approve_state: "APPROVED",
				state: "PUBLISH",
				medias_meta_data: [
					{
						id: "d5dbde50-5eb2-11ee-b5f0-91c215a79692",
						size: 4326,
						type: "IMAGE",
						status: true,
						sources: {
							first: {
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 90,
							},
							third: {
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 30,
							},
							second: {
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 60,
							},
						},
						thumbnails: [
							{
								url: "https://fansomftp.flexcloud.co.kr/cf0ed2fc72a358dd373f1460a56fee43_4821/cf0ed2fc72a358dd373f1460a56fee43_4821.jpeg",
								size: 4821,
								sharp_quality: 80,
							},
						],
						original_url:
							"https://fansomftp.flexcloud.co.kr/c3fdc5a48e29d987c95219ad1782a663_4326/c3fdc5a48e29d987c95219ad1782a663_4326.png",
					},
				],
				created_at_unix_timestamp: "1695712690207",
				updated_at_unix_timestamp: "1695983268095",
				created_at: "2023-09-26T07:18:10.210Z",
				updated_at: "2023-09-29T10:27:47.819Z",
				deleted_at: null,
				is_purchased: true,
			},
		},
		{
			id: "6edb4560-5778-11ee-be36-f5a246b4d7ea",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: "hi",
			type: "TEXT",
			user_hidden: [],
			price: 0,
			status: true,
			created_at_unix_timestamp: "1695188526263",
			updated_at_unix_timestamp: "1695188526264",
			created_at: "2023-09-20T05:42:06.240Z",
			updated_at: "2023-09-20T05:42:06.240Z",
			deleted_at: null,
			are_you: true,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_3_3-1.png",
				avatar: [
					"https://fansomftp.flexcloud.co.kr/0085280abfa7800b614fe3876aba8fd9_248177/0085280abfa7800b614fe3876aba8fd9_248177.jpeg",
					"https://fansomftp.flexcloud.co.kr/d0d6bc49d131217859e7fe9ed16ed398_152593/d0d6bc49d131217859e7fe9ed16ed398_152593.jpeg",
				],
				id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
				nickname: "가연잉",
				fullname: null,
				email: "pppqqq123@gmail.com",
				user_type: "CELEBRITY",
				register_status: "ACCEPT",
				identified_id: "GSOC36HCTtnd",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "a80d47b2-5602-11ee-abfe-b77f01bba0d3",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
			secret_album_id: "3f447b40-5602-11ee-abfe-b77f01bba0d3",
			default_message_id: null,
			admin_message_id: null,
			content: "",
			type: "MEDIA",
			user_hidden: [],
			price: 10,
			status: true,
			created_at_unix_timestamp: "1695027990453",
			updated_at_unix_timestamp: "1695027990453",
			created_at: "2023-09-18T09:06:30.468Z",
			updated_at: "2023-09-18T09:06:30.468Z",
			deleted_at: null,
			are_you: true,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_3_3-1.png",
				avatar: [
					"https://fansomftp.flexcloud.co.kr/0085280abfa7800b614fe3876aba8fd9_248177/0085280abfa7800b614fe3876aba8fd9_248177.jpeg",
					"https://fansomftp.flexcloud.co.kr/d0d6bc49d131217859e7fe9ed16ed398_152593/d0d6bc49d131217859e7fe9ed16ed398_152593.jpeg",
				],
				id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
				nickname: "가연잉",
				fullname: null,
				email: "pppqqq123@gmail.com",
				user_type: "CELEBRITY",
				register_status: "ACCEPT",
				identified_id: "GSOC36HCTtnd",
			},
			medias: [
				{
					id: "a817cf00-5602-11ee-abfe-b77f01bba0d3",
					url: "https://fansomftp.flexcloud.co.kr/f861ac2866d54f300a4187322151abd6_113725/f861ac2866d54f300a4187322151abd6_113725.jpeg",
					message_id: "a80d47b2-5602-11ee-abfe-b77f01bba0d3",
					status: true,
					media_meta_data: {
						id: "ebf2b070-5ebb-11ee-864d-630c643cf2fa",
						size: 113725,
						type: "IMAGE",
						status: true,
						sources: {
							first: {
								url: "https://fansomftp.flexcloud.co.kr/9c96d378464010cf98fddaea02bbf430_135923/9c96d378464010cf98fddaea02bbf430_135923.jpeg",
								size: 135923,
								sharp_quality: 90,
							},
							third: {
								url: "https://fansomftp.flexcloud.co.kr/ab19add70105cf7304884502c9d9350b_41105/ab19add70105cf7304884502c9d9350b_41105.jpeg",
								size: 41105,
								sharp_quality: 30,
							},
							second: {
								url: "https://fansomftp.flexcloud.co.kr/b5444f51b1a495a26ee86a2ff495685f_69730/b5444f51b1a495a26ee86a2ff495685f_69730.jpeg",
								size: 69730,
								sharp_quality: 60,
							},
						},
						thumbnails: [
							{
								url: "https://fansomftp.flexcloud.co.kr/0d33c71a100618b299fdd1b4a08f41cc_115422/0d33c71a100618b299fdd1b4a08f41cc_115422.jpeg",
								size: 115422,
								sharp_quality: 80,
							},
						],
						original_url:
							"https://fansomftp.flexcloud.co.kr/f861ac2866d54f300a4187322151abd6_113725/f861ac2866d54f300a4187322151abd6_113725.jpeg",
					},
					created_at_unix_timestamp: "1695027990514",
					updated_at_unix_timestamp: "1695987170552",
					created_at: "2023-09-18T09:06:30.518Z",
					updated_at: "2023-09-29T11:32:50.349Z",
				},
			],
			secret_message: null,
			secret_album: {
				id: "3f447b40-5602-11ee-abfe-b77f01bba0d3",
				user_id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
				medias: [
					"https://fansomftp.flexcloud.co.kr/f861ac2866d54f300a4187322151abd6_113725/f861ac2866d54f300a4187322151abd6_113725.jpeg",
				],
				content: "",
				price: 10,
				type: "PAID",
				receiver: null,
				period: null,
				period_type: "NO_LIMIT",
				status: null,
				approve_state: "APPROVED",
				state: "PUBLISH",
				medias_meta_data: [],
				created_at_unix_timestamp: "1695027814646",
				updated_at_unix_timestamp: "1695027816615",
				created_at: "2023-09-18T09:03:34.648Z",
				updated_at: "2023-09-18T09:06:29.721Z",
				deleted_at: null,
				is_purchased: true,
			},
		},
		{
			id: "e3235290-4e05-11ee-a92c-31e3173dbc43",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 6,
			status: true,
			created_at_unix_timestamp: "1694149768762",
			updated_at_unix_timestamp: "1694149768762",
			created_at: "2023-09-08T05:09:28.735Z",
			updated_at: "2023-09-08T05:09:28.735Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "7326f2d0-4df6-11ee-a92c-31e3173dbc43",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 2,
			status: true,
			created_at_unix_timestamp: "1694143138430",
			updated_at_unix_timestamp: "1694143138430",
			created_at: "2023-09-08T03:18:58.405Z",
			updated_at: "2023-09-08T03:18:58.405Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "47610be0-4df6-11ee-a92c-31e3173dbc43",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 15,
			status: true,
			created_at_unix_timestamp: "1694143064991",
			updated_at_unix_timestamp: "1694143064991",
			created_at: "2023-09-08T03:17:44.968Z",
			updated_at: "2023-09-08T03:17:44.968Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "30f5c210-4df6-11ee-a92c-31e3173dbc43",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 3,
			status: true,
			created_at_unix_timestamp: "1694143027378",
			updated_at_unix_timestamp: "1694143027378",
			created_at: "2023-09-08T03:17:07.346Z",
			updated_at: "2023-09-08T03:17:07.346Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "746f46c0-4df5-11ee-a92c-31e3173dbc43",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/emoji_10.svg",
			type: "STICKER",
			user_hidden: [],
			price: 0,
			status: true,
			created_at_unix_timestamp: "1694142711085",
			updated_at_unix_timestamp: "1694142711085",
			created_at: "2023-09-08T03:11:51.058Z",
			updated_at: "2023-09-08T03:11:51.058Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "6fa7d3a0-4df5-11ee-a92c-31e3173dbc43",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 100,
			status: true,
			created_at_unix_timestamp: "1694142703067",
			updated_at_unix_timestamp: "1694142703067",
			created_at: "2023-09-08T03:11:43.042Z",
			updated_at: "2023-09-08T03:11:43.042Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "69d4bce0-4df5-11ee-a92c-31e3173dbc43",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: "채팅 튀엇",
			type: "TEXT",
			user_hidden: [],
			price: 0,
			status: true,
			created_at_unix_timestamp: "1694142693296",
			updated_at_unix_timestamp: "1694142693296",
			created_at: "2023-09-08T03:11:33.266Z",
			updated_at: "2023-09-08T03:11:33.266Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "c81ea520-4df2-11ee-8413-2188aeda9a9b",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 10,
			status: true,
			created_at_unix_timestamp: "1694141562995",
			updated_at_unix_timestamp: "1694141562995",
			created_at: "2023-09-08T02:52:42.974Z",
			updated_at: "2023-09-08T02:52:42.974Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "d4f9c270-4ded-11ee-8413-2188aeda9a9b",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 100,
			status: true,
			created_at_unix_timestamp: "1694139437080",
			updated_at_unix_timestamp: "1694139437080",
			created_at: "2023-09-08T02:17:17.061Z",
			updated_at: "2023-09-08T02:17:17.061Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "a84c88c0-4ded-11ee-8413-2188aeda9a9b",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 10,
			status: true,
			created_at_unix_timestamp: "1694139362125",
			updated_at_unix_timestamp: "1694139362125",
			created_at: "2023-09-08T02:16:02.102Z",
			updated_at: "2023-09-08T02:16:02.102Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "1b39e890-4dea-11ee-8413-2188aeda9a9b",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: null,
			type: "GIFT",
			user_hidden: [],
			price: 10,
			status: true,
			created_at_unix_timestamp: "1694137836954",
			updated_at_unix_timestamp: "1694137836954",
			created_at: "2023-09-08T01:50:36.931Z",
			updated_at: "2023-09-08T01:50:36.931Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "1ff4c910-4d5c-11ee-9436-136d9a13bcf0",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: "안녕하세요",
			type: "TEXT",
			user_hidden: [],
			price: 0,
			status: true,
			created_at_unix_timestamp: "1694076856354",
			updated_at_unix_timestamp: "1694076856354",
			created_at: "2023-09-07T08:54:16.333Z",
			updated_at: "2023-09-07T08:54:16.333Z",
			deleted_at: null,
			are_you: true,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_3_3-1.png",
				avatar: [
					"https://fansomftp.flexcloud.co.kr/0085280abfa7800b614fe3876aba8fd9_248177/0085280abfa7800b614fe3876aba8fd9_248177.jpeg",
					"https://fansomftp.flexcloud.co.kr/d0d6bc49d131217859e7fe9ed16ed398_152593/d0d6bc49d131217859e7fe9ed16ed398_152593.jpeg",
				],
				id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
				nickname: "가연잉",
				fullname: null,
				email: "pppqqq123@gmail.com",
				user_type: "CELEBRITY",
				register_status: "ACCEPT",
				identified_id: "GSOC36HCTtnd",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "10c91e50-4d5c-11ee-9436-136d9a13bcf0",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: "안녕하세요?",
			type: "TEXT",
			user_hidden: [],
			price: 0,
			status: true,
			created_at_unix_timestamp: "1694076830902",
			updated_at_unix_timestamp: "1694076830902",
			created_at: "2023-09-07T08:53:50.878Z",
			updated_at: "2023-09-07T08:53:50.878Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "02bc02f0-4d5c-11ee-9436-136d9a13bcf0",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
			secret_album_id: null,
			default_message_id: null,
			admin_message_id: null,
			content: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/emoji_11.svg",
			type: "STICKER",
			user_hidden: [],
			price: 0,
			status: true,
			created_at_unix_timestamp: "1694076807328",
			updated_at_unix_timestamp: "1694076807328",
			created_at: "2023-09-07T08:53:27.296Z",
			updated_at: "2023-09-07T08:53:27.296Z",
			deleted_at: null,
			are_you: false,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_2_2-9.png",
				avatar: [
					"https://server-prod.fansome.co.kr:5000/api/v1/image/get/resized-image-1695809575019.png",
				],
				id: "9ae000f0-4d2e-11ee-babb-bbcf24845a1e",
				nickname: "fstest02",
				fullname: "관리자등록",
				email: "fstest02@gmail.com",
				user_type: "NORMAL",
				register_status: "PENDING",
				identified_id: "esNAuh1vfcbj",
			},
			medias: [],
			secret_message: null,
			secret_album: null,
		},
		{
			id: "b35c7e10-4d5b-11ee-9436-136d9a13bcf0",
			conversation_id: "b353f290-4d5b-11ee-9436-136d9a13bcf0",
			parent_id: null,
			sender_id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
			secret_album_id: null,
			default_message_id: "33f10f20-40ad-11ee-8f5f-2949dd79cb8e",
			admin_message_id: null,
			content: "hihhhhh",
			type: "TEXT",
			user_hidden: [],
			price: 0,
			status: true,
			created_at_unix_timestamp: "1694076674162",
			updated_at_unix_timestamp: "1694076674162",
			created_at: "2023-09-07T08:51:14.104Z",
			updated_at: "2023-09-07T08:51:14.104Z",
			deleted_at: null,
			are_you: true,
			sender: {
				last_open_app: "Invalid date",
				default_avatar: "https://server-prod.fansome.co.kr:5000/api/v1/image/get/avatar_3_3-1.png",
				avatar: [
					"https://fansomftp.flexcloud.co.kr/0085280abfa7800b614fe3876aba8fd9_248177/0085280abfa7800b614fe3876aba8fd9_248177.jpeg",
					"https://fansomftp.flexcloud.co.kr/d0d6bc49d131217859e7fe9ed16ed398_152593/d0d6bc49d131217859e7fe9ed16ed398_152593.jpeg",
				],
				id: "c2f98ce0-40a5-11ee-8f5f-2949dd79cb8e",
				nickname: "가연잉",
				fullname: null,
				email: "pppqqq123@gmail.com",
				user_type: "CELEBRITY",
				register_status: "ACCEPT",
				identified_id: "GSOC36HCTtnd",
			},
			medias: [
				{
					id: "b35d6870-4d5b-11ee-9436-136d9a13bcf0",
					url: "https://fansomftp.flexcloud.co.kr/411f1df986900087a9a4732e26ef8288_127371/411f1df986900087a9a4732e26ef8288_127371.jpeg",
					message_id: "b35c7e10-4d5b-11ee-9436-136d9a13bcf0",
					status: true,
					media_meta_data: {
						id: "2e337c20-5ebd-11ee-a863-f733a1fc5392",
						size: 127371,
						type: "IMAGE",
						status: true,
						sources: {
							first: {
								url: "https://fansomftp.flexcloud.co.kr/8f99cbb9cf50ae36d742e3786145d74b_150682/8f99cbb9cf50ae36d742e3786145d74b_150682.jpeg",
								size: 150682,
								sharp_quality: 90,
							},
							third: {
								url: "https://fansomftp.flexcloud.co.kr/53a1979ce24987b4a5c435b730cd0be7_35077/53a1979ce24987b4a5c435b730cd0be7_35077.jpeg",
								size: 35077,
								sharp_quality: 30,
							},
							second: {
								url: "https://fansomftp.flexcloud.co.kr/a33166609ddc5c14d3c0d01d9b49f8cb_65370/a33166609ddc5c14d3c0d01d9b49f8cb_65370.jpeg",
								size: 65370,
								sharp_quality: 60,
							},
						},
						thumbnails: [
							{
								url: "https://fansomftp.flexcloud.co.kr/e3bd1d841462b1c517f88377541e6af1_116189/e3bd1d841462b1c517f88377541e6af1_116189.jpeg",
								size: 116189,
								sharp_quality: 80,
							},
						],
						original_url:
							"https://fansomftp.flexcloud.co.kr/411f1df986900087a9a4732e26ef8288_127371/411f1df986900087a9a4732e26ef8288_127371.jpeg",
					},
					created_at_unix_timestamp: "1694076674167",
					updated_at_unix_timestamp: "1695987711202",
					created_at: "2023-09-07T08:51:14.104Z",
					updated_at: "2023-09-29T11:41:51.001Z",
				},
			],
			secret_message: null,
			secret_album: null,
		},
	],
};

export const checkImageLink = (link) => {
	return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(link);
};

export const getAdminType = (employeeType, userName) => {
	if (employeeType === adminType.ADMIN_ASP) {
		let existCompany = _.find(company, (item) => _.includes(userName, item));
		if (!existCompany) return adminType.SUPER_ADMIN_ASP;
		// return `${adminType.ADMIN_ASP}_${existCompany.toUpperCase()}`;
	}
	return employeeType;
};

export const getFilterTypeAdminASP = (employeeType) => {
	return _.last(_.split(employeeType, "_"));
};

export const formatUrl = (url) => {
	if (!/^https?:\/\//i.test(url)) {
		return "https://" + url;
	}
	return url;
};

export const stringFormat = (str, ...args) => {
	return str.replace(/{(\d+)}/g, (match, number) => {
		return typeof args[number] !== "undefined" ? args[number] : match;
	});
};
