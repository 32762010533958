import "./style.css";
import React from "react";
import "./style.scss";
const FilterInputWrapper = ({ label, children, style_label = {} }) => {
	return (
		<div className="filterInput">
			<div className="filterInput__label" style={{ ...style_label }}>
				<label style={{ fontSize: 11 }}>{label}</label>
			</div>
			<div className="filterInput__body">{children}</div>
		</div>
	);
};

export default FilterInputWrapper;
