import { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserApi from "src/api/MemberApi";
import HasgtagApi from "src/api/HasgtagApi";
import { IsCeleb, getAgeUser } from "src/util/Utils";
import Typography from "@mui/material/Typography";
import {
	UserInfo,
	UserInfoChange,
	Star,
	ListCoin,
	Payment,
	FeedList,
	FollowList,
	FollowListCeleb,
	InquiriUserProfile,
	SupcriptionList,
	CelebInfoChange,
	CelebInfo,
	NormalToCeleb,
} from "./userProfileDetail";

import {
	UserInfoASP,
	UserInfoChangeASP,
	StarASP,
	FollowListASP,
	InquiriUserProfileASP,
} from "./userProfileDetailASP";

import { userType } from "src/util/data";
import "./style.css";
import { convertTextToKorean } from "src/util/convert";
import PluskitPackageListASP from "./userProfileDetailASP/PluskitPackageListASP";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "100%",
	maxWidth: "1575px",
};
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const TabItemsCeleb = [
	{ copom: CelebInfo, label: "셀럽정보" },
	{ copom: CelebInfoChange, label: "셀럽 정보 변경" },
	{ copom: Payment, label: "결제" },
	{ copom: Star, label: "스타" },
	{ copom: ListCoin, label: "코인 적립 리스트" },
	{ copom: FeedList, label: "피드 리스트" },
	{ copom: FollowListCeleb, label: "팔로우 리스트" },
	{ copom: SupcriptionList, label: "구독 리스트" },
	{ copom: InquiriUserProfile, label: "문의사항" },
];
const TabItemsNormalNoASP = [
	{ copom: UserInfo, label: "회원정보" },
	{ copom: UserInfoChange, label: "회원정보변경" },
	{ copom: Payment, label: "결제" },
	{ copom: Star, label: "스타" },
	{ copom: FollowList, label: "팔로우 리스트" },
	{ copom: SupcriptionList, label: "구독 리스트" },
	{ copom: InquiriUserProfile, label: "문의사항" },
	{ copom: NormalToCeleb, label: "업데이트" },
];
const TabItemsNormalASP = [
	{ copom: UserInfoASP, label: "회원정보" },
	{ copom: UserInfoChangeASP, label: "회원정보변경" },
	{ copom: StarASP, label: "스타" },
	{ copom: FollowListASP, label: "팔로우 리스트" },
	{ copom: PluskitPackageListASP, label: "패키지" },
	{ copom: InquiriUserProfileASP, label: "문의사항" },
];
const UserProfile = ({ open, handleClose, id, setReloadApiAfterChange }) => {
	const [hashTagData, setHashTagData] = useState([]);
	const [value, setValue] = useState(0);
	const [userInfo, setuserInfo] = useState(null);
	const [TabItemsNormal, setTabItemsNormal] = useState(TabItemsNormalNoASP);
	const [asp, setAsp] = useState(null);
	const [title, settitle] = useState("");
	const arr = [];
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const fetchAll = async () => {
		console.log("🚀 ~ file: UserProvider.js:34 ~ UserProvider ~ id:", id);
		try {
			if (id) {
				setuserInfo(null);
				const res = await UserApi.userProfile(id);
				const res2 = await UserApi.userProfileASP(id);
				setuserInfo(res?.results?.object);
				if (res?.results?.object?.is_asp === true) {
					setTabItemsNormal(TabItemsNormalASP);
				} else {
					setTabItemsNormal(TabItemsNormalNoASP);
				}
				if (res2) {
					setAsp(res2?.results?.object?.asp_user);
				}
				IsCeleb(res?.results?.object) ? settitle("셀럽 상세 프로필") : settitle("회원 상세프로필");
			} else return;
		} catch (error) {
			console.log(error);
		}
	};
	const featchHashTag = async () => {
		console.log("🚀 ", id);
		try {
			const response = await HasgtagApi.findAll(1, 200);
			if (response.code === 200) {
				// xu li pagination
				response.results.objects.rows.map((val, idx) => {
					return arr.push({
						label: "#" + val?.title || "",
						value: val?.id,
						type_belong: val?.type_belong || "",
					});
				});
			}
		} catch (error) {
		} finally {
			setHashTagData(arr);
		}
	};
	useEffect(() => {
		fetchAll();
		featchHashTag();
		setValue(0);
	}, [id]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				sx={{
					"& .MuiTabs-indicator": {
						backgroundColor: "black",
					},
					"& .MuiTab-root.Mui-selected": {
						color: "black",
					},
					"& .MuiTabs-flexContainer": {
						justifyContent: "space-between",
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Box sx={{ mx: "20px", bgcolor: "#fff", boxShadow: 24 }}>
							<div className="chatMessHeader">
								<p>{title}</p>
								<CloseIcon className="closeBtn" onClick={handleClose} />
							</div>

							<div className="userProfile-container">
								<h3>{title}</h3>
								<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
									<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
										{userInfo?.user_type === userType.CELEB &&
										userInfo?.register_status === "ACCEPT"
											? TabItemsCeleb.map((item, index) => (
													<Tab key={index} label={item.label} {...a11yProps(index)} />
											  ))
											: TabItemsNormal.map((item, index) => (
													<Tab key={index} label={item.label} {...a11yProps(index)} />
											  ))}
									</Tabs>
								</Box>

								<div style={{ overflow: "scroll", height: "500px" }}>
									{/* info */}
									<div style={{ display: "flex", padding: "24px 0" }}>
										<div className="user-info">
											<label htmlFor="">{IsCeleb(userInfo) ? "셀럽 ID" : "회원 ID"} </label>
											<input type="text" value={userInfo?.identified_id} name="" id="" readOnly />
										</div>
										<div className="user-info">
											<label htmlFor="">닉네임</label>
											<input type="text" value={userInfo?.nickname} name="" id="" readOnly />
										</div>
										<div className="user-info">
											<label htmlFor="">나이</label>
											<input
												type="text"
												value={getAgeUser(userInfo?.birthday) || "미입력"}
												name=""
												id=""
												readOnly
											/>
										</div>
										<div className="user-info">
											<label htmlFor="">성별</label>
											<input
												type="text"
												value={convertTextToKorean(userInfo?.sex)}
												name=""
												id=""
												readOnly
											/>
										</div>
										{
											<div className="user-info">
												{asp ? (
													<>
														<label htmlFor="">플랫폼 </label>
														<input type="text" value={asp?.asp_name} name="" id="" readOnly />
													</>
												) : (
													<>
														<label htmlFor="">uuid</label>
														<input type="text" value={userInfo?.idx} name="" id="" readOnly />
													</>
												)}
											</div>
										}
									</div>

									{userInfo?.user_type === userType.CELEB && userInfo?.register_status === "ACCEPT"
										? TabItemsCeleb.map((item, index) => {
												const { copom: Component } = item;
												return (
													<TabPanel value={value} index={index} key={index}>
														<Component
															hastag={hashTagData}
															featchHashTag={featchHashTag}
															data={userInfo}
															fetchAll={fetchAll}
															setReloadApiAfterChange={setReloadApiAfterChange}
														/>
													</TabPanel>
												);
										  })
										: TabItemsNormal.map((item, index) => {
												const { copom: Component } = item;
												return (
													<TabPanel value={value} index={index} key={index}>
														<Component
															hastag={hashTagData}
															featchHashTag={featchHashTag}
															data={userInfo}
															fetchAll={fetchAll}
															setReloadApiAfterChange={setReloadApiAfterChange}
														/>
													</TabPanel>
												);
										  })}
								</div>
							</div>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
};

export default UserProfile;
