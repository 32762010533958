import { useContext, useEffect, useState } from "react";
import ko from "date-fns/locale/ko";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import "./styles.scss";
import { userProfileContext } from "src/providers/UserProvider";

const DateRangePicker = ({ DateRange, setDateRange, name, form, placeholder, value }) => {
	const { isReloadApi } = useContext(userProfileContext);
	const [firstDate, setFirstDate] = useState();
	const [secondDate, setSecondDate] = useState(new Date());
	useEffect(() => {
		form.setFieldValue(name, [firstDate, secondDate]);
		setDateRange([firstDate, secondDate]);
		if (firstDate > secondDate) setSecondDate(firstDate);
		if (!firstDate && !secondDate) {
			form.setFieldValue(name, []);

			setDateRange([]);
		}
	}, [firstDate, secondDate]);

	useEffect(() => {
		setDateRange([]);
		setSecondDate("");
		setFirstDate("");
		form.setFieldValue(name, []);
	}, [isReloadApi]);

	return (
		<div className="date-picker-container">
			<DatePicker
				locale={ko}
				placeholderText={placeholder && placeholder[0] ? placeholder[0] : "YYYY/MM/DD"}
				selected={firstDate}
				showTimeSelect
				timeCaption="시간"
				onChange={(date) => {
					setFirstDate(date);
				}}
				dateFormat="yyyy/MM/dd HH:mm"
			/>
			<div style={{ margin: "0 4px", display: "flex", alignItems: "center" }}>-</div>
			<DatePicker
				locale={ko}
				placeholderText={placeholder && placeholder[1] ? placeholder[1] : "YYYY/MM/DD"}
				selected={secondDate}
				minDate={firstDate}
				showTimeSelect
				timeCaption="시간"
				onChange={(date) => {
					setSecondDate(date);
				}}
				dateFormat="yyyy/MM/dd HH:mm"
			/>
		</div>
	);
};

export default DateRangePicker;
