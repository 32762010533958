import { Alert, Snackbar } from "@mui/material";

import React, { createContext, useState } from "react";
import checkLogo from "src/assets/img/AlertIcon.svg";

const AlertContext = createContext();

function AlertProvider({ children }) {
	const [alert, setAlert] = useState({
		open: false,
		type: null,
		content: null,
		confirm: false,
	});

	return (
		<AlertContext.Provider
			value={{
				alert: alert,
				showAlert: (data) =>
					setAlert({
						...data,
						open: true,
					}),
			}}
		>
			<>
				{alert.open && alert.confirm && (
					<Snackbar
						open={alert.open}
						onClose={() =>
							setAlert({
								open: false,
								type: null,
								content: null,
								confirm: false,
							})
						}
						autoHideDuration={1500}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<Alert
							icon={false}
							sx={{
								"& .MuiAlert-message": {
									display: "flex",
									alignItems: "center",
								},
								fontSize: 14,
								background: "#151515",
								color: "white",
							}}
						>
							<img src={checkLogo} alt="check icon" />
							<p
								style={{
									marginLeft: "5px",
								}}
							>
								{" "}
								{alert.content}
							</p>
						</Alert>
					</Snackbar>
				)}
				{children}
			</>
		</AlertContext.Provider>
	);
}

export { AlertContext, AlertProvider };
