// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NormalToCeleb_wrapper__2OYTM {\n  display: flex;\n  justify-content: center;\n}\n.NormalToCeleb_wrapper__2OYTM .NormalToCeleb_container__2Qsbu {\n  width: 25%;\n  display: flex;\n  justify-content: space-between;\n}\n.NormalToCeleb_wrapper__2OYTM .NormalToCeleb_container__2Qsbu .NormalToCeleb_inputPhone__1zZs_ {\n  width: 70%;\n}", "",{"version":3,"sources":["webpack://src/components/phase2/userProfileDetail/NormalToCeleb.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AACE;EACE,UAAA;EACA,aAAA;EACA,8BAAA;AACJ;AACI;EACE,UAAA;AACN","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n\n  .container {\n    width: 25%;\n    display: flex;\n    justify-content: space-between;\n\n    .inputPhone {\n      width: 70%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "NormalToCeleb_wrapper__2OYTM",
	"container": "NormalToCeleb_container__2Qsbu",
	"inputPhone": "NormalToCeleb_inputPhone__1zZs_"
};
export default ___CSS_LOADER_EXPORT___;
