import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Emitter from "src/util/Emitter";
import * as _ from "lodash";

const SelectInput = styled(Select)(({ theme }) => ({
	borderRadius: "10px",
	background: "#FAFAFA",
	border: "1px solid #E1E1E1",
	width: "100%",
	height: "100%",
	"& legend": { display: "none" },
	"& fieldset": { top: 0 },
}));

const SelectField = ({ field, form, data, setDateRange }) => {
	const [selectedField, setSelectedField] = useState(field.value);

	useEffect(() => {
		if (
			!field.name ||
			(field.name.toUpperCase() !== "TYPEPLATFORM" && field.name.toUpperCase() !== "PLATFORM")
		) {
			setSelectedField(field.value);
			return;
		}

		// Auto trigger filter submit when the data length of field is 1
		if (data.length === 1 && data[0].value !== "ALL" && field.name && form.values) {
			setSelectedField(data[0].value);
			form.setFieldValue(field.name, data[0].value);
			let currentFormValues = _.cloneDeep(form.values);
			currentFormValues[field.name] = data[0].value;
			Emitter.emit("AUTO_FILTER", currentFormValues);
		} else setSelectedField(field.value);
	}, [data]);

	return (
		<>
			<SelectInput
				value={selectedField}
				onChange={(e) => {
					setSelectedField(e.target.value);
					form.setFieldValue(field.name, e.target.value);
					if (setDateRange && e.target.value === "ALL") {
						setDateRange([]);
						form.setFieldValue("time__data", []);
					}
				}}
				disabled={data?.length === 1}
				defaultValue={data[0]?.value}
			>
				{data.map((item, index) => (
					<MenuItem key={index} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</SelectInput>
		</>
	);
};

export default SelectField;
