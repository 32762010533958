import jwt_decode from "jwt-decode";
import React, { Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import "./app.css";
import { icons } from "./assets/icons";
import { TheLayout } from "./components/phase1/containers";
import { LoadingProvider } from "./providers/LoadingProvider";
import { UserProvider } from "./providers/UserProvider";
import { AlertProvider } from "./providers/AlertProvider";
import routes, { routesAntiKdisk, routesContentManager } from "./routes";
import "./scss/style.scss";
import { getAdminType, getToken } from "./util/Utils";
import { showError } from "./util/error";
import { adminType } from "src/util/data";

React.icons = icons;
const Login = React.lazy(() => import("./Pages/login/Login"));
const ListUserASPLog = React.lazy(() => import("./Pages/UserManagementASP/ListUserASPLog"));

function App() {
	const a = useSelector((state) => state.employee);
	const employee = JSON.parse(localStorage.getItem("user"));
	const employeeType = getAdminType(employee?.type, employee?.username);
	const token = getToken();
	const tokenDecode = token ? jwt_decode(token) : "";
	let routerWeb;
	let expired = false;

	const isAspAdmin = useMemo(() => {
		return employeeType === adminType.ADMIN_ASP || employeeType === adminType.SUPER_ADMIN_ASP;
	}, [employeeType]);

	const isContentManager = useMemo(() => {
		return employeeType === adminType.OPERATOR || employeeType === adminType.CONTENT_MANAGER;
	}, [employeeType])

	if (new Date(tokenDecode.exp).getTime() <= Date.now()) {
		expired = true;
	}
	if (expired) {
		localStorage.clear();
		showError("Your login session has been expired!");
	}
	if (isAspAdmin) {
		routerWeb = routesAntiKdisk;
	} else if (isContentManager) {
		routerWeb = routesContentManager;
	} else {
		routerWeb = routes;
	}

	const loading = (
		<div className="pt-3 text-center">
			<div className="sk-spinner sk-spinner-pulse"></div>
			...로딩 중
		</div>
	);

	const PrivateRoute = ({ component: Component, ...rest }) => (
		<Route
			{...rest}
			render={(props) =>
				a.length || localStorage.getItem("user") ? (
					<>
						<Component {...props} />
					</>
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);

	return (
		<div>
			<HashRouter basename="/">
				<AlertProvider>
					<LoadingProvider>
						<UserProvider>
							<LastLocationProvider>
								<Suspense fallback={loading}>
									<Switch>
										<Route exact path="/listUserASPLog" name="ListUserASPLog" component={ListUserASPLog}/>
										<Route exact path="/login" name="Login" component={Login} />
										<TheLayout>
											{routerWeb?.map((route, index) => (
												<PrivateRoute
													key={index}
													exact={route.exact}
													path={route.path}
													component={route.component}
												/>
											))}
										</TheLayout>
									</Switch>
								</Suspense>
							</LastLocationProvider>
						</UserProvider>
					</LoadingProvider>
				</AlertProvider>
			</HashRouter>
		</div>
	);
}

export default App;
