import Api from "./Api";
export default class penatyApi extends Api {
	addMulti(body) {
		console.log("url", body);
		const url = `${this.HOSTNAME}/${this.table}/admin/penalty_multi_user`;
		console.log("url", url);
		return this.axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		});
	}
}
