import Api from "./Api";
import axiosClient from "./axiosClient";

export default class saleHistoryApi extends Api {
	getAll(page, limit, filter) {
		const url = `${this.HOSTNAME}/${this.table}/admin/get_list_cms?${this.fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["updated_at","desc"]]`;
		return axiosClient.post(url);
	}

	getListSubscriber(page, limit, filter, data) {
		const url = `${this.HOSTNAME}/${this.table}/admin/get_list_subscriber_cms?${this.fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["updated_at","desc"]]`;
		return axiosClient.post(url, data);
	}
}
