import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import ExportCSV from "./ExportCSV";
import ExportCSVV2 from "src/components/phase2/ExportCSVV2";

import "./style.css";
import Swal from "sweetalert2";

function DataTable({
	columns,
	items,
	total,
	page,
	currentPage,
	changePage,
	limit,
	fileName,
	checkboxSelection,
	onSelectionModelChange,
	handleMany,
	autoHeight,
	handleDelete,
	handleAdd,
	showLimit,
	handleRequest,
	handleProcces,

	titleDelete,
	showPrint = false,
	itemPrint,
	setLimitPrint,
	setItemPrint,
	customAction
}) {
	const getRowHeight = React.useCallback(() => {
		return "auto";
	}, []);
	return (
		<Box
			sx={{
				width: "100%",
				"& .bold": {
					fontWeight: 600,
				},
				"& .MuiDataGrid-virtualScroller": {
					background: "white",
				},
				"& .MuiDataGrid-columnHeaderRow": {
					background: "f6f6f6",
				},
			}}
		>
			<div className="headerDataTable">
				<Stack spacing={2} direction="row">
					<p>총 {total || 0}건</p>
					<Stack spacing={2} direction="row" sx={{ height: "42px" }}>
						{handleProcces && (
							<Button
								onClick={handleProcces}
								variant="outlined"
								sx={{
									background: "yellow",
									width: 112,
									height: 42,
									borderRadius: "0.5rem",
									border: "1px solid yellow",
									color: "black",
								}}
							>
								선택대기
							</Button>
						)}
						{handleRequest && (
							<Button
								variant="outlined"
								onClick={async () =>
									Swal.fire({
										title: " 취소하시겠습니까?",
										icon: "warning",
										showCancelButton: true,
										confirmButtonText: "확인",
										cancelButtonColor: "#d33",
										cancelButtonText: "취소",
									}).then((result) => {
										if (result.isConfirmed) {
											handleRequest();
										}
									})
								}
								// onClick={handleRequest}
								sx={{
									width: 112,
									height: 42,
									borderRadius: "0.5rem",

									color: "black",
								}}
							>
								선택취소
							</Button>
						)}
					</Stack>
				</Stack>

				<Stack spacing={2} direction="row" sx={{ height: "42px" }}>
					{customAction}
					{handleDelete && (
						<Button
							onClick={handleDelete}
							variant="outlined"
							sx={{
								background: "#CECECE",
								width: 112,
								height: 42,
								borderRadius: "0.5rem",
								border: "1px solid #B1B1B1",
								color: "black",
							}}
						>
							{handleMany?.titleBlock ? handleMany?.titleBlock : "회원탈퇴"}
						</Button>
					)}
					{handleMany?.title && (
						<Button
							variant="outlined"
							sx={{
								background: "#FFDBDB",
								width: 141,
								height: 42,
								borderRadius: "0.5rem",
								border: "1px solid #FEA0A0",
								color: "black",
							}}
							onClick={handleMany.action}
						>
							{handleMany.title}
						</Button>
					)}
					{handleAdd && (
						<Button
							variant="contained"
							sx={{
								width: 141,
								height: 42,
								borderRadius: "0.5rem",
								color: "white",
							}}
							onClick={handleAdd.action}
						>
							{handleAdd.title}
						</Button>
					)}
					{!showPrint ? (
						<ExportCSV csvData={items} fileName={fileName} />
					) : (
						<ExportCSVV2
							csvData={itemPrint}
							fileName={fileName}
							total={total}
							setLimit={setLimitPrint}
							setItemPrint={setItemPrint}
						/>
					)}
					{(showLimit === false ? showLimit : true) && (
						<FormControl sx={{ height: "42px" }}>
							<Select
								className="select"
								value={limit.value}
								sx={{
									"& legend": { display: "none" },
									"& fieldset": { top: 0 },
								}}
								onChange={(e) => limit.set(e.target.value)}
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
								<MenuItem value={100}>100</MenuItem>
							</Select>
						</FormControl>
					)}
				</Stack>
			</div>
			<DataGrid
				sx={{
					"& .MuiDataGrid-cell--textLeft": {
						justifyContent: "center",
					},
					overflowX: "scroll",
				}}
				autoHeight
				getRowHeight={autoHeight && getRowHeight}
				disableExtendRowFullWidth
				disableColumnFilter
				disableSelectionOnClick
				disableColumnMenu
				hideFooter
				showCellRightBorder
				showColumnRightBorder
				columns={columns}
				rows={items}
				checkboxSelection={checkboxSelection}
				onSelectionModelChange={onSelectionModelChange}
			/>
			<div className="pagination">
				<Pagination
					count={page}
					page={currentPage}
					variant="outlined"
					shape="rounded"
					onChange={changePage}
				/>
			</div>
		</Box>
	);
}
export default React.memo(DataTable);
