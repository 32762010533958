export const TYPE_STATISTICS = {
	DAILY: 1,
	TIMEZONE: 2,
};

export const CategoryErrorEnum = {
	// API_ERROR
	API_EXCEPTION: "API_EXCEPTION",
	API_GET_TOKEN_AGORA_CHAT_ERROR: "API_GET_TOKEN_AGORA_CHAT_ERROR",
	API_GET_TOKEN_AGORA_RTC_ERROR: "API_GET_TOKEN_AGORA_RTC_ERROR",

	// AGORA_ERROR
	AGORA_EXCEPTION: "AGORA_EXCEPTION",
	AGORA_DISCONNECT_VIDEO_CALL: "AGORA_DISCONNECT_VIDEO_CALL",
	AGORA_DISCONNECT_CHAT: "AGORA_DISCONNECT_CHAT",
	AGORA_EXCEPTION_CHAT: "AGORA_EXCEPTION_CHAT",
	AGORA_REMOTE_VIDEO_CHANGED_FAILED: "AGORA_REMOTE_VIDEO_CHANGED_FAILED",
	AGORA_CONNECTION_STATE_CHANGED_FAILED: "AGORA_CONNECTION_STATE_CHANGED_FAILED",

	// INVITATION_FAILED
	INVITATION_TIMEOUT: "INVITATION_TIMEOUT",
	INVITATION_CANCELLED: "INVITATION_CANCELLED",
	INVITATION_REFUSED: "INVITATION_REFUSED",
	INVITATION_BUSY: "INVITATION_BUSY",
	INVITATION_API_FAILED: "INVITATION_API_FAILED",

	// CLIENT_ERROR
	CLIENT_SOCKET_ERROR: "CLIENT_SOCKET_ERROR",
	CLIENT_SOCKET_DISCONNECT: "CLIENT_SOCKET_DISCONNECT",
	CLIENT_SOCKET_CONNECT_ERROR: "CLIENT_SOCKET_CONNECT_ERROR",
	CLIENT_OUT_OF_MEMORY: "CLIENT_OUT_OF_MEMORY",
	CLIENT_SOCKET_CONNECT_TIME_OUT: "CLIENT_SOCKET_CONNECT_TIME_OUT",
	CLIENT_JOIN_CHANNEL_FAILED: "CLIENT_JOIN_CHANNEL_FAILED",
};

export const ERROR_MESSAGES = {
	// API_ERROR
	[CategoryErrorEnum.API_EXCEPTION]: "Api Exception",
	[CategoryErrorEnum.API_GET_TOKEN_AGORA_CHAT_ERROR]: "Get token Agora Chat Error",
	[CategoryErrorEnum.API_GET_TOKEN_AGORA_RTC_ERROR]: "Get token Agora RTC Error",

	// AGORA_ERROR
	[CategoryErrorEnum.AGORA_EXCEPTION]: "Agora Exception",
	[CategoryErrorEnum.AGORA_DISCONNECT_VIDEO_CALL]: "Agora Disconnect Video Call",
	[CategoryErrorEnum.AGORA_DISCONNECT_CHAT]: "Agora Disconnect Chat",
	[CategoryErrorEnum.AGORA_EXCEPTION_CHAT]: "Agora Exception Chat",
	[CategoryErrorEnum.AGORA_REMOTE_VIDEO_CHANGED_FAILED]: "Agora Remote Video Changed Failed",
	[CategoryErrorEnum.AGORA_CONNECTION_STATE_CHANGED_FAILED]:
		"Agora Connection State Changed Failed",

	// INVITATION_FAILED
	[CategoryErrorEnum.INVITATION_TIMEOUT]: "Invitation Timeout",
	[CategoryErrorEnum.INVITATION_CANCELLED]: "Invitation Cancelled",
	[CategoryErrorEnum.INVITATION_REFUSED]: "Invitation Refused",
	[CategoryErrorEnum.INVITATION_API_FAILED]: "Invitation Api Failed",
	[CategoryErrorEnum.INVITATION_BUSY]: "Invitation Busy",

	// CLIENT_ERROR
	[CategoryErrorEnum.CLIENT_SOCKET_DISCONNECT]: "Client Socket Disconnect",
	[CategoryErrorEnum.CLIENT_SOCKET_ERROR]: "Client Socket Error",
	[CategoryErrorEnum.CLIENT_SOCKET_CONNECT_ERROR]: "Client Socket Connect Error",
	[CategoryErrorEnum.CLIENT_OUT_OF_MEMORY]: "Client Out Of Memory",
	[CategoryErrorEnum.CLIENT_SOCKET_CONNECT_TIME_OUT]: "Client Socket Connect Time Out",
	[CategoryErrorEnum.CLIENT_JOIN_CHANNEL_FAILED]: "Client Join Channel Failed",
};

export const CALL_TYPE = {
	VIDEO_CALL: "VIDEO_CALL",
	PLUS_KIT: "PLUS_KIT",
};

export const CALL_STATUS_TYPE = {
	SUCCESS: "SUCCESS",
	FAIL: "FAIL",
};

export const CALL_STATUS_DONE = "CALL_DONE";
export const CALL_STATUS_CALLING = "CALLING";

export const CALL_METHOD_TYPE = {
	DIRECT: "DIRECT_CALL",
	RANDOM: "RANDOM_CALL",
};

export const ERROR_CODES = [
	{
		code: 5000,
		name: "API Error",
	},
	{
		code: 5001,
		name: "Client Error",
	},
	{
		code: 5002,
		name: "Agora Error",
	},
	{
		code: 5003,
		name: "Invitation Error",
	},
];
