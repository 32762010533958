import moment from "moment";
import EmployeeApi from "../api/EmployeeApi";

const LIMIT_REVENUE = 1000;

export const convertDatePicker = (date, time) => {
	const dateFormat = moment(date).format("YYYY/MM/DD");
	const result = moment(dateFormat)
		.add(time?.split(":")[0], "hours")
		.add(time?.split(":")[1], "minutes")
		.utc()
		.valueOf();
	return result;
};

export const convertTimeZone = (data) => {
	if (!data) return "-";
	return data ? moment(new Date(data).getTime()).format("YYYY/MM/DD HH:mm") : null;
};
export const convertTimeZoneSecond = (data) => {
	if (!data) return "-";
	return data ? moment(new Date(data).getTime()).format("YYYY/MM/DD HH:mm:ss") : null;
};
export const convertTimeBirthday = (data) => {
	return moment(new Date(data).getTime()).format("YYYY/MM/DD");
};
export const convertTimeToAge = (data) => {
	return Number.isNaN(
		moment().diff(
			moment(moment(new Date(data).getTime()).format("YYYY/MM/DD"), "DD-MM-YYYY"),
			"years"
		)
	)
		? "< 1"
		: moment().diff(
				moment(moment(new Date(data).getTime()).format("YYYY/MM/DD"), "DD-MM-YYYY"),
				"years"
		  );
};

export function convertMili(millisec) {
	var seconds = (millisec / 1000).toFixed(0);
	var minutes = Math.floor(seconds / 60);
	var hours = "";

	if (minutes > 59) {
		hours = Math.floor(minutes / 60);
		hours = hours >= 10 ? hours : "0" + hours;
		minutes = minutes - hours * 60;
		minutes = minutes >= 10 ? minutes : "0" + minutes;
	}

	seconds = Math.floor(seconds % 60);
	seconds = seconds >= 10 ? seconds : "0" + seconds;
	if (hours !== "") {
		return hours + ":" + minutes + ":" + seconds;
	}
	return minutes + ":" + seconds;
}

export function convertLevel(totalScore) {
	let result = "";

	if (totalScore) {
		let scorePronounce = totalScore.pronounce.totalScore;
		let rhytmScore = totalScore.rhytmScore.totalScore;
		let intonationScore = totalScore.intonationScore.totalScore;
		let proficiencyScore = totalScore.proficiencyScore.totalScore;
		let total = scorePronounce + rhytmScore + intonationScore + proficiencyScore;

		if (total > 300) {
			return (result = "ADVANCED");
		} else if (total > 100) {
			return (result = "INTERMEDIATE");
		} else {
			return (result = "BEGINNER");
		}
	} else {
		return result;
	}
}

export async function convertNickName(id) {
	const res = await EmployeeApi.findOne(id);
	if (res.code === 200) {
		return res.results.object.username;
	}
}

export const formatSerial = (number) => {
	let string = number?.toString();

	if (string?.length === 1) return `00${number}`;
	else if (string?.length === 2) return `0${number}`;
	else if (!string) return "";
	else return number;
};

export const convertSerial = (pitch, pronunciation, rhythm, fluency) => {
	let pitchString = pitch?.toString();
	let pronunciationString = pronunciation?.toString();
	let rhythmString = rhythm?.toString();
	let fluencyString = fluency?.toString();

	if (pitchString?.length === 1) pitch = `00${pitch}`;
	else if (pitchString?.length === 2) pitch = `0${pitch}`;
	else if (pitchString?.length === 3) pitch = `${pitch}`;

	if (pronunciationString?.length === 1) pronunciation = `00${pronunciation}`;
	else if (pronunciationString?.length === 2) pronunciation = `0${pronunciation}`;
	else if (pronunciationString?.length === 3) pronunciation = `${pronunciation}`;

	if (rhythmString?.length === 1) rhythm = `00${rhythm}`;
	else if (rhythmString?.length === 2) rhythm = `0${rhythm}`;
	else if (rhythmString?.length === 3) rhythm = `${rhythm}`;

	if (fluencyString?.length === 1) fluency = `00${fluency}`;
	else if (fluencyString?.length === 2) fluency = `0${fluency}`;
	else if (fluencyString?.length === 3) fluency = `${fluency}`;

	if (!pitchString && !pronunciationString && !rhythmString && !fluencyString) {
		return "";
	}

	return `${pitch}-${pronunciation}-${rhythm}-${fluency}`;
};

export const checkTime = (time) => {
	let pattern = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;
	let res = time.match(pattern);
	if (res[0] === res?.input) return true;
	else return false;
	//   if (res[0] === time) return
};

export const updateSerial = (serialNumber) => {
	let serial = serialNumber?.toString();

	if (serial?.length === 1) serialNumber = `00${serialNumber}`;
	else if (serial?.length === 2) serialNumber = `0${serialNumber}`;
	else if (serial?.length === 3) serialNumber = `${serialNumber}`;

	return serialNumber;
};

export const clipString = (data, lengthString = 50) => {
	if (!data) return "";
	const regex = /(<([^>]+)>)/gi;
	data = data.replace(regex, "");
	data = data.replace(/&nbsp;/g, " ").trim();
	if (data.length > lengthString) {
		data = data.substring(0, lengthString) + " ...";
	}

	return data;
};

export const convertPostType = (data) => {
	if (!data) return "";

	const type = [
		{ value: "SALE", label: "Sale" },
		{ value: "PROMOTION", label: "Khuyến Mãi" },
		{ value: "NEWS", label: "Tin Tức" },
		{ value: "TERMS", label: "Điều Khoản" },
	];

	for (const item of type) {
		if (item.value === data) {
			return item.label;
		}
	}
};

export const convertDiscountType = (data) => {
	if (!data) return "";
	if (data === "SHARED") return "Giảm giá voucher";
	if (data === "STANDARD") return "Tiêu chuẩn";
	if (data === "CONTRIBUTOR") return "Giảm giá cộng tác viên";
};

export const convertStatusTransaction = (data) => {
	if (!data) return "";
	if (data === "PENDING") return "Đang xử lý";
	if (data === "DONE") return "Hoàn thành";
	if (data === "CANCEL") return "Hủy";
};

export const convertFrequency = (data) => {
	if (!data) return "";
	if (data === "ONE_TIME") return "Một lần";
	if (data === "REGULAR_EVERY_7_DAYS") return "7 ngày 1 lần";
	if (data === "REGULAR_EVERY_30_DAYS") return "30 ngày 1 lần";
};

export const convertMoneyVND = (data) => {
	if (!data) return "";
	return new Intl.NumberFormat("vn-VN", {
		style: "currency",
		currency: "VND",
	}).format(data);
};
export const convertMoneyVNDCheckType = (data, type) => {
	if (!data) return "";
	const response = new Intl.NumberFormat("vn-VN", {
		style: "currency",
		currency: "VND",
	}).format(data);

	if (type === "AUTO") {
		return `+${response}`;
	} else {
		if (data < 0) return response;

		return `-${response}`;
	}
};

export const convertStringToMoney = (data) => {
	if (!data) return 0;
	return parseInt(data.replace("+", "").replace("-", "").replace(".", ""));
};

export const convertBonusPointToLevel = (bonus_point) => {
	if (bonus_point >= 10000) {
		return "DIAMOND";
	}

	if (bonus_point >= 5000) {
		return "GOLD";
	}

	if (bonus_point >= 1000) {
		return "SILVER";
	}

	return "DEFAULT";
};
export const convertVNDtoNumber = (data) => {
	return data.replace(/\./g, "").replace(/ ₫/g, "");
};

export const convertRate = (rate, price) => {
	if (rate === 0 || rate === "") return rate;
	return `${rate} = ${convertMoneyVND(price * rate)}`;
};
export const convertTextSearch = (data) => {
	return data.replace(/\./g, "").replace(/ ₫/g, "").replace(/\+/g, "");
};

export const getLinkUpload = (upload_link, link, image_type) => {
	console.log("getLinkUpload -> image_type", image_type);
	console.log("getLinkUpload -> link", link);
	console.log("getLinkUpload -> upload_link", upload_link);

	return image_type === "upload" ? upload_link : link;
};

export const filterNewActivityCRUD = (data) => {
	var result = data[data.length - 1];

	return result.activity;
};

export const filterNewActivity = (data) => {
	const sortedActivities = data.sort((a, b) =>
		a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0
	);

	const result = sortedActivities[sortedActivities.length - 1];
	if (result.activity === "PROCESSING") return "Đang xử lý";
	else if (result.activity === "CONFIRMED") return "Xác nhận";
	else if (result.activity === "COMPLETED") return "Hoàn thành";
	else if (result.activity === "CANCELLED") return "Hủy";
};
export const filterNewActivityUpdatedAt = (data) => {
	const result = data[0];
	return moment.utc(result.created_at).format("YYYY/MM/DD HH:mm");
};

export const convertCkeditor = (data) => {
	if (!data) return "";

	return data.replace(/<iframe src=/g, "<iframe url=");
};

export const convertRank = (data) => {
	if (data === "DIAMOND") return "Kim cương";
	else if (data === "GOLD") return "Vàng";
	else if (data === "SILVER") return "Bạc";
	else if (data === "DEFAULT") return "Thường";
};
export const convertObject = (data) => {
	if (data === "ALL") return "Tất cả";
	else if (data === "NORMAL") return "Thành viên thường";
	else if (data === "COLLAB") return "Cộng tác viên";
};

export const convertHMS = (value) => {
	if (value) {
		const sec = parseInt(value, 10); // convert value to number if it's string
		let hours = Math.floor(sec / 3600); // get hours
		let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
		let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
		// add 0 if value < 10; Example: 2 => 02

		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
	}
	return "00:00:00";
};

export const convertTextToKorean = (text) => {
	switch (text) {
		case "SUBSCRIBE":
			return "구독중";
		case "UNSUBSCRIBE":
			return "구독 종료";
		case "ACCEPT":
			return "승인";
		case "DENY":
			return "취소";
		case "PENDING":
			return "대기";
		case "ACCOUNT_RELATED":
			return "계정 관련";
		case "PAYMENT_RELATED":
			return "결제 관련";
		case "OTHER":
			return "기타";
		case "INDIVIDUAL":
			return "개인";
		case "BUSINESS":
			return "사업자";
		case "SUCCESS":
			return "성공";
		case "NORMAL":
			return "정상";
		case "BLOCK":
			return "계정 정지";
		case "DELETE":
			return "탈퇴";
		case "WARNING":
			return "주의";
		case "RISK":
			return "위험";
		case "MEDIA_UPLOADING":
			return "미디어 업로드";
		case "MEDIA_UPLOAD_FAIL":
			return "미디어 업로드 실패";
		case "MALE":
			return "남자";
		case "FEMALE":
			return "여자";
		case "BUG_REPORT":
			return "버그 제보";
		case "REPORT":
			return "신고 하기";
		case "MANAGER_PAYMENT":
			return "관리자지급";
		case "VIDEO_CALL":
			return "영상채팅";
		case "SUBSCRIPTION":
			return "구독";
		case "GIFT":
			return "선물";
		case "TEXT":
			return "문자채팅";
		case "BUY_POST":
			return "피드구매";
		case "CHATING":
			return "운영중";
		case "END_CHAT":
			return "종료";
		case "FREE_MESSAGE":
			return "무료";
		case "PAID_MESSAGE":
			return "유료";
		case "FOLLOW":
			return "팔로우";
		case "UNFOLLOW":
			return "언팔로우 ";
		case "FAILURE":
			return "실패";
		case "REQUEST":
			return "요청";
		case "GIFT_FROM_VIDEO":
			return "영상통화중 선물";
		case "FOLLOWER":
			return "팔로어";
		case "ALL":
			return "전체";
		case "CREATED":
			return "생성";
		case "STICKER":
			return "이모티콘";
		case "ADMIN_ADD_STAR":
			return "관리자 지급";
		case "ADMIN_SUB_STAR":
			return "관리자 차감";
		case "ADMIN_ADD_DONATE_MONEY":
			return "관리자 지급";
		case "ADMIN_SUB_DONATE_MONEY":
			return "관리자 차감";
		case "PROCESS":
			return "진행중";
		case "SERVICE_INTRODUCTION":
			return "서비스 소개";
		case "USING":
			return "사용";
		case "DONE":
			return "완료";
		case "AGENCY_REQUEST_WITHDRAW":
			return "대행사 요청 철회";
		case "AGENCY_PENDING":
			return "대행사 보류 중";
		case "IN_APP_PURCHASE":
			return "스타구매";
		case "SELECTED":
			return "선택";
		case "UNSELECTED":
			return "비선택";
		case "BLOCKED":
			return "차단";
		case "UNBLOCKED":
			return "차단해제";
		case "MEMBER_NORNAL":
			return "회원";
		case "MEMBER_CELEB":
			return "셀럽";
		case "REFUND":
			return "취소";
		case "IN_WEB_PURCHASE":
			return "웹 스타 구매";
		case "AUTO_PAY_CALL":
			return "원클릭 충전";
		case "AUTO_PAY":
			return "스타 자동 충전";
		case "CELEB_RECEIVER":
			return "셀럽수신";
		case "CELEB_CALL":
			return "셀럽발신";
		case "PLUS_KIT":
			return "플러스킷";
		case "ACTIVE":
			return "현재 활성";
		case "PAUSE":
			return "현재 대기";
		case "CALLING":
			return "통화 중";
		case "UNKNOWN":
			return "미확인";
		case "INACTIVE":
			return "비활성화";
		case "DIRECT_END":
			return "직접 종료";
		default:
			return text;
	}
};

export const convertTextToKoreanV2 = (text) => {
	switch (text) {
		case "SUBSCRIBE":
			return "구독중";
		case "UNSUBSCRIBE":
			return "구독 종료";
		case "ACCEPT":
			return "승인";
		case "DENY":
			return "비승인";
		case "PENDING":
			return "대기";
		case "ACCOUNT_RELATED":
			return "계정 관련";
		case "PAYMENT_RELATED":
			return "결제 관련";
		case "OTHER":
			return "기타";
		case "INDIVIDUAL":
			return "개인";
		case "BUSINESS":
			return "사업자";
		case "SUCCESS":
			return "성공";
		case "NORMAL":
			return "정상";
		case "BLOCK":
			return "계정 정지";
		case "DELETE":
			return "탈퇴";
		case "WARNING":
			return "주의";
		case "RISK":
			return "위험";
		case "MEDIA_UPLOADING":
			return "미디어 업로드";
		case "MEDIA_UPLOAD_FAIL":
			return "미디어 업로드 실패";
		case "MALE":
			return "남자";
		case "FEMALE":
			return "여자";
		case "BUG_REPORT":
			return "버그 제보";
		case "REPORT":
			return "신고 하기";
		case "MANAGER_PAYMENT":
			return "관리자지급";
		case "VIDEO_CALL":
			return "영상채팅";
		case "SUBSCRIPTION":
			return "구독";
		case "GIFT":
			return "선물";
		case "TEXT":
			return "문자채팅";
		case "BUY_POST":
			return "피드구매";
		case "CHATING":
			return "운영중";
		case "END_CHAT":
			return "종료";
		case "FREE_MESSAGE":
			return "무료";
		case "PAID_MESSAGE":
			return "유료";
		case "FOLLOW":
			return "팔로우";
		case "UNFOLLOW":
			return "언팔로우 ";
		case "FAILURE":
		case "FAILED":
			return "실패";
		case "REQUEST":
			return "요청";
		case "GIFT_FROM_VIDEO":
			return "영상통화중 선물";
		case "FOLLOWER":
			return "팔로어";
		case "ALL":
			return "전체";
		case "CREATED":
			return "생성";
		case "STICKER":
			return "이모티콘";
		case "ADMIN_ADD_STAR":
			return "관리자 지급";
		case "ADMIN_SUB_STAR":
			return "관리자 차감";
		case "PROCESS":
			return "신청";
		case "SERVICE_INTRODUCTION":
			return "서비스 소개";
		case "USING":
			return "사용";
		case "DONE":
			return "완료";
		case "AGENCY_REQUEST_WITHDRAW":
			return "대행사 요청 철회";
		case "AGENCY_PENDING":
			return "대행사 보류 중";
		case "REFUND":
			return "취소";
		case "IN_WEB_PURCHASE":
			return "웹 스타 구매";
		case "AUTO_PAY_CALL":
			return "원클릭 충전";
		case "AUTO_PAY":
			return "스타 자동 충전";
		case "PLUS_KIT":
			return "플러스킷";
		default:
			return text;
	}
};

export const convertPluskitPurchaseStatus = (text) => {
	switch (text) {
		case 'SUBSCRIBED':
            return '적용 중';
        case 'EXPIRED':
            return '적용 만료';
        case 'CANCELED':
            return '취소';
        case 'ADMIN_CANCELED':
            return '관리자 취소';
        case 'UPGRADED':
            return '업그레이드';
		default:
			return text;
	}
};

export const convertNiceToDate = (value) => {
	if (value && value.length === 8) {
		return value.slice(0, 4) + "-" + value.slice(4, 6) + "-" + value.slice(6, 8);
	} else {
		return value;
	}
};

export const convertDonatedMoney = (data) => {
	console.log("	", data);
	const donated =
		Math.floor((Number(data?.donated_money) + Number(data?.subscribe_revenue)) / LIMIT_REVENUE) *
		LIMIT_REVENUE;
	// const donated = Math.floor(data?.donated_money / 10000) * 10000;

	// ;
	const intermediary_fee = data?.intermediary_fee || 0;
	let value;
	if (data?.donated_money >= 50000) {
		value = (donated * intermediary_fee) / 100;
	} else {
		value = 0;
	}
	return value.toLocaleString();
};

export const convertDateFormat = (inputDate) => {
	const parts = inputDate.split("/");
	if (parts.length === 3) {
		const [day, month, year] = parts;
		if (year.length !== 4) {
			return inputDate;
		} else {
			return `${year}/${month}/${day}`;
		}
	} else {
		// Trả về inputDate hoặc thông báo lỗi nếu đầu vào không hợp lệ
		return inputDate;
	}
};

export const convertDateToKoreanFormat = (dateString) => {
	// Chuyển đổi ngày thành đối tượng Date
	const date = new Date(dateString);

	// Lấy năm, tháng và ngày từ đối tượng Date
	const year = date.getFullYear();
	const month = date.getMonth() + 1; // Tháng bắt đầu từ 0
	const day = date.getDate();

	// Tạo định dạng Hàn Quốc
	const koreanDate = `${year} 년 ${month} 월 ${day} 일`;

	return koreanDate;
};

export const convertPlatform = (data) => {
	switch (data) {
		case "IAP_APPLE":
			return "APPLE";
		case "IAP_GOOGLE":
			return "GOOGLE";
		default:
			return "Web";
	}
};

export const celebInConversation = (data) => {
	if (data?.admin?.register_status === "ACCEPT" && data?.admin?.user_type === "CELEBRITY") {
		return true;
	} else {
		return false;
	}
};

export const dataaaaa = (data) => {
	console.log("🎃🎃🎃 ~ file: convert.js:468 ~ dataaaaa ~ data:", data);
};

export const handlSignInTime = (timeWeb, timeApp) => {
	if (!timeWeb && timeApp) {
		return convertTimeZone(parseInt(timeApp));
	} else if (timeWeb && !timeApp) {
		return convertTimeZone(parseInt(timeWeb));
	} else if (timeWeb && timeApp) {
		if (timeWeb > timeApp) {
			return convertTimeZone(parseInt(timeWeb));
		} else {
			return convertTimeZone(parseInt(timeApp));
		}
	} else {
		return "";
	}
};

export const handleType = (typeMedia) => {
	// console.log("type_media", typeMedia);
	if (typeMedia === "ALBUM") {
		return "앨범 "; //ALBUM
	} else if (typeMedia === "IMAGE") {
		return "사진"; //IMAGE
	} else if (typeMedia === "VIDEO") return "동영상"; //VIDEO
	else return "-";
};

export const convertText = (data) => {
	switch (data) {
		case "REQUEST":
			return "문의중";
		case "DONE":
			return "문의 완료";
		default:
			return data;
	}
};

export const convertPaymentTypeName = (paymentType) => {
	switch (paymentType) {
		case "STAR":
			return "스타";
		case "AUTO_PAY":
			return "스타자동충전";
		case "AUTO_PAY_CALL":
			return "One Click";
		case "IAP_GOOGLE":
			return "구글";
		case "IAP_APPLE":
			return "애플";
		default:
			return "-";
	}
};
