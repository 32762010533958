import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import "./styles.scss";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function MultipleSelectChip({
	options,
	getHashTag,
	setSelectedOptions,
	selectedOptions,
	data,
}) {
	const theme = useTheme();
	const [personName, setPersonName] = React.useState([]);
	const handleChange = (event) => {
		console.log("event", event);
		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	React.useEffect(() => {
		console.log("data data", data);
		const arr = [];
		data?.map((item) => {
			arr.push("#" + item?.hashtag?.title);
		});
		console.log("arrrrrrrrr", arr);
		setPersonName(arr);
	}, []);
	const computeC = (a, b) => {
		console.log("personName", personName);
		const c = [];
		for (let i = 0; i < a.length; i++) {
			const foundItem = b.find((item) => item.label === a[i]);
			if (foundItem) {
				c.push({ label: foundItem.label, value: foundItem.value });
			}
		}
		return c;
	};
	React.useEffect(() => {
		const result = computeC(personName, options);
		setSelectedOptions(result);
	}, [personName]);
	// const handleOnclick = () => {
	// 	console.log("selected result", personName, options);
	// 	const result = computeC(personName, options);
	// };
	return (
		<div className="container">
			<FormControl sx={{ m: 1, width: 300 }}>
				<Select
					multiple
					value={personName}
					onChange={handleChange}
					input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
					renderValue={(selected) => (
						<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
							{selected.map((value) => (
								<Chip key={value} label={value} />
							))}
						</Box>
					)}
					MenuProps={MenuProps}
				>
					{options.map((name) => (
						<MenuItem
							key={name?.value}
							value={name?.label}
							style={getStyles(name?.label, personName, theme)}
						>
							{name?.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{/* <div onClick={() => handleOnclick()}>abc</div> */}
		</div>
	);
}
