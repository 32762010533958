import { ROUTE_API } from "src/util/ROUTER";
import userSettingApi from "./UserSettingApi";
import saleHistoryApi from "./saleHistoryApi";
import useStarProfileApi from "./useStarProfileApi";
import followListProfileApi from "./followListProfileApi";
import penatyAPi from "./pennatyApi";
import BannerAspApi from "./BannerAspApi";
import HashtagAspApi from "./hashtagAspApi";
import HashtagApiV2 from "./HashtagApiV2.js";
import PaymentApi from "./paymentApi";
import SystemApi from "./SystemApi";

import NiceApi from "./NiceApi";
import ASPApi from "./ASPApi.js";
import autoLoginApi from "./autoLoginApi";
import BannerApiV2 from "./BannerApiV2";
import revenueApi from "./revenueApi";
import IntroAspAPI from "./introAspApi";

export const userSettingAPI = new userSettingApi("", ROUTE_API.USER_SETTING);
export const SaleHistoryApi = new saleHistoryApi(
	'fields=["$all",{"user":["$all"]},{"pack":["$all"]}]',
	ROUTE_API.SALES_HISTORY
);

export const paymentApi = new PaymentApi(
	'fields=["$all",{"user":["$all"]},{"pack":["$all"]}]',
	ROUTE_API.PAYMENT
);

export const UseStarProfileApi = new useStarProfileApi(
	'fields=["$all",{"sender":["$all"]},{"post":["$all",{"secret_album" : ["$all"]}]}]',
	ROUTE_API.USAGE_STAR
);

export const RevenueApi = new revenueApi('fields=["$all"]', ROUTE_API.REVENUE);

export const FollowListProfileApi = new followListProfileApi(
	'fields=["$all",{"user":["id","nickname","identified_id","sex"]},{"followed":["$all",{"agency":["$all"]}]}]',
	ROUTE_API.FAVORITE
);
export const PenatyAPi = new penatyAPi('fields=["$all"]', ROUTE_API.PENALTY);

export const bannerAspApi = new BannerAspApi(
	'fields=["$all",{"hashtag_asp":["$all"]}]',
	ROUTE_API.BANNER_ASP
);
export const bannerApiV2 = new BannerApiV2(
	'fields=["$all",{"hashtag":["$all"]}]',
	ROUTE_API.BANNER
);
export const hashtagAspApi = new HashtagAspApi(
	'fields=["$all",{"banners_asp":["id","image"]}]',
	ROUTE_API.HASHTAG_ASP
);
export const hashtagApiV2 = new HashtagApiV2(
	`fields=["$all",{"banners":["id","image"]}]`,
	ROUTE_API.HASHTAG
);
export const niceApi = new NiceApi('fields=["$all"]', ROUTE_API.NICE);

export const aspApi = new ASPApi('fields=["$all"]', ROUTE_API.ASP_MEMBER);
export const AutoLoginApi = new autoLoginApi(
	'fields=["id","user_type"]',
	ROUTE_API.AUTH_AUTO_LOGIN
);
export const systemApi = new SystemApi('fields=["$all"]', ROUTE_API.SYSTEM_SETTING);

export const introAspApi = new IntroAspAPI('fields=["$all"]', ROUTE_API.INTRO_ASP);
