import Api from "./Api";
import axiosClient from "./axiosClient";

export default class IntroAspAPI extends Api {
	updateIntro(body, id) {
		const url = `${this.HOSTNAME}/${this.table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		});
	}

	findAll(page = 1, limit = 20, filter, order) {
		const url = `${this.HOSTNAME}/${this.table}?${this.fields}&page=${page}&limit=${limit}&order=[${
			order || '["created_at_unix_timestamp","asc"]'
		}]`;
		return axiosClient.get(url);
	}
}
