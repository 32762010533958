import React, { useState, useContext, useEffect, useRef } from "react";
import "./style.css";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import { valueFilter } from "src/util/Utils";
import { keyHeaderFilter } from "src/util/data";
import { userProfileContext } from "src/providers/UserProvider";
import Emitter from "src/util/Emitter";
const TheHeaderDefault = ({
	title,
	Component,
	setFilter,
	typeHeadeFilter,
	initValue,
	id,
	setSortData,
}) => {
	const { isReloadApi } = useContext(userProfileContext);
	const formikRef = useRef();
	const [arr] = useState({});
	const {
		FOLLOW_CELEB,
		APPECPT_CELEB,
		CHAT_CELEB,
		POST_CELEB,
		SUBCRIPTION_CELEB,
		PURCHASE_HISTORY,
		SUBSCRIBER_HISTORY,
		WEB_PURCHASE_HISTORY,
		ACCUMULATED_STAR_CELEB,
		BLOCK_USER,
		SETTING_DEFAULT_MESS,
		DEFAULT_MESS,
		INQUIRY,
		REPORT_USER,
		DELETE_USER,
		PAYMENT_USER_PROFILE,
		STAR_USER_PROFILE,
		LIST_COIN_USER_PROFILE,
		APPROVE_POST,
		PROFILE_IMAGE,
		LIST_USER,
		LIST_CELEB,
		FOLLOW_LIST_USER_PROFILE,
		FOLLOW_LIST_USER_PROFILE_CELEB,
		PLUSKIT_PACKAGE_LIST_ASP,
		FEED_LIST_USER_PROFILE,
		SUPCRIPTION_LIST_USER_PROFILE,
		CELEB_REQUEST_WITHDRAW,
		CELEB_WITHDRAW,
		AGENCY_WITHDRAW,
		AGENCY_REQUEST_WITHDRAW,
		INAPP_PAYMENT,
		AGENCY,
		DAILY_SALE,
		STAR_LOG,
		MONTHLY_SALE,
		VIDEO_CALL_LOG,
		CHAT_LOG,
		GIFT_LOG,
		DAILY_SALE_ASP,
		MONTHLY_SALE_ASP,
		Qna_ASP,
		STAR_LOG_ASP,
		COIN_LOG_ASP,
		LIST_USER_ASP,
		VIDEO_CHAT_LOG_ASP,
		CHAT_LOG_ASP,
		PLUSKIT_PURCHASE_LOG_ASP,
		PROFILE_IMAGE_ASP,
		REPORT_USER_ASP,
		LIST_INSTAGRAM_USER,
		INTERMEDIARY_FEE,
		TIME_ZONE_ACCESS_STATISTICS,
		DAILY_ACCESS_STATISTICS,
		CELEB_CONNECTION_STATUS,
		ON_OFF_PLUS_KIT_MANAGEMENT,
		VIDEO_CALL_ERROR_STATISTICS,
		GIFT_REGISTRATION_MANAGEMENT,
	} = keyHeaderFilter;

	const handleSubmit = (values) => {
		let exchangeValue;
		const {
			Follow,
			Appecpt,
			Chat,
			Post,
			subcription,
			accumulated_star_celeb,
			purchase_history,
			subscriber_history,
			web_purchase_history,
			Block,
			listUser,
			listCeleb,
			SettingDefaultMess,
			report,
			DefaultMess,
			inquiry,
			deleteAccount,
			approvePost,
			paymentUserProfile,
			profileImage,
			starUserProfile,
			listCoinUserProfile,
			feedListUserProfile,
			followListUserProfile,
			followListUserProfileCeleb,
			pluskitPackageListASP,
			supcriptionListUserProfile,
			celebRequestWithdraw,
			celebWithdraw,
			agencyWithdraw,
			agencyRequestWithdraw,
			inAppPayment,
			agency,
			dailySale,
			starLog,
			dailySaleMonth,
			VideoCallLog,
			ChatLog,
			GiftLog,
			DailySaleASP,
			MonthlySaleMonthASP,
			inquiryASP,
			starLogASP,
			coinLogASP,
			listUserASP,
			videoChatLogASP,
			ChatLogASP,
			PluskitPurchaseLogASP,
			profileImageASP,
			reportUserASP,
			listInstagramUser,
			intermediaryFee,
			TimezoneAccessStatistics,
			DailyAccessStatistics,
			CelebConnectionStatus,
			OnOffPlusKitManagement,
			VideoCallErrorStatistics,
			GiftRegistrationManagement,
		} = valueFilter;
		switch (typeHeadeFilter) {
			case FOLLOW_CELEB: {
				if (values.search_time !== "ALL") {
					setSortData([...values.search_time.split(".").map((s) => s.replace(/\$/g, "")), "desc"]);
				}
				exchangeValue = Follow(values);
				setFilter(exchangeValue);
				break;
			}
			case APPECPT_CELEB: {
				exchangeValue = Appecpt(values);
				if (values.status === "PENDING") {
					setSortData({ column: "created_at_unix_timestamp", asc: false });
				}
				setFilter(exchangeValue);
				break;
			}
			case CHAT_CELEB: {
				exchangeValue = Chat(values);
				setFilter(exchangeValue);
				break;
			}
			case PURCHASE_HISTORY: {
				exchangeValue = purchase_history(values);
				setFilter(exchangeValue);
				break;
			}
			case SUBSCRIBER_HISTORY: {
				exchangeValue = subscriber_history(values);
				setFilter(exchangeValue);
				break;
			}
			case WEB_PURCHASE_HISTORY: {
				exchangeValue = web_purchase_history(values);
				setFilter(exchangeValue);
				break;
			}
			case POST_CELEB: {
				exchangeValue = Post(values);
				setFilter(exchangeValue);
				break;
			}
			case SUBCRIPTION_CELEB: {
				exchangeValue = subcription(values);
				setFilter(exchangeValue);
				break;
			}
			case ACCUMULATED_STAR_CELEB: {
				exchangeValue = accumulated_star_celeb(values);
				setFilter(exchangeValue);
				break;
			}
			case BLOCK_USER: {
				exchangeValue = Block(values);
				setFilter(exchangeValue);
				break;
			}
			case SETTING_DEFAULT_MESS: {
				exchangeValue = SettingDefaultMess(values);
				setFilter(exchangeValue);
				break;
			}
			case DEFAULT_MESS: {
				exchangeValue = DefaultMess(values);
				setFilter(exchangeValue);
				break;
			}
			case REPORT_USER: {
				exchangeValue = report(values);
				setFilter(exchangeValue);
				break;
			}
			case DELETE_USER: {
				exchangeValue = deleteAccount(values);
				setFilter(exchangeValue);
				break;
			}
			case INQUIRY: {
				exchangeValue = inquiry(values);
				setFilter(exchangeValue);
				break;
			}
			case APPROVE_POST: {
				exchangeValue = approvePost(values);
				setFilter(exchangeValue);
				break;
			}
			case PROFILE_IMAGE: {
				exchangeValue = profileImage(values);
				setFilter(exchangeValue);
				break;
			}
			case PAYMENT_USER_PROFILE: {
				exchangeValue = paymentUserProfile(values);
				setFilter(exchangeValue);
				break;
			}
			case STAR_USER_PROFILE: {
				exchangeValue = starUserProfile(values, id);
				setFilter(exchangeValue);
				break;
			}
			case LIST_COIN_USER_PROFILE: {
				exchangeValue = listCoinUserProfile(values, id);
				setFilter(exchangeValue);
				break;
			}
			case FEED_LIST_USER_PROFILE: {
				exchangeValue = feedListUserProfile(values);
				setFilter(exchangeValue);
				break;
			}
			case FOLLOW_LIST_USER_PROFILE: {
				exchangeValue = followListUserProfile(values);
				setFilter(exchangeValue);
				break;
			}
			case FOLLOW_LIST_USER_PROFILE_CELEB: {
				exchangeValue = followListUserProfileCeleb(values);
				setFilter(exchangeValue);
				break;
			}
			case PLUSKIT_PACKAGE_LIST_ASP: {
				exchangeValue = pluskitPackageListASP(values);
				setFilter(exchangeValue);
				break;
			}
			case SUPCRIPTION_LIST_USER_PROFILE: {
				exchangeValue = supcriptionListUserProfile(values);
				setFilter(exchangeValue);
				break;
			}
			case LIST_USER: {
				exchangeValue = listUser(values);
				setFilter(exchangeValue);
				break;
			}
			case LIST_CELEB: {
				exchangeValue = listCeleb(values);
				setFilter(exchangeValue);
				break;
			}
			case CELEB_REQUEST_WITHDRAW: {
				exchangeValue = celebRequestWithdraw(values);
				setFilter(exchangeValue);
				break;
			}
			case CELEB_WITHDRAW: {
				exchangeValue = celebWithdraw(values);
				setFilter(exchangeValue);
				break;
			}
			case AGENCY_WITHDRAW: {
				exchangeValue = agencyWithdraw(values);
				setFilter(exchangeValue);
				break;
			}
			case AGENCY_REQUEST_WITHDRAW: {
				exchangeValue = agencyRequestWithdraw(values);
				setFilter(exchangeValue);
				break;
			}
			case INAPP_PAYMENT: {
				exchangeValue = inAppPayment(values);
				setFilter(exchangeValue);
				break;
			}
			case AGENCY: {
				exchangeValue = agency(values);
				setFilter(exchangeValue);
				break;
			}
			case DAILY_SALE: {
				exchangeValue = dailySale(values);
				setFilter(exchangeValue);
				break;
			}
			case STAR_LOG: {
				exchangeValue = starLog(values);
				setFilter(exchangeValue);
				break;
			}
			case MONTHLY_SALE: {
				exchangeValue = dailySaleMonth(values);
				setFilter(exchangeValue);
				break;
			}
			case VIDEO_CALL_LOG: {
				exchangeValue = VideoCallLog(values);
				setFilter(exchangeValue);
				break;
			}
			case CHAT_LOG: {
				exchangeValue = ChatLog(values);
				setFilter(exchangeValue);
				break;
			}
			case GIFT_LOG: {
				exchangeValue = GiftLog(values);

				setFilter(exchangeValue);
				break;
			}
			case DAILY_SALE_ASP: {
				exchangeValue = DailySaleASP(values);

				setFilter(exchangeValue);
				break;
			}
			case MONTHLY_SALE_ASP: {
				exchangeValue = MonthlySaleMonthASP(values);

				setFilter(exchangeValue);
				break;
			}
			case Qna_ASP: {
				exchangeValue = inquiryASP(values);
				setFilter(exchangeValue);
				break;
			}
			case STAR_LOG_ASP: {
				exchangeValue = starLogASP(values);
				setFilter(exchangeValue);
				break;
			}
			case COIN_LOG_ASP: {
				exchangeValue = coinLogASP(values);
				setFilter(exchangeValue);
			}
			case LIST_USER_ASP: {
				exchangeValue = listUserASP(values);
				setFilter(exchangeValue);
				break;
			}
			case VIDEO_CHAT_LOG_ASP: {
				exchangeValue = videoChatLogASP(values);
				setFilter(exchangeValue);
				break;
			}
			case CHAT_LOG_ASP: {
				exchangeValue = ChatLogASP(values);
				setFilter(exchangeValue);
				break;
			}
			case PLUSKIT_PURCHASE_LOG_ASP: {
				exchangeValue = PluskitPurchaseLogASP(values);
				setFilter(exchangeValue);
				break;
			}
			case PROFILE_IMAGE_ASP: {
				exchangeValue = profileImageASP(values);
				setFilter(exchangeValue);
				break;
			}
			case REPORT_USER_ASP: {
				exchangeValue = reportUserASP(values);
				setFilter(exchangeValue);
				break;
			}
			case LIST_INSTAGRAM_USER: {
				exchangeValue = listInstagramUser(values);
				setFilter(exchangeValue);
				break;
			}
			case INTERMEDIARY_FEE: {
				exchangeValue = intermediaryFee(values);
				setFilter(exchangeValue);
				break;
			}
			case TIME_ZONE_ACCESS_STATISTICS: {
				exchangeValue = TimezoneAccessStatistics(values);
				setFilter(exchangeValue);
				break;
			}
			case DAILY_ACCESS_STATISTICS: {
				exchangeValue = DailyAccessStatistics(values);
				setFilter(exchangeValue);
				break;
			}
			case CELEB_CONNECTION_STATUS: {
				exchangeValue = CelebConnectionStatus(values);
				setFilter(exchangeValue);
				break;
			}
			case ON_OFF_PLUS_KIT_MANAGEMENT: {
				exchangeValue = OnOffPlusKitManagement(values);
				setFilter(exchangeValue);
				break;
			}
			case VIDEO_CALL_ERROR_STATISTICS: {
				exchangeValue = VideoCallErrorStatistics(values);
				setFilter(exchangeValue);
				break;
			}
			case GIFT_REGISTRATION_MANAGEMENT: {
				exchangeValue = GiftRegistrationManagement(values);
				setFilter(exchangeValue);
				break;
			}
			default:
				break;
		}
	};

	useEffect(() => {
		formikRef.current.resetForm();
	}, [isReloadApi]);

	useEffect(() => {
		Emitter.on("AUTO_FILTER", (values) => handleSubmit(values));
		return () => {
			Emitter.off("AUTO_FILTER");
		};
	}, []);

	return (
		<Formik
			innerRef={formikRef}
			enableReinitialize={false}
			initialValues={initValue ? initValue : arr}
			onSubmit={handleSubmit}
		>
			{({ resetForm }) => {
				return (
					<Form>
						<div className="headerContainer">
							<h3>{title ? title : ""}</h3>
							<div className="headerContent">
								<div style={{ padding: "20px" }}>
									<div className="headerFilter__group">
										<Component />
									</div>
								</div>
							</div>
							<div className="buttonContainer">
								<Button
									variant="contained"
									sx={{
										backgroundColor: "#3B3B3B",
										color: "white",
										width: 221,
										height: 52,
										borderRadius: "10px",
										fontSize: "18px",
									}}
									type="submit"
								>
									검색
								</Button>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default TheHeaderDefault;
