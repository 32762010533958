import { createSlice } from "@reduxjs/toolkit";

const employee = createSlice({
	name: "employee",
	initialState: [],
	reducers: {
		login: (state, action) => {
			state.push(action.payload);
		},
		empArr: (state, action) => {
			state.push(action.payload);
		},

		logout: function (state) {
			// eslint-disable-next-line no-unused-vars
			state = [];
		},
	},
});

const { reducer, actions } = employee;
export const { login, empArr, logout } = actions;
export default reducer;
