import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import { FollowListProfileApi } from "src/api";
import DataTable from "src/components/phase2/DataTable";
import FollowListFilterCeleb from "src/components/phase2/HeaderFilterInput/UserFollowListCeleb";
import TheHeaderDefault from "src/components/phase2/NewLayout/TheHeaderDefault";
import useFilters from "src/hooks/useFilter";
import { LoadingContext } from "src/providers/LoadingProvider";
import { convertTextToKorean, convertTimeZone } from "src/util/convert";
import { initValue, keyHeaderFilter } from "src/util/data";
import "./style.css";

const UserInfo = ({ data }) => {
	const arr = [];
	const [items, setItems] = useState([]);
	const [total, settotal] = useState(0);
	const { showLoading, hideLoading } = useContext(LoadingContext);

	const { LIMITED, currentPage, spages, filter } = useFilters();
	const columns = [
		{
			field: "id",
			hide: true,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "no",
			headerName: "No",
			headerAlign: "center",

			sortable: false,
		},
		{
			field: "user_id",
			headerName: "아이디",
			flex: 1,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "user_nickname",
			headerName: "닉네임",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},
		{
			field: "user_gender",
			headerName: "성별",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "create_at",
			headerName: "시작일",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "update_at",
			headerName: "종료일",
			flex: 1,
			renderCell: (params) => {
				return <div>{params?.row?.status === "UNFOLLOW" ? params?.row?.update_at : "-"}</div>;
			},
			headerAlign: "center",
			sortable: false,
		},

		{
			field: "status",
			headerName: "상태",
			headerAlign: "center",
			flex: 1,
			renderCell: (params) => {
				return <div>{convertTextToKorean(params?.row?.status)}</div>;
			},
			sortable: false,
		},
	];
	const handleChangePage = (e, value) => {
		currentPage.set(value);
	};

	useEffect(() => {
		const fetchAll = async () => {
			showLoading();
			try {
				const response = await FollowListProfileApi.getAll(
					currentPage.value,
					LIMITED.value,
					JSON.stringify({ ...filter.value, followed_id: data?.id }),
					'["updated_at_unix_timestamp","desc"]'
				);

				settotal(response?.results?.objects.count);
				if (response.code === 200) {
					// xu li pagination
					let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
					spages.set(pages);
					response.results.objects.rows.map((val, idx) => {
						// console.log(val);
						return arr.push({
							id: val?.id,
							no: idx + (currentPage.value - 1) * LIMITED.value + 1,
							user_id: val?.user?.identified_id,
							user_nickname: val?.user?.nickname,
							user_gender: convertTextToKorean(val?.user?.sex),
							create_at: convertTimeZone(+val?.created_at_unix_timestamp),
							status: val?.status ? "FOLLOW" : "UNFOLLOW",
							update_at: convertTimeZone(+val?.updated_at_unix_timestamp),
						});
					});
				}
			} catch (error) {
			} finally {
				hideLoading();
				setItems(arr);
			}
		};

		fetchAll();
	}, [currentPage.value, LIMITED.value, filter.value, data?.id]);
	useEffect(() => {
		currentPage.set(1);
	}, [filter.value]);

	return (
		<>
			<Stack spacing={2}>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>팔로우 리스트</p>
					</div>
					<TheHeaderDefault
						Component={FollowListFilterCeleb}
						typeHeadeFilter={keyHeaderFilter.FOLLOW_LIST_USER_PROFILE_CELEB}
						setFilter={filter.set}
						initValue={initValue.USERPROFILE.FOLLOWCELEB}
					/>
					<DataTable
						columns={columns}
						total={total}
						items={items}
						page={spages.value}
						currentPage={currentPage.value}
						changePage={handleChangePage}
						limit={LIMITED}
						fileName="block user"
					/>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
