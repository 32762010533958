import React from "react";

const Input = ({ field, form, className, placeholder }) => {
	return (
		<>
			<input
				value={field.value}
				placeholder={placeholder ? placeholder : ""}
				className={className ? className : ""}
				onChange={(e) => form.setFieldValue(field.name, e.target.value.trim())}
			/>
		</>
	);
};

export default Input;
