import React from "react";
import UploadApi from "src/api/UploadApi";
import plus from "../../../assets/img/plus.png";
import "../../../scss/style.scss";
import "./file.css";

function UploadImg({ URL, thumbnail, title, setUrlImg, uploadRef }) {
	const [value, setValue] = React.useState();
	const [, setValueLoading] = React.useState(0);
	const token = JSON.parse(localStorage.getItem("token"));
	React.useEffect(() => {
		setValue(thumbnail);
	}, [thumbnail]);

	const readUrl = async (e) => {
		const options = {
			headers: {
				Authorization: `Bearer ${token}`,
			},

			onUploadProgress: (ProgressEvent) => {
				const { loaded, total } = ProgressEvent;
				let percent = Math.floor((loaded * 100) / total);
				if (percent < 100) {
					setValueLoading(percent);
				}
			},
		};

		try {
			const formData = new FormData();
			formData.append("image", e.target.files[0]);

			const response = await UploadApi.uploadImg_FTP(formData, options);

			if (response.code === 200) {
				setValueLoading(100);
				URL(response.results.object.url);
				setValue(response.results.object.url);
				setUrlImg(response.results.object.url);
			}
		} catch (error) {
			alert(error.toString());
		} finally {
			setValueLoading(0);
		}
	};

	return (
		<div>
			<>
				{value && value !== "null" ? (
					<div className="input-group-btn">
						<img
							style={{ width: "150px", height: "150px", objectFit: "cover" }}
							src={value}
							alt="Image"
						/>
					</div>
				) : (
					<div className="input-group-btn">
						<span
							style={{
								display: "inline-block",
								width: "150px",
								height: "150px",
								backgroundColor: "transparent",
							}}
							className="fileUpload btn btn-secondary"
						>
							<img
								style={{
									width: "100px",
									height: "100px",
									position: "absolute",
									top: "15%",
									left: "18%",
								}}
								src={plus}
							/>
							<input
								style={{
									width: "150px",
									height: "150px",
								}}
								ref={uploadRef}
								type="file"
								className="upload up"
								id="up"
								onChange={readUrl}
								accept={"image/*"}
							/>
						</span>
					</div>
				)}
			</>
		</div>
	);
}

export default UploadImg;
