import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import "./style.scss";
const SelectInput = ({ data, className, handleChange, value }) => {
	return (
		<FormControl sx={{ height: "100%", width: "66%" }}>
			<Select
				className={className}
				sx={{
					"& legend": { display: "none" },
					"& fieldset": { top: 0 },
					width: "100%",
				}}
				value={value}
				onChange={handleChange}
			>
				{data.map((item, index) => (
					<MenuItem key={index} value={item.value}>
						{item.title}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectInput;
