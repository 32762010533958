import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { useState } from "react";
import { DataRangePickerField, InputField, SelectField } from "src/components/phase2/Fields";
import FilterInputWrapper from "src/components/phase2/NewLayout/FilterInputWrapper";
import "src/components/phase2/style.css";

const filterData = {
	celebInfo: [
		{
			value: "$celeb.identified_id$",
			label: "셀럽 아이디",
		},
		{
			value: "$celeb.nickname$",
			label: "셀럽 닉네임",
		},
	],
	statusInfo: [
		{
			value: "ALL",
			label: "전체",
		},
		{
			value: "SUBSCRIBED",
			label: "적용 중",
		},
		{
			value: "EXPIRED",
			label: "적용 만료",
		},
		{
			value: "ADMIN_CANCELED",
			label: "관리자 취소",
		},
		{
			value: "CANCELED",
			label: "취소",
		},
		{
			value: "UPGRADED",
			label: "업그레이드",
		},
	],
	timeInfo: [
		{
			value: "ALL",
			label: "전체",
		},
		{
			value: "register_at_unix_timestamp",
			label: "구매날짜",
		},
		{
			value: "expire_at_unix_timestamp",
			label: "종료날짜",
		},
	],
	type: [
		{
			value: "ALL",
			label: "전체",
		},
		{
			value: "UNLIMITED",
			label: "Plan 01",
		},
		{
			value: "LIMITED",
			label: "Plan 02",
		},
	],
};

const PluskitPackageListASPFilter = () => {
	const [DateRange, setDateRange] = useState([]);
	return (
		<FormGroup>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={6}>
					<FilterInputWrapper label="셀럽 정보">
						<Grid item xs={4} className="mr-2">
							<Field name="celebInfoSelect" component={SelectField} data={filterData.celebInfo} />
						</Grid>
						<Grid item xs={8}>
							<Field
								name="celebInfoInput"
								component={InputField}
								placeholder="입력해주세요"
								className="inputFilter"
							/>
						</Grid>
					</FilterInputWrapper>
					<FilterInputWrapper label="기간 검색">
						<Field name="timeInfoSelect" component={SelectField} data={filterData.timeInfo} />
						<div style={{ marginRight: "7px" }}></div>
						<Field
							name="time__data"
							component={DataRangePickerField}
							DateRange={DateRange}
							setDateRange={setDateRange}
						/>
					</FilterInputWrapper>
				</Grid>
				<Grid item xs={6}>
					<FilterInputWrapper label="상태">
						<Field name="status" component={SelectField} data={filterData.statusInfo} />
					</FilterInputWrapper>
					<FilterInputWrapper label="구매">
						<Field name="type" component={SelectField} data={filterData.type} />
					</FilterInputWrapper>
				</Grid>
			</Grid>
		</FormGroup>
	);
};

export default PluskitPackageListASPFilter;
