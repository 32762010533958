import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import { UseStarProfileApi } from "src/api";
import TheHeaderDefault from "src/components/phase2/NewLayout/TheHeaderDefault";
import useFilters from "src/hooks/useFilter";
import { LoadingContext } from "src/providers/LoadingProvider";
import { convertTimeZone, handleType } from "src/util/convert";
import { keyHeaderFilter } from "src/util/data";
import ModalTotalSender3 from "src/components/phase2/ModalTotalSender3";
import { CardModal } from "../CardImg";

import "./style.css";

import DataTable from "src/components/phase2/DataTable";
import FeedListFilter from "src/components/phase2/HeaderFilterInput/UserProfileFeedList";

const UserInfo = ({ data }) => {
	const arr = [];
	const [items, setItems] = useState([]);
	const [total, settotal] = useState(0);
	const [openModalTotalSender, setopenModalTotalSender] = useState(false);
	const [openModal, setOpenmodal] = useState(false);
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [itemsUsers, setItemsUsers] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [itemMedias, setItemMedias] = useState([]);
	const handleShowCeleb = (celeb) => {
		setItemsUsers(() => celeb);
		setopenModalTotalSender(true);
	};

	const { LIMITED, currentPage, spages, filter } = useFilters();
	const columns = [
		{
			field: "id",
			hide: true,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "no",
			headerName: "No",
			headerAlign: "center",

			sortable: false,
		},
		{
			field: "type",
			headerName: "종류",
			flex: 1,
			headerAlign: "center",
			sortable: false,
			renderCell: (params) => {
				return (
					<p
						style={{
							textDecoration: `${params.row.medias.length > 0 ? "underline" : ""}`,
							cursor: "pointer",
						}}
						onClick={() => {
							setItemMedias(params.row.medias);
							setOpenmodal(true);
						}}
					>
						{params.row.type}
					</p>
				);
			},
		},
		{
			field: "time_pay",
			headerName: "구매 기간",

			headerAlign: "center",
			sortable: false,
		},

		{
			field: "setting",
			headerName: "설정",
			headerAlign: "center",
			minWidth: 200,
			sortable: false,
			renderCell: (params) => {
				return <div>{params?.row?.setting === "FREE" ? "무료" : "유료"}</div>;
			},
		},
		{
			field: "star",
			headerName: "스타",

			headerAlign: "center",
			sortable: false,
		},

		{
			field: "create_at",
			headerName: "등록날짜",
			headerAlign: "center",
			flex: 1,
			sortable: false,
		},
		{
			field: "total_user",
			headerName: "구매자 수",
			headerAlign: "center",
			minWidth: 200,
			sortable: false,
			renderCell: (params) => {
				return (
					<p
						style={{
							textDecoration: `${params.row.total_user > 0 ? "underline" : ""}`,
							cursor: "pointer",
						}}
						onClick={() => params.row.total_user > 0 && handleShowCeleb(params.row.list_user)}
					>
						{params.row.total_user}
					</p>
				);
			},
		},

		{
			field: "time_purchase",
			headerName: "구매날짜",
			headerAlign: "center",
			flex: 1,
			sortable: false,
		},
	];
	const handleChangePage = (e, value) => {
		currentPage.set(value);
	};

	const checkPeriodType = (period_type) => {
		switch (period_type) {
			case "NO_LIMIT":
				return "무제한";

			case "ONE_DAY":
				return "하루";

			case "ONE_WEEK":
				return "일주일";

			case "TWO_WEEK":
				return "이주일";

			case "ONE_MONTH":
				return "한달";
			case "ENTER":
				return "직접입력";
			default:
				return "-";
		}
	};
	const getAll = async () => {
		showLoading();
		try {
			const response = await UseStarProfileApi.getAllPost(
				currentPage.value,
				LIMITED.value,
				JSON.stringify({ ...filter.value, user_id: data.id, approve_state: "APPROVED" })
			);

			settotal(response?.results?.objects.count);
			if (response.code === 200) {
				// xu li pagination
				let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
				spages.set(pages);
				response.results.objects.rows.map((val, idx) => {
					return arr.push({
						id: val?.id,
						no: idx + (currentPage.value - 1) * LIMITED.value + 1,
						setting: val?.secret_album_id ? val?.secret_album?.type : val?.type,
						star: val?.price || "-",
						create_at: convertTimeZone(+val?.created_at_unix_timestamp),
						total_user: val?.usage_star_post?.length || "-",
						time_purchase: convertTimeZone(+val?.last_date_purchase),
						type:
							handleType(val?.type_media) + `${val?.type_media ? `(${val?.medias?.length}) ` : ""}`,
						time_pay: val?.secret_album_id ? checkPeriodType(val?.secret_album?.period_type) : "-",
						list_user: val?.usage_star_post || [],
						medias: val?.medias || [],
					});
				});
			}
		} catch (error) {
		} finally {
			hideLoading();
			setItems(arr);
		}
	};
	useEffect(() => {
		getAll();
	}, [currentPage.value, LIMITED.value, filter.value, data]);
	useEffect(() => {
		currentPage.set(1);
	}, [filter.value]);

	return (
		<>
			{console.log(" cần sửa FeedList")}
			<Stack spacing={2}>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>피드 리스트</p>
					</div>
					<TheHeaderDefault
						Component={FeedListFilter}
						typeHeadeFilter={keyHeaderFilter.FEED_LIST_USER_PROFILE}
						setFilter={filter.set}
						initValue={{
							time__data: [],
							search_time: "created_at_unix_timestamp",
						}}
					/>
					<DataTable
						columns={columns}
						total={total}
						items={items}
						page={spages.value}
						currentPage={currentPage.value}
						changePage={handleChangePage}
						limit={LIMITED}
						fileName="block user"
					/>
				</div>
			</Stack>
			<ModalTotalSender3
				open={openModalTotalSender}
				handleClose={() => setopenModalTotalSender(false)}
				data={itemsUsers}
				type="Agency"
			/>
			<CardModal
				open={openModal}
				handleClose={() => setOpenmodal(false)}
				item={{ medias: itemMedias }}
				typeCard={"POST"}
				user={data}
			/>
		</>
	);
};

export default UserInfo;
