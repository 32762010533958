import { ROUTE } from "src/util/ROUTER";
import Api from "./Api";
import axiosClient from "./axiosClient";

export default class revenueApi extends Api {
	adminUpdateDonateMoney(data) {
		const url = `${this.HOSTNAME}/${this.table}/${ROUTE.ADMIN_UPDATE_DONATE_MONEY}`;
		return axiosClient.post(url, data);
	}
}
