import axiosClient from "./axiosClient";
// const HOSTNAME = process.env.REACT_APP_HOST_NAME;
const HOSTNAME = process.env.REACT_APP_API;
const fields =
	'fields=["$all",{"celeb":["$all"]},{"user" : ["nickname","identified_id","sex"]}, {"pack_sub": ["$all", {"celeb": ["id", "nickname", "avatar","sex","phone","identified_id",{"agency":["$all"]}]}]}]';
const table = "subscriber";

const SubcriberApi = {
	getAll: (page, limit, filter) => {
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["expire_at_unix_timestamp","desc"]]`;
		return axiosClient.get(url);
	},
};

export default SubcriberApi;
