import React, { useState } from "react";

const PhoneInput = ({ field, form, className, placeholder, min, max }) => {
	// eslint-disable-next-line no-unused-vars
	const [ageValue, setAgeValue] = useState();
	return (
		<>
			<input
				type={"number"}
				placeholder={placeholder ? placeholder : ""}
				className={className ? className : ""}
				value={field.value}
				// pattern="^-?[0-9]\d*\.?\d*$"
				onChange={(e) => {
					let { value } = e.target;
					value = Math.max(Math.min(Number(max), Number(value)));
					setAgeValue(value);
					form.setFieldValue(field.name, value);
				}}
			/>
		</>
	);
};

export default PhoneInput;
