import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const MultiLanguage = ({ language, handleChangeLanguage }) => {
	const theme = useTheme();
	const { t } = useTranslation();

	useEffect(() => {
		if (!localStorage.getItem("i18nextLng")) {
			localStorage.setItem("i18nextLng", "kr");
		}
	}, []);

	return (
		<>
			<Box
				sx={{
					ml: 2,
					mr: 3,
					[theme.breakpoints.down("md")]: {
						mr: 2,
					},
				}}
			>
				<FormControl sx={{ minWidth: 120 }}>
					<InputLabel id="demo-simple-select-outlined-label">{t("language.Language")}</InputLabel>
					<Select
						value={language.value}
						onChange={handleChangeLanguage}
						label={t("language.Language")}
						displayEmpty
						inputProps={{ "aria-label": "Without label" }}
            sx={{height: '50px'}}
					>
						<MenuItem value="kr">{t("language.Korean")}</MenuItem>
						<MenuItem value="en">{t("language.English")}</MenuItem>
					</Select>
				</FormControl>
			</Box>
		</>
	);
};

export default MultiLanguage;
